import { isNullOrEmpty } from "./utils";

/// Return new Date object
export function newDate(raw) {
   if (raw === undefined || raw === null) return new Date();
   if (raw instanceof Date) return raw;
   return new Date(raw);
}

export function addDays(add, date) {
   let newdate = newDate(date);
   newdate.setDate(newdate.getDate() + add);
   return newdate;
}

/// Returns a new Date object adding the specified number of seconds.  If date is ommitted, then now is used.
export function addSecondsToDate(seconds, date) {
   date = newDate(date);

   let base = date.getTime();
   let milliseconds = seconds * 1000;
   return new Date(base + milliseconds);
}

/// Return true/false if the given date is expired
export function isExpired(expireDate) {
   expireDate = newDate(expireDate);

   let now = new Date().getTime();
   let expiretime = expireDate.getTime();

   return now > expiretime;
}

/// Returns a date string in the format of YYYY-mm-DDTHH:MM:SS.SSSZ
export function getISODateTime(dateTime) {
   dateTime = newDate(dateTime);

   return dateTime.toISOString();
}

/// Returns a date string in the format of YYYY-mm-DDTHH:MM:SS.SSSZ
export function getLocalISODateTime(dateTime) {
   dateTime = newDate(dateTime);

   let isodatetime =
      dateTime.getFullYear() +
      "-" +
      make2Digit(dateTime.getMonth() + 1) +
      "-" +
      make2Digit(dateTime.getDate());
   isodatetime +=
      "T" +
      make2Digit(dateTime.getHours()) +
      ":" +
      make2Digit(dateTime.getMinutes()) +
      ":" +
      make2Digit(dateTime.getSeconds());

   return isodatetime;
}

function make2Digit(value) {
   if (typeof value === "number") value = value.toString();
   if (value.length === 1) return "0" + value;
   return value;
}

export function formatDateTime(dateTime) {
   if (isNullOrEmpty(dateTime)) return "";

   return getDateTime(dateTime);
}

/// Returns formatted date/time string in the format of YYYY-mm-DD HH:MM:SS AM/PM
export function getDateTime(dateTime) {
   dateTime = newDate(dateTime);

   return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
}

export function formatDate(date) {
   if (isNullOrEmpty(date)) return "";
   const dt = newDate(date);
   return dt.toLocaleDateString();
}

/// Returns a date string in the browser's locale format (i.e. language setting)
export function getDate(date) {
   date = newDate(date);
   return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

/// Returns a time string in the format of HH:MM:SS or H:MM:SS AM/PM
export function getTime(date, military = false, includesec = true) {
   date = newDate(date);

   let ampm = "AM";
   if (military) ampm = "";
   let hr = date.getHours();
   if (military) {
      if (hr.toString().length === 1) hr = "0" + hr;
   } else {
      if (hr > 11) ampm = "PM";
      if (hr > 12) {
         hr = hr - 12;
      } else if (hr === 0) {
         hr = 12;
      }
   }
   let min = date.getMinutes();
   if (min.toString().length === 1) min = "0" + min;
   let sec = date.getSeconds();
   if (sec.toString().length === 1) sec = "0" + sec;
   let returntime = hr + ":" + min;
   if (includesec) returntime += ":" + sec;
   if (military) return returntime;
   return  returntime + " " + ampm;
}

export function getTimeObject(time, asString) {
   if (time === null) return null;
   if (asString === undefined) asString = false;

   if (typeof time === "string") {
      let hh;
      let mm;
      let ss = 0;
      const parts = time.split(" ");
      const timeparts = parts[0].split(":");
      hh = Number(timeparts[0]);
      mm = Number(timeparts[1]);
      if (timeparts.length > 2) ss = Number(timeparts[2]);
      if (time.match(/\d{1,2}:\d{1,2}:\d{1,2} [AP]M/) !== null) {
         if (parts[1] === "PM") hh = hh + 12;
         if (hh === 24) hh = 0;
      }
      const date = new Date(2000, 0, 1, hh, mm, ss);
      if (asString) return getLocalISODateTime(date);
      return date;
   }
   return newDate(time);
}

//strips the non-date or the non-time part for use in labels
export function fixDateTimeLabelFormat(value, military = false, includesec = true) {
   if (value == null) return value;
   if (value.toString().indexOf("null") >= 0) {
      value = value.replace(/null/g, "");
   };
   if (value.toString().indexOf("T00:00:00") >= 0) {
      value = value.replace("T00:00:00", "");
   };
   if (value.toString().indexOf("2000-01-01T") >= 0) {
      let newtimevalue = getTime(value.substr(value.indexOf("2000-01-01T"), 19), military, includesec)
      value = value.replace(value.substr(value.indexOf("2000-01-01T"), 19), newtimevalue);
   };
   return value;
}

export function getDateObject(date) {
   if (typeof date === "object") return date;

   if (typeof date === "string") {
      if (date.match(/\d{4}-\d{2}-\d{2}/g) && date.indexOf("T") === -1) {
         date = date + "T00:00:00";
      }
   }
   return new Date(date);
}

export function getDateTimeObject(raw) {
   if (isNullOrEmpty(raw)) return newDate();
   if (typeof raw === "string") {
      if (!raw.match(/\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2} [AP]M/g)) {
         return newDate();
      }
   }
   var secs = Date.parse(raw);
   return new Date(secs);
}

export function getUnixTimestamp(date) {
   const unixDateTime = newDate(date);

   return Math.floor(unixDateTime / 1000);
}

// Compare date/time 1 to date/time 2.
// returns 1 (greater than), 0 (equal), -1 (less than), null
export function compareDates(datetime1, datetime2) {
   if (isNullOrEmpty(datetime1) || isNullOrEmpty(datetime2)) return null;

   const compare1 = new Date(datetime1).getTime();
   const compare2 = new Date(datetime2).getTime();

   if (compare1 > compare2) return 1;
   if (compare1 < compare2) return -1;
   if (compare1 === compare2) return 0;

   return null;
}
