import React, { useState, useEffect, useRef } from "react";
import {
   ListView,
   ListViewHeader,
   ListViewFooter,
} from "@progress/kendo-react-listview";
import FormData from "./FormData";
import FormDataInfo from "./FormDataInfo";
import { GetDataAndList, SaveDataValue } from "../db/dataHandler";
import DataNote from "./FormDataTypes/DataNote";
import { isNullOrEmpty } from "../helpers/utils";
import { UpdateSiblings } from "../helpers/groupDataUtils";
import "./FormGroupData.css";
import { FormBackButton } from "./FormBackButton";
import { ChangeRequestNotation } from './ChangeRequestNotation';

function FormGroupData({
   form,
   groupId,
   setGroupId,
   sizeFactor,
   formIsLocked,
   online,
}) {
   const [renderCount, setRenderCount] = useState(0);
   const refGroupData = useRef(null);
   const anchor = useRef(null);
   const [datalist, setDatalist] = useState(null); //form.FormGroups[groupId].Data);
   const refElements = useRef({});

   const prevGroupId =
      groupId === "root" || form.FormGroups[groupId].groupId === 0
         ? "root"
         : form.FormGroups[groupId].groupId;

   useEffect(() => {
      let isMounted = true;
      if (refGroupData.current == null) {
         GetDataAndList(form, groupId).then((response) => {
            if (isMounted) {
               refGroupData.current = response.data;
               setDatalist([...response.list]);
               const newcount = renderCount + 1;
               setRenderCount(newcount);
            }
         });
      }
      return () => {
         isMounted = false;
      };
   }, [form.FormGroups, groupId, form, renderCount, datalist]);

   const comment =
      groupId === "root"
         ? form.ChangeRequestComment
         : form.FormGroups[groupId].Comment;

   const dataListHeader = () => {
      const label =
         groupId === "root" ? "Home" : form.FormGroups[groupId].Label;
      const note =
         groupId === "root"
            ? ""
            : form.def.GroupDefs[form.FormGroups[groupId].DefId].Note;
      return (
         <ListViewHeader
            style={{
               color: "black",
               fontWeight: "bold",
               fontSize: 1 * sizeFactor + "rem",
               padding: 5,
               borderTop: '2px solid lightgrey',
               borderBottom: '2px solid lightgrey',
               marginBottom: '5px',
               backgroundColor: 'rgb(245, 245, 245)',
            }}
         >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
               <div>
                  {label}
                  {isNullOrEmpty(note) ? null : (
                     <DataNote
                        note={note}
                        anchor={anchor}
                        sizeFactor={sizeFactor}
                     />
                  )}
               </div>
               {groupId !== "root" &&
                  <FormBackButton formId={form.Id} prevGroupId={prevGroupId} setGroupId={setGroupId} />
               }
            </div>
         </ListViewHeader>
      );
   };

   const dataListFooter = () => {
      return (
         <ListViewFooter
            style={{
               paddingLeft: "5px",
               paddingTop: "5px",
               paddingBottom: "5px",
               paddingRight: "5px"
            }}
         >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
               <div
                  style={{
                     color: "lightgrey",
                     fontSize: 0.85 * sizeFactor + "rem",
                  }}
               >
                  &nbsp;
               </div>
               {groupId !== "root" &&
                  <FormBackButton formId={form.Id} prevGroupId={prevGroupId} setGroupId={setGroupId} />
               }
            </div>
         </ListViewFooter>
      );
   };

   const saveData = async (dataId, value) => {
      if (formIsLocked) return;
      await SaveDataValue(dataId, value, form, groupId).then((response) => {
         let refresh = UpdateSiblings({
            oldGroupData: refGroupData.current,
            newGroupData: response,
            dataDefs: form.def.DataDefs,
            refElements: refElements,
            dataId: dataId,
         });
         refGroupData.current = response;
         var defid = refGroupData.current[dataId].DefId;
         if (!isNullOrEmpty(defid)) {
            if (form.def.DataDefs[defid].maps != null) {
               if(form.def.DataDefs[defid].maps.filterReferences.length > 0) { //checks dependent field for filtering select list
                  refresh = true;
               }
            }
         }
         if (refresh) {
            let newcount = renderCount + 1;
            setRenderCount(newcount);
         }
      });
   };

   const addRefElement = (dataId, ref) => {
      refElements.current[dataId] = ref;
   };

   const datalisting = (props) => {

      const data = refGroupData.current[props.dataItem.id];

      if (props.dataItem.infoonly) {
         return (
            <FormDataInfo
               dataDef={form.def.DataDefs[props.dataItem.id]}
               sizeFactor={sizeFactor}
               hidden={data.hidden}
            />
         );
      } else if (
         refGroupData.current == null ||
         !refGroupData.current.hasOwnProperty(props.dataItem.id)
      ) {
         return null;
      }

      return (
         <FormData
            key={props.dataItem}
            form={form}
            data={data}
            saveData={saveData}
            addRefElement={addRefElement}
            sizeFactor={sizeFactor}
            formIsLocked={formIsLocked}
            online={online}
         />
      );
   };

   return (
      <div>
         {(!!comment || !!form.ChangeRequestDocumentId) &&
            <ChangeRequestNotation
               sizeFactor={sizeFactor}
               comment={comment}
               documentId={form.ChangeRequestDocumentId}
               isOnline={online}
            />
         }
         <ListView
            data={datalist === null ? [] : datalist}
            item={datalisting}
            header={dataListHeader}
            footer={dataListFooter}
         />
      </div>
   );
}

export default FormGroupData;
