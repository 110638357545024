import React, { useRef, useEffect } from "react";
import DataLabel from "./DataLabel";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { isNullOrEmpty, getDateObject } from "../../helpers/utils";

function DataDate({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);
   const thisValue = isNullOrEmpty(dataValue) ? null : getDateObject(dataValue);
   return (
      <>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <DatePicker
            id={editorId}
            defaultValue={thisValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={dataDef.ReadOnly || formIsLocked}
            required={dataDef.Required}
            style={{
               fontSize: 0.85 * sizeFactor + "rem",
               borderRadius: "2px",
               padding: "5px",
               height: "calc(1.4285714286em + 10px)",
               color: "black",
               backgroundColor: "white",
               borderColor: "rgba(0, 0, 0, 0.08)",
            }}
         />
      </>
   );
}

export default DataDate;
