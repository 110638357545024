import React, { useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { register } from "../serviceWorkerRegistration";

function ServiceWorkerWrapper() {
   const [showReload, setShowReload] = useState(false);

   const onServiceWorkerUpdate = (registration) => {
      if (registration.waiting !== null) {
         registration.waiting.postMessage({ type: "SKIP_WAITING" });
         window.location.reload();
      }
   };

   useEffect(() => {
      register({ onUpdate: onServiceWorkerUpdate });
   }, []);

   return (
      <>
         {showReload ? (
            <Dialog
               title="New Version Available!"
               onClose={() => setShowReload(false)}
            >
               Click 'Reload' to use the new version.
               <DialogActionsBar>
                  <button className="k-button">Reload</button>
                  <button
                     className="k-button"
                     onClick={() => setShowReload(false)}
                  >
                     Cancel
                  </button>
               </DialogActionsBar>
            </Dialog>
         ) : null}
      </>
   );
}

export default ServiceWorkerWrapper;
