/* eslint-disable eqeqeq */
import React, { useRef, useEffect } from "react";
import DataLabel from "./DataLabel";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { isNullOrEmpty } from "../../helpers/utils";

function DataNumber({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);
   const thisValue =
      isNullOrEmpty(dataValue) || (dataDef.Required && dataValue == 0)
         ? null
         : isNaN(Number(dataValue))
         ? null
         : Number(dataValue);

   let numoptions = {
      ref: ref,
      id: editorId,
      defaultValue: thisValue,
      onChange: handleOnChange,
      onBlur: handleOnBlur,
      disabled: dataDef.ReadOnly || formIsLocked,
      required: dataDef.Required,
      size: "large",
      fillMode: "solid",
      rounded: "medium",
      spinners: false,
   };

   let placeholder = "";
   if (
      dataDef.InputTypeOptions !== null &&
      typeof dataDef.InputTypeOptions === "object"
   ) {
      if (dataDef.InputTypeOptions.hasOwnProperty("min")) {
         numoptions.min = dataDef.InputTypeOptions.min;
         placeholder = numoptions.min;
      }
      if (dataDef.InputTypeOptions.hasOwnProperty("max")) {
         numoptions.max = dataDef.InputTypeOptions.max;
         if (isNullOrEmpty(placeholder)) {
            placeholder += "? - ";
         } else {
            placeholder += " - ";
         }
         placeholder += numoptions.max;
      } else if (!isNullOrEmpty(placeholder)) {
         placeholder += " - ?";
      }
      if (!isNullOrEmpty(placeholder)) placeholder = "(" + placeholder + ")";
      if (dataDef.InputTypeOptions.hasOwnProperty("step")) {
         numoptions.step = dataDef.InputTypeOptions.step;
         numoptions.spinners = true;
         if (!isNullOrEmpty(placeholder)) placeholder += " ";
         placeholder += "+/- " + numoptions.step;
      }
   }

   return (
      <>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <div style={{ display: "flex" }}>
            <div>
               <NumericTextBox {...numoptions} />
            </div>
            <div
               style={{
                  color: "blue",
                  fontSize: 0.75 * sizeFactor + "rem",
                  paddingLeft: "3px",
               }}
            >
               {placeholder}
            </div>
         </div>
      </>
   );
}

export default DataNumber;
