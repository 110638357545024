import { instance as axios } from "./apiClient";
import * as logger from "../helpers/logger";
import { normalizeUrl, isNullOrEmpty } from "../helpers/utils";
import { UpdateUserDb } from "../db/userHandler";

export async function EmailForm(id, accessToken, resendPdf) {
   await logger.System("apiUtils:EmailForm", "Email Form " + id);

   const url =
      normalizeUrl(
         process.env.REACT_APP_API_SERVER_URL,
         "api/EnFormMApi/EmailForm"
      ) +
      "?id=" +
      id +
      "&resendPdf=" +
      (resendPdf ? "true" : "false");

   const config = {
      headers: { Authorization: "Bearer " + accessToken },
   };
   let promise = axios
      .get(url, config)
      .then(async (response) => {
         if (!isNullOrEmpty(response.data)) {
            if (!response.data.Success) {
               if (response.data.Errors.length > 0) {
                  let errmsg = "";
                  for (let e = 0; e < response.data.Errors.length; e++) {
                     if (!isNullOrEmpty(errmsg)) errmsg += ", ";
                     errmsg += response.data.Errors[e];
                     await logger.Error(
                        "apiUtil:EmailForm",
                        "Server error: " + response.data.Errors[e]
                     );
                  }
                  throw new Error(errmsg);
               }
            }
         }
         return true;
      })
      .catch((error) => {
         logger.Error("apiUtil:EmailForm", "Error emailing form", error);
         throw new Error("Email Form - " + error);
      });

   return promise;
}

export async function GeneratePdfBytes(id, accessToken) {
   await logger.System("apiUtils:PreviewPdf", "Preview Pdf Form " + id);

   const normalizedUrl = normalizeUrl(process.env.REACT_APP_API_SERVER_URL,"api/EnFormMApi/PreviewPdf");
   const url = `${normalizedUrl}?id=${id}`;

   const config = {
      headers: { Authorization: "Bearer " + accessToken }
   };

   let promise = axios
      .get(url, config)
      .then(async (response) => {

         if (isNullOrEmpty(response.data)) {
            throw new Error("Invalid Response");
         }

         if (response.data.Success) {            
            return response.data.Data;
         }

         if (response.data.Errors.length > 0) {
            let errmsg = "";
            for (let e = 0; e < response.data.Errors.length; e++) {
               if (!isNullOrEmpty(errmsg)) errmsg += ", ";
               errmsg += response.data.Errors[e];
               await logger.Error(
                  "apiUtil:PreviewPdf",
                  "Server error: " + response.data.Errors[e]
               );
            }
            
            throw new Error(errmsg);             
         }
      })
      .catch((error) => {
         logger.Error("apiUtil:PreviewPdf", "Error Previewing Pdf", error);
         throw new Error("Preview Pdf Form - " + error);
      });

   return promise;
}

export async function SendEmail(
   type,
   title,
   message,
   to,
   content,
   accessToken
) {
   await logger.Info("apiUtils:SendEmail", title);

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/SendEmail"
   );

   const config = {
      headers: { Authorization: "Bearer " + accessToken },
   };
   const dto = {
      Type: type,
      Title: title,
      Message: message,
      Data: JSON.stringify(content),
      To: to,
   };
   return axios
      .post(url, dto, config)
      .then(async (response) => {
         if (!isNullOrEmpty(response.data)) {
            if (!response.data.Success) {
               if (response.data.Errors.length > 0) {
                  let errmsg = "";
                  for (let e = 0; e < response.data.Errors.length; e++) {
                     if (!isNullOrEmpty(errmsg)) errmsg += ", ";
                     errmsg += response.data.Errors[e];
                     await logger.Error(
                        "apiUtil:SendEmail",
                        "Server error: " + response.data.Errors[e]
                     );
                  }
                  throw new Error(errmsg);
               }
            }
         }
         return true;
      })
      .catch((error) => {
         logger.Error("apiUtil:SendEmail", "Error sending email. ", error);
         throw new Error("Send Email - " + error);
      });
}

export async function SyncDigitalId(accessToken, digitalId) {
   const config = { headers: { Authorization: "Bearer " + accessToken } };
   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/SyncDigitalId"
   );
   const dto = {
      Name: "",
      Content: digitalId,
   };
   return axios.post(url, dto, config).then(
      async function (response) {
         if (response.data) {
            const userdb = { DigitalIdOnFile: response.data };
            UpdateUserDb(userdb);
         }
         return response.data;
      },
      function (error) {
         logger.Error(
            "apiUtils:SyncDigitalId",
            "Error occurred syncing Digital Id",
            error
         );
         return false;
      }
   );
}
