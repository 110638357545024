import { Button } from '@progress/kendo-react-buttons'

interface IProps {
  formId: number,
  prevGroupId: number,
  setGroupId: (formId: number, prevGroupId: number) => void;
};

const FormBackButton = ({ formId, prevGroupId, setGroupId }: IProps) =>
(
  <Button
    themeColor="secondary"
    icon="undo"
    onClick={() => setTimeout(() => setGroupId(formId, prevGroupId), 100)}
  >
    Back
  </Button>
)

export { FormBackButton }