import { useState, useEffect, useRef } from "react";
import {
   Card,
   CardBody,
   CardHeader,
   CardFooter,
} from "@progress/kendo-react-layout";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { isNullOrEmpty, getDateObject, getDate } from "../helpers/utils";
import * as enums from "../helpers/enums";
import { LocationInput } from "./LocationInput";
import "./FormEdit.css";

const isInViewport = (element) => {
   const rect = element.getBoundingClientRect();
   return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
         (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
   );
};

function FormEdit({ form, sizeFactor, setEditId, saveEditForm }) {
   const ref = useRef(null);
   const [editForm, setEditForm] = useState({
      Location:
         form.hasOwnProperty("Location") && !isNullOrEmpty(form.Location)
            ? form.Location
            : "",
      OverrideLocation:
         !form.hasOwnProperty("Location") || isNullOrEmpty(form.Location)
            ? ""
            : form.Location.startsWith("lng:")
            ? ""
            : form.Location,
      GeoLocation:
         form.hasOwnProperty("Location") && !isNullOrEmpty(form.Location) && form.Location.startsWith("lng:")
            ? form.Location
            : "",
      ReportDate: getDateObject(form.ReportDate),
      Title:
         form.hasOwnProperty("Title") && !isNullOrEmpty(form.Title)
            ? form.Title
            : "",
   });

   useEffect(() => {
      if (ref !== null && !isInViewport(ref.current)) {
         ref.current.scrollIntoView({ inline: "end" });
      }
   }, []);

   const handleOnChange = (event) => {
      if(event.target.name === "ReportDate" || event.target.name === "Title") {
         setEditForm({
            ...editForm,
            [event.target.name]: event.target.value,
         });
      }
   };
   
   const handleOnSave = () => {
      const updateobject = {
         Location: isNullOrEmpty(editForm.OverrideLocation)
            ? editForm.Location
            : editForm.OverrideLocation,
         ReportDate: getDate(editForm.ReportDate) + "T00:00:00",
         State:
            form.State === enums.State.New
               ? enums.State.New
               : enums.State.Update,
         Title: editForm.Title,
      };
      saveEditForm(form.Id, updateobject);
      setEditId(0);
   };

   const onManualLocate = (newLocation) => {
      setEditForm({
         ...editForm,
         OverrideLocation: newLocation,
         Location: ''
      });
    }

    const onGeoLocate = (geoLocation) => {
      setEditForm({
         ...editForm,
         Location: geoLocation,
         OverrideLocation: ''
      });
    }

   const getLocationType = () => {
      if(!isNullOrEmpty(editForm.OverrideLocation)) {
         return "manual";
      }
      return "geo";
   }

   const setFormGeoLocation = (geoLocation) => {
      setEditForm({
         ...editForm,
         GeoLocation: geoLocation,
      });
   }

   return (
      <div ref={ref}>
         <Card
            style={{
               backgroundColor: "#f8f8f8",
               border: "1px solid gold",
            }}
         >
            <CardHeader>
               <span style={{ fontSize: "18px", fontWeight: "600" }}>Edit</span>
            </CardHeader>
            <CardBody>
               <div style={{
                  display: 'grid',
                  gap: '10px'
               }}>
                  <div>
                     <div
                        style={{
                           fontWeight: "600",
                           fontSize: 0.95 * sizeFactor + "rem",
                        }}>
                           Title (optional)
                     </div>
                     <input
                        id="formEdit-title-input"
                        type="text"
                        name="Title"
                        onChange={handleOnChange}
                        defaultValue={editForm.Title}
                        placeholder="Leave blank for default form title"
                        style={{
                           height: "calc(1.4285714286em + 10px)",
                           fontSize: 0.9 * sizeFactor + "rem",
                        }}
                     />
                  </div>
                  <div>
                     <div
                        style={{
                           fontWeight: "600",
                           fontSize: 0.95 * sizeFactor + "rem",
                        }}
                     >
                        Report Date
                     </div>
                     <div style={{ width: "70%" }}>
                        <DatePicker
                           name="ReportDate"
                           value={editForm.ReportDate}
                           onChange={handleOnChange}
                        />
                     </div>
                  </div>
                  <LocationInput 
                     onManualLocate={onManualLocate}
                     onGeoLocate={onGeoLocate}
                     location={editForm.OverrideLocation}
                     sizeFactor={sizeFactor}
                     locationType={getLocationType()}
                     setFormGeoLocation={setFormGeoLocation}
                  />
               </div>
            </CardBody>
            <CardFooter>
               <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <div>
                     <Button
                        style={{
                           fontSize: 0.95 * sizeFactor + "rem",
                        }}
                        icon="save"
                        onClick={handleOnSave}
                     >
                        Save
                     </Button>
                  </div>
                  <div>
                     <Button
                        style={{
                           fontSize: 0.95 * sizeFactor + "rem",
                        }}
                        icon="cancel"
                        onClick={() => setEditId(0)}
                     >
                        Cancel
                     </Button>
                  </div>
               </div>
            </CardFooter>
         </Card>
      </div>
   );
}

export default FormEdit;
