import React, { useRef, useEffect } from "react";
import { Input } from "@progress/kendo-react-inputs";
import DataLabel from "./DataLabel";
import { isNullOrEmpty } from "../../helpers/utils";

function DataTextBox({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);
   return (
      <div>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <Input
            ref={ref}
            id={editorId}
            value={dataValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            style={{ width: "100%", fontSize: 1 * sizeFactor + "rem" }}
            disabled={dataDef.ReadOnly || formIsLocked}
            required={dataDef.Required}
         />
      </div>
   );
}

export default DataTextBox;
