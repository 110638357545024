import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import DataLabel from "./DataLabel";
import { Loader } from "@progress/kendo-react-indicators";
import { isNullOrEmpty } from "../../helpers/utils";
import { GetGeoLocation } from "../../helpers/location";
import "./DataGeoLocation.css";

function DataGeoLocation({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const [isLoading, setIsLoading] = useState(false);

   const [longitude, setLongitude] = useState(
      isNullOrEmpty(dataValue)
         ? ""
         : Number(dataValue.split(",")[0].split(":")[1]).toFixed(6)
   );
   const [latitude, setLatitude] = useState(
      isNullOrEmpty(dataValue)
         ? ""
         : Number(dataValue.split(",")[1].split(":")[1]).toFixed(6)
   );
   const [altitude, setAltitude] = useState(
      isNullOrEmpty(dataValue) ? "" : dataValue.split(",")[2].split(":")[1]
   );

   const getGeoLocation = async () => {
      setIsLoading(true);
      const newvalue = await GetGeoLocation();
      setIsLoading(false);
      handleOnChange(newvalue);

      setLongitude(
         isNullOrEmpty(newvalue)
            ? ""
            : Number(newvalue.split(",")[0].split(":")[1]).toFixed(6)
      );
      setLatitude(
         isNullOrEmpty(newvalue)
            ? ""
            : Number(newvalue.split(",")[1].split(":")[1]).toFixed(6)
      );
      setAltitude(
         isNullOrEmpty(newvalue) ? "" : newvalue.split(",")[2].split(":")[1]
      );
   };

   const handleLatLngAltChange = (event) => {
      switch (event.target.name) {
         case "longitude":
            handleOnChange(
               "lng:" +
                  event.target.value +
                  ",lat:" +
                  latitude +
                  ",alt:" +
                  altitude
            );
            setLongitude(event.target.value);
            break;

         case "latitude":
            handleOnChange(
               "lng:" +
                  longitude +
                  ",lat:" +
                  event.target.value +
                  ",alt:" +
                  altitude
            );
            setLatitude(event.target.value);
            break;

         case "altitude":
            handleOnChange(
               "lng:" +
                  longitude +
                  ",lat:" +
                  latitude +
                  ",alt:" +
                  event.target.value
            );
            setAltitude(event.target.value);
            break;

         default:
            console.log("");
      }
   };

   return (
      <div>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />

         <div
            style={{
               display: "flex",
               justifyContent: "space-between",
               width: "100%",
            }}
         >
            <div style={{ width: "75%" }}>
               <div style={{ display: "flex" }}>
                  <div
                     className="location-label"
                     style={{
                        fontSize: 1 * sizeFactor + "rem",
                     }}
                  >
                     Latitude:{" "}
                  </div>
                  <div
                     style={{
                        fontSize: 0.9 * sizeFactor + "rem",
                     }}
                  >
                     <input
                        type="text"
                        name="latitude"
                        value={latitude}
                        className="location-input"
                        onChange={handleLatLngAltChange}
                     />
                  </div>
               </div>
               <div style={{ display: "flex" }}>
                  <div
                     className="location-label"
                     style={{
                        fontSize: 1 * sizeFactor + "rem",
                     }}
                  >
                     Longitude:{" "}
                  </div>
                  <div
                     style={{
                        fontSize: 0.9 * sizeFactor + "rem",
                     }}
                  >
                     <input
                        type="text"
                        name="longitude"
                        value={longitude}
                        className="location-input"
                        onChange={handleLatLngAltChange}
                     />
                  </div>
               </div>
               <div style={{ display: "flex" }}>
                  <div
                     className="location-label"
                     style={{
                        fontSize: 1 * sizeFactor + "rem",
                     }}
                  >
                     Altitude:{" "}
                  </div>
                  <div
                     style={{
                        fontSize: 0.9 * sizeFactor + "rem",
                     }}
                  >
                     <input
                        type="text"
                        name="altitude"
                        value={altitude}
                        className="location-input"
                        onChange={handleLatLngAltChange}
                     />
                  </div>
               </div>
            </div>
            <div>
               <div>
                  <Button
                     icon="refresh"
                     onClick={getGeoLocation}
                     disabled={formIsLocked}
                     style={{
                        width: "100%",
                        marginBottom: "3px",
                     }}
                  >
                     <span className="k-icon k-i-globe"></span>
                  </Button>
               </div>
            </div>
         </div>
         {isLoading ? (
            <div
               style={{
                  textAlign: "center",
                  marginTop: "-50px",
                  height: "50px",
               }}
            >
               <Loader type="infinite-spinner" />
            </div>
         ) : null}
      </div>
   );
}

export default DataGeoLocation;
