import db from "./db";
import { parse } from "../helpers/parser";
import * as enums from "../helpers/enums";
import { isNullOrEmpty } from "../helpers/utils";
import * as logger from "../helpers/logger";


export async function GetDefById(defId) {
   return await db.defs.get(defId);
}

export async function GetWorkOrderById(workOrderId) {
   const workorders = await db.vals.get("WorkOrders");
   return workorders.records[workOrderId];
}

export async function GetFormDefsList() {
   let list = [];
   await db.defs.toCollection().each(function (def, cursor) {
      list.push({
         Id: def.Id,
         Name: def.Name,
         Version: def.Version,
         Label: def.Label,
      });
   });
   list.sort((a, b) => {
      return a.Name < b.Name ? -1 : a.Name === b.Name ? 0 : 1;
   });
   return list;
}

export async function GetFormDefsForSelect() {
   let tree = [];
   const workorders = await db.vals.get("WorkOrders");
   let deflist = [];
   let defnamelist = [];
   await db.defs.toCollection().each(function (def, cursor) {
      if (!isNullOrEmpty(def.WorkOrders)) {
         const index = defnamelist.findIndex((item) => {
            return item.Name === def.Name;
         });
         if (index !== -1) {
            const defnamedef = defnamelist[index];
            if (defnamedef.Version < def.Version) {
               defnamedef.Version = def.Version;
               deflist[defnamedef.Index] = def;
            }
         } else {
            deflist.push(def);
            defnamelist.push({
               Name: def.Name,
               Version: def.Version,
               Index: deflist.length - 1,
            });
         }
      }
   });
   defnamelist = null;
   deflist.sort((a, b) => {
      return a.Name < b.Name ? -1 : a.Name === b.Name ? 0 : 1;
   });
   deflist.sort((a, b) => {
      return a.FormType < b.FormType ? -1 : a.FormType === b.FormType ? 0 : 1;
   });
   for (let f = 0; f < deflist.length; f++) {
      const def = deflist[f];
      const formtype = isNullOrEmpty(def.FormType) ? "Unknown" : def.FormType;
      for (let w = 0; w < def.WorkOrders.length; w++) {
         const workorder = workorders?.records[def.WorkOrders[w]];
         if (isNullOrEmpty(workorder)) continue;
         const workorderindex = tree.findIndex((item) => {
            return item.data.id === "workorder-" + workorder.Id;
         });
         let workorderLimb;
         if (workorderindex !== -1) {
            workorderLimb = tree[workorderindex];
         } else {
            workorderLimb = {
               data: {
                  id: "workorder-" + workorder.Id,
                  text: workorder.Name,
               },
               items: [],
            };
            tree.push(workorderLimb);
         }
         const formtypeindex = workorderLimb.items.findIndex((item) => {
            return (
               item.data.id ===
               "workorder-" + workorder.Id + "-formtype-" + formtype
            );
         });
         let formtypelimb;
         if (formtypeindex !== -1) {
            formtypelimb = workorderLimb.items[formtypeindex];
         } else {
            formtypelimb = {
               data: {
                  id: "workorder-" + workorder.Id + "-formtype-" + formtype,
                  text: formtype,
               },
               items: [],
            };
            workorderLimb.items.push(formtypelimb);
         }
         formtypelimb.items.push({
            data: {
               id: "workorder-" + workorder.Id + "-def-" + def.Id,
               text: def.Name + " - " + def.Label,
               Id: def.Id,
               Name: def.Name,
               Version: def.Version,
               Label: def.Label,
            },
         });
      }
   }
   deflist = null;
   return tree;
}

export async function GetUsedDefIds() {
   let list = [];
   await db.forms.toCollection().each(function (form, cursor) {
      if (list.indexOf(form.DefId) === -1) {
         list.push(form.DefId);
      }
   });
   return list;
}

export async function SaveDefsToDB(defs) {
   logger.Info("defHandler:SaveDefsToDB", "Save Defs to Db start");
   let deflist = [];
   // Extract Form Definitions and Normalize to Redux suggested best practices
   for (let d = 0; d < defs.length; d++) {
      const def = defs[d];
      try {
         await saveDef(def);
         deflist.push(def.Id);
      } catch (err) {
         await logger.Error(
            "defHandler:SaveDefsToDB",
            "Saving Definition " + def.Id,
            err
         );
      }
   }
   try {
      await verifyAndSyncLocalDefs(deflist);
   } catch (err) {
      await logger.Error(
         "defHandler:SaveDefsToDB",
         "Verifying Save of Definitions",
         err
      );
   }
}

async function saveDef(def) {
   await logger.Info("defHandler:saveDef", "Save Definition " + def.Name);
   let putdef = {
      Id: def.Id,
      Label: def.Label,
      Name: def.Name,
      Version: def.Version,
      Groups: def.Groups,
      SignatureRequired: def.SignatureRequired,
      SendExcelFile: def.SendExcelFile,
      DataDefs: {},
      GroupDefs: {},
      FormType: def.FormType,
      WorkOrders: def.hasOwnProperty("WorkOrders") ? def.WorkOrders : [],
   };
   for (let d = 0; d < def.DataDefs.length; d++) {
      const dd = def.DataDefs[d];
      if (!isNullOrEmpty(dd.InputTypeOptions)) {
         let opt = dd.InputTypeOptions.trim();
         if (opt.startsWith("{") && opt.endsWith("}")) {
            dd.InputTypeOptions = JSON.parse(opt);
         }
      }
      putdef.DataDefs[dd.Id] = dd;
   }
   for (let g = 0; g < def.GroupDefs.length; g++) {
      const gg = def.GroupDefs[g];
      putdef.GroupDefs[gg.Id] = gg;
   }
   await db.defs.put(putdef, putdef.Id);

   let defslastdb = await db.defsLast.get(def.Name);
   if (defslastdb === undefined) {
      await db.defsLast.put({ id: def.Name });
   }
}

async function verifyAndSyncLocalDefs(defList) {
   const defkeys = await db.defs.toCollection().primaryKeys();
   for (let d = 0; d < defList.length; d++) {
      if (defkeys.indexOf(defList[d]) === -1) {
         throw new Error("Definition " + defList[d] + " not found");
      }
   }
   let deletelist = [];
   for (let k = 0; k < defkeys.length; k++) {
      if (defList.indexOf(defkeys[k]) === -1) {
         deletelist.push(defkeys[k]);
      }
   }

   for (let i = 0; i < deletelist.length; i++) {
      await db.defs.delete(deletelist[i]);
   }
}

export async function ParseDataDefs() {
   logger.Info("defHandler:ParseDataDefs", "Parse Data Defs start");
   const defkeys = await db.defs.toCollection().primaryKeys();

   let success = true;
   for (let k = 0; k < defkeys.length; k++) {
      const defid = defkeys[k];
      try {
         await parseDataDef(defid);
      } catch (err) {
         await logger.Error(
            "defHandler:ParseDataDefs",
            "Parse Defintion " + defid,
            err
         );
         success = false;
      }
   }
   return success;
}

async function parseDataDef(id) {
   let dbdef = await db.defs.get(id);
   let datadeflist = {};
   let groupdeflist = {};
   let datareflist = {};
   let datahidlist = {};
   let datagrouplist = {};
   const groupdefkeys = Object.keys(dbdef.GroupDefs);
   for (let g = 0; g < groupdefkeys.length; g++) {
      const groupdef = dbdef.GroupDefs[groupdefkeys[g]];
      let hcomponents = parse(groupdef.Hidden);
      groupdef.hiddenComponents = hcomponents;
      groupdeflist[groupdef.Name] = { Id: groupdef.Id, data: {} };
      for (let d = 0; d < groupdef.Data.length; d++) {
         const datadefid = groupdef.Data[d];
         const datadef = dbdef.DataDefs[datadefid];
         groupdeflist[groupdef.Name].data[datadef.Name] = datadefid;
      }
   }
   for (let g = 0; g < groupdefkeys.length; g++) {
      const groupdef = dbdef.GroupDefs[groupdefkeys[g]];
      getDataDefFromNode(
         groupdef.hiddenComponents,
         groupdeflist,
         datagrouplist,
         groupdef.Id
      );
   }
   const datadefkeys = Object.keys(dbdef.DataDefs);
   for (let d = 0; d < datadefkeys.length; d++) {
      let datadef = dbdef.DataDefs[datadefkeys[d]];
      let components = parse(datadef.Default);
      let fcomponents = parse(datadef.FilterOnValue);
      let hcomponents = parse(datadef.Hidden);

      getDataDefFromNode(components, groupdeflist, datadeflist, datadef.Id);
      getDataDefFromNode(fcomponents, groupdeflist, datareflist, datadef.Id);
      getDataDefFromNode(hcomponents, groupdeflist, datahidlist, datadef.Id);

      datadef.maps = {
         defaultComponents: components,
         defaultReferences: [],
         filterComponents: fcomponents,
         filterReferences: [],
         hiddenComponents: hcomponents,
         hiddenReferences: [],
         groupReferences: [],
      };
   }
   setReferences(datadeflist, dbdef, "defaultReferences");
   setReferences(datareflist, dbdef, "filterReferences");
   setReferences(datahidlist, dbdef, "hiddenReferences");
   setReferences(datagrouplist, dbdef, "groupReferences");

   await db.defs.put(dbdef);
}

function getDataDefFromNode(node, groupDefList, dataDefList, defId) {
   if (
      isNullOrEmpty(node) ||
      !node.hasOwnProperty("type") ||
      node.type === enums.DefaultType.Literal ||
      node.type === enums.DefaultType.Action
   )
      return;

   if (node.type === enums.DefaultType.Reference) {
      if (!node.hasOwnProperty("args")) return;

      for (let a = 0; a < node.args.length; a++) {
         getDataDefFromNode(node.args[a], groupDefList, dataDefList, defId);
      }
      return;
   } else if (node.type === enums.DefaultType.Operator) {
      getDataDefFromNode(node.left, groupDefList, dataDefList, defId);
      getDataDefFromNode(node.right, groupDefList, dataDefList, defId);
      return;
   }

   if (node.type !== enums.DefaultType.GroupData) return; // Should never happen...

   if (isNullOrEmpty(node.info.group) || isNullOrEmpty(node.info.data)) return;
   if (node.info.data.charAt(0) === "@") return;

   const datadefid = groupDefList[node.info.group].data[node.info.data];
   node.info.dataDefId = datadefid;
   if (!dataDefList.hasOwnProperty(datadefid)) {
      dataDefList[datadefid] = [];
   }
   dataDefList[datadefid].push(defId);
}

function setReferences(list, def, property) {
   const keys = Object.keys(list);
   for (let i = 0; i < keys.length; i++) {
      let datadef = def.DataDefs[keys[i]];
      datadef.maps[property] = list[keys[i]];
   }
}
