import React, { useRef } from "react";
import { Label } from "@progress/kendo-react-labels";
import DataNote from "./DataNote";
import { isNullOrEmpty } from "../../helpers/utils";

function DataLabel({ dataDef, dataValue, sizeFactor }) {
   const anchor = useRef(null);

   return (
      <Label
         style={{
            display: "grid",
            gridAutoFlow: 'column',
            gap: '4px',
            justifyContent: 'left',
            alignItems: 'center',
            fontWeight: "600",
            fontSize: 1 * sizeFactor + "rem",
         }}
      >
         {dataDef.Required && isNullOrEmpty(dataValue) && (
            <span
               className="k-icon k-i-warning "
               style={{
                  color: "red",
                  fontSize: 1.2 * sizeFactor + "rem",
               }}
            ></span>
         )}

         {dataDef.Label}
         {isNullOrEmpty(dataDef.Note) ? null : (
            <DataNote
               note={dataDef.Note}
               anchor={anchor}
               sizeFactor={sizeFactor}
            />
         )}
      </Label>
   );
}

export default DataLabel;
