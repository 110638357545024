import db from "./db";
import { isNullOrEmpty } from "../helpers/utils";
import * as enums from "../helpers/enums";
import { incrementTotals } from "../helpers/groupUtils";
import { DeleteForm, GetForm } from "./formHandler";
import { MapGroups } from "./groupHandler";
import { GetGroupDataNameValue } from "./valHandler";
import * as logger from "../helpers/logger";
import { getUser } from "../helpers/userInfo";

let groupProps = null;
let dataProps = null;

export async function GetFormForServer(id) {
   let weatherlist = {};
   let dbform = await db.forms.get(id);
   let anychanges = dbform.State !== enums.State.None;
   let form = getNewObject(dbform, getServerProps(dbform));
   if (dbform.hasOwnProperty("weather") && dbform.weather.length > 0) {
      weatherlist[dbform.Id] = dbform.weather;
   } else {
      weatherlist[dbform.Id] = [];
   }
   form.FormGroups = [];
   form.FormData = [];
   form.documents = [];
   if (form.Status === enums.Status.PendingSubmit) {
      form.Status = enums.Status.Submitted;
   } else if (form.Status === enums.Status.PendingCancel) {
      form.Status = enums.Status.Canceled;
   }

   const formDef = await db.defs.get(form.DefId);

   // if (formDef.DataDefs[documentdata.DefId] && formDef.DataDefs[documentdata.DefId].InfoOnly)
   //    continue;


   try {
      if (await populateFormGroups(form.Groups, form, formDef)) {
         anychanges = true;
      }
   } catch (err) {
      logger.Error(
         "formServer:GetFormsForServer",
         "Error occurred populating groups for form " + form.Id,
         err
      );
      throw err;
   }
   return [form, weatherlist, anychanges];
}

async function populateFormGroups(groups, form, formDef) {
   let anychanges = false;
   for (let g = 0; g < groups.length; g++) {
      const gid = groups[g];
      const groupdb = await db.formGroups.get(gid);
      if (groupdb == null) throw new Error("Invalid Group Id. " + gid);
      if (groupdb.State !== enums.State.None) anychanges = true;
      if (groupProps === null) groupProps = getServerProps(groupdb);
      let group = getNewObject(groupdb, groupProps);
      form.FormGroups.push(group);
      if (group.Groups.length > 0) {
         if (await populateFormGroups(group.Groups, form, formDef)) anychanges = true;
      } else if (group.Data.length > 0) {
         if (await populateFormData(group.Data, form, groups, formDef))
            anychanges = true;
      }
   }
   return anychanges;
}

async function populateFormData(data, form, groups, formDef) {
   let anychanges = false;
   for (let d = 0; d < data.length; d++) {
      const did = data[d];
      const datadb = await db.formData.get(did);
      if (datadb == null) {
         logger.Error(
            "formServer:populateFormDate",
            "Invalid Data Id. " + did,
            JSON.stringify(groups)
         );
         throw new Error("Invalid Data Id. " + did);
      }
      if (datadb.State !== enums.State.None) anychanges = true;
      if (dataProps === null) dataProps = getServerProps(datadb);
      let datum = getNewObject(datadb, dataProps);
      form.FormData.push(datum);

      if (datadb.hasOwnProperty("content")) {
         const isInfoOnly = formDef && formDef.DataDefs[datadb.DefId] && formDef.DataDefs[datadb.DefId].InfoOnly;
         if (isInfoOnly === false) {
            form.documents.push(datadb);
         }
      }
   }
   return anychanges;
}

function getNewObject(object, props) {
   let newobject = {};
   for (let p = 0; p < props.length; p++) {
      newobject[props[p]] = object[props[p]];
   }
   return newobject;
}

function getServerProps(object) {
   const keys = Object.keys(object);
   let props = [];
   for (let k = 0; k < keys.length; k++) {
      const keychar = keys[k].charAt(0);
      if (keychar === keychar.toUpperCase()) props.push(keys[k]);
   }
   return props;
}

export async function SaveFormToLocalDb(newform, documents, newState) {
   logger.Info("formsApi:SaveFormToLocalDb", "Save Form start " + newform.Id);
   const user = await getUser();
   newform.State = newState;
   newform.changeRequest = newform.Status === enums.Status.ChangeRequest;
   newform.assigned =
      newform.AssignedTo === user.Username &&
      newform.Status === enums.Status.Submitted &&
      !isNullOrEmpty(newform.AssignedTo);
   newform.dataDefX = {};
   const formgroups = newform.FormGroups;
   const formdata = newform.FormData;
   delete newform.FormGroups;
   delete newform.FormData;
   let def = await db.defs.get(newform.DefId);
   if (isNullOrEmpty(def)) {
      await logger.Error(
         "formServer:SaveFormToLocalDb",
         "Form definition " +
         newform.DefId +
         " does not exist on the mobile device for form " +
         newform.Id,
         "Missing form definition " + newform.DefId
      );
      return;
   }
   let groupInfo = await getGroupInfo(formgroups, newform.Groups, def);
   if (isNullOrEmpty(groupInfo)) {
      await logger.Error(
         "formServer:SaveFormToLocalDb",
         "Missing group info. Form not saved.",
         "Missing group info"
      );
      return;
   }
   newform.highlight = groupInfo.form.highlight;
   await db.forms.put(newform, newform.Id);

   await logger.Trace("formServer:SaveFormToLocalDb", "Saving Groups " + newform.Id);
   await saveGroupsFromServer(formgroups, newform.Id, groupInfo);

   await logger.Trace("formServer:SaveFormToLocalDb", "Saving Data " + newform.Id);
   await saveDataFromServer(formdata, newform, groupInfo, def, documents);

   await logger.Trace("formServer:SaveFormToLocalDb", "Saving Group Info " + newform.Id);
   await saveGroupInfoFromData(groupInfo);

   await db.forms.put(newform, newform.Id);

   if (newform.Id !== newform.OrigId && !isNullOrEmpty(newform.OrigId)) {
      await logger.Trace(
         "formServer:SaveFormToLocalDb",
         "Delete original. form=" + newform.Id + "; orig form=" + newform.OrigId
      );
      await DeleteForm(newform.OrigId);
   }

   await logger.Trace(
      "formServer:SaveFormToLocalDb",
      "Cleanup Groups and Data " + newform.Id
   );
   await cleanupGroupsAndData(newform.Id);

   let form = await GetForm(newform.Id);
   await MapGroups(form);

   return getDocumentInfo(formdata, def);
}

function getDocumentInfo(formdata, def) {
   let documentinfo = [];
   for (let d = 0; d < formdata.length; d++) {
      const data = formdata[d];
      const datadef = def.DataDefs[data.DefId];
      if (
         (datadef.InputType === enums.InputType.Picture || datadef.InputType === enums.InputType.Attachment) &&
         !isNullOrEmpty(data.Value)
      ) {
         documentinfo.push({ dataId: data.Id, documentId: data.Value });
      }
   }
   return documentinfo;
}

export async function VerifyAndSyncLocalForms(formList) {
   const formkeys = await db.forms.toCollection().primaryKeys();
   for (let f = 0; f < formList.length; f++) {
      if (formkeys.indexOf(formList[f].Id) === -1) {
         await logger.Error(
            "formServer:verifyAndSyncLocalForms",
            "Form " +
            formList[f] +
            " was sent from the server but not found on mobile device",
            "Form " + formList[f] + " not found"
         );
      }
   }

   let deletelist = [];
   for (let k = 0; k < formkeys.length; k++) {
      const formindex = formList.findIndex((item) => {
         return item.Id === formkeys[k];
      });
      if (formindex === -1 && formkeys[k] > 0) {
         deletelist.push(formkeys[k]);
      }
   }

   for (let i = 0; i < deletelist.length; i++) {
      await logger.Info(
         "formServer:verifyAndSyncLocalForms",
         "Delete Form " + deletelist[i]
      );
      await DeleteForm(deletelist[i]);
   }

   return true;
}

export async function IntegrityCheck() {
   logger.Info("formServer:integrityCheck", "Start Integrity Check");
   const formkeys = await db.forms.toCollection().primaryKeys();
   let deletelist = [];
   for (let f = 0; f < formkeys.length; f++) {
      let formdb = await db.forms.get(formkeys[f]);
      if (!(await integrityCheckGroup(formdb.Groups))) {
         deletelist.push(formkeys[f]);
      }
   }

   for (let i = 0; i < deletelist.length; i++) {
      await logger.Info(
         "formServer:integrityCheck",
         "Delete Form " + deletelist[i]
      );
      await DeleteForm(deletelist[i]);
   }

   logger.Info(
      "formServer:integrityCheck",
      "Finish Integrity Check. " + deletelist.length + " form(s) were deleted."
   );
   return true;
}

async function integrityCheckGroup(groups) {
   let success = true;
   for (var g = 0; g < groups.length; g++) {
      let gid = groups[g];
      let groupdb = await db.formGroups.get(gid);
      if (groupdb == null) {
         logger.Error(
            "formsApi:integryCheckGroup",
            "Integrity Group Check failed " + gid,
            "Group " + gid + " does not exist"
         );
         success = false;
      }
      if (groupdb.Type === enums.GroupType.Group) {
         if (!(await integrityCheckGroup(groupdb.Groups))) success = false;
      } else if (groupdb.Type === enums.GroupType.Data) {
         if (!(await integrityCheckData(groupdb.Data))) success = false;
      }
   }
   return success;
}

async function integrityCheckData(datas) {
   let success = true;
   for (let d = 0; d < datas.length; d++) {
      let did = datas[d];
      let datadb = await db.formData.get(did);
      if (datadb == null) {
         logger.Error(
            "formsApi:integryCheckData",
            "Integrity Data Check failed " + did,
            "Data " + did + " does not exist"
         );
         success = false;
      }
   }
   return success;
}

async function getGroupInfo(formGroups, groupsFromForm, def) {
   let groupInfo = { form: { highlight: false }, groups: {}, data: {} };
   let repeats = {};
   getGroupParents(groupsFromForm, 0, groupInfo);
   for (let g = 0; g < formGroups.length; g++) {
      let group = formGroups[g];
      if (group.Highlight) groupInfo.form.highlight = true;
      const groupdef = def.GroupDefs[group.DefId];
      if (isNullOrEmpty(groupdef)) {
         await logger.Error(
            "formServer:getGroupInfo",
            "Group Definition not found. " + group.DefId,
            "Group Definition not found"
         );
         return null;
      }
      if (groupdef.Repeats) {
         repeats[group.Id] = groupdef.Id;
      }
      getGroupParents(group.Groups, group.Id, groupInfo);
      groupInfo.groups[group.Id].dataName = groupdef.DataName;
      groupInfo.groups[group.Id].name = groupdef.Name;
      for (let d = 0; d < group.Data.length; d++) {
         let did = group.Data[d];
         groupInfo.data[did] = group.Id;
      }
   }

   const keys = Object.keys(repeats);
   let sequenceCountList = {};
   for (let k = 0; k < keys.length; k++) {
      const groupid = keys[k];
      const parentIdDefId =
         groupInfo.groups[groupid].parentId + ":" + repeats[groupid];
      if (!sequenceCountList.hasOwnProperty(parentIdDefId))
         sequenceCountList[parentIdDefId] = 0;
      sequenceCountList[parentIdDefId]++;
   }

   for (let k = 0; k < keys.length; k++) {
      const groupid = keys[k];
      const parentIdDefId =
         groupInfo.groups[groupid].parentId + ":" + repeats[groupid];
      let group = groupInfo.groups[groupid];

      if (sequenceCountList.hasOwnProperty(parentIdDefId)) {
         group.sequenceCount = sequenceCountList[parentIdDefId];
      }
   }

   return groupInfo;
}

function getGroupParents(groups, parentId, groupInfo) {
   for (let g = 0; g < groups.length; g++) {
      let gid = groups[g];
      groupInfo.groups[gid] = {
         parentId: parentId,
         name: "",
         dataName: "",
         dataNameId: 0,
         dataNameValue: "",
         total: 0,
         required: 0,
         totalEntered: 0,
         requiredEntered: 0,
         sequenceCount: 0,
      };
   }
}

async function saveGroupsFromServer(groups, formId, groupInfo) {
   for (let g = 0; g < groups.length; g++) {
      let group = groups[g];
      group.State = enums.State.None;
      group.formId = formId;
      group.groupId = groupInfo.groups[group.Id].parentId;
      group.dataNameId = 0;
      group.dataNameValue = "";
      group.total = 0;
      group.required = 0;
      group.totalEntered = 0;
      group.requiredEntered = 0;
      group.sequenceCount = 0;
      await db.formGroups.put(group, group.Id);
   }
}

async function saveGroupInfoFromData(groupInfo) {
   const keys = Object.keys(groupInfo.groups);
   for (let k = 0; k < keys.length; k++) {
      let groupdb = await db.formGroups.get(Number(keys[k]));
      groupdb.dataNameId = groupInfo.groups[keys[k]].dataNameId;
      groupdb.dataNameValue = groupInfo.groups[keys[k]].dataNameValue;
      groupdb.total = groupInfo.groups[keys[k]].total;
      groupdb.required = groupInfo.groups[keys[k]].required;
      groupdb.totalEntered = groupInfo.groups[keys[k]].totalEntered;
      groupdb.requiredEntered = groupInfo.groups[keys[k]].requiredEntered;
      groupdb.sequenceCount = groupInfo.groups[keys[k]].sequenceCount;
      await db.formGroups.put(groupdb, groupdb.Id);
   }
}

async function saveDataFromServer(
   formData,
   newForm,
   groupInfo,
   def,
   documents
) {
   for (let d = 0; d < formData.length; d++) {
      let data = formData[d];
      try {
         data.State = enums.State.None;
         data.formId = newForm.Id;
         data.groupId = groupInfo.data[data.Id];
         data.groupDataNameIds = [];

         const datadef = def.DataDefs[data.DefId];
         if (datadef.InputType === enums.InputType.Picture || datadef.InputType === enums.InputType.Attachment) {
            let document = documents.find(doc =>
               (doc.Id > 0 && doc.Id === data.Id) || (doc.Id < 0 && doc.Id === data.OrigId)
            );

            if (!!document) {
               document.Id = data.Id;
               document.Value = data.Value;
               data.content = document.content;

               if (datadef.InputType === enums.InputType.Attachment) {
                  data.fileName = document.fileName;
                  data.fileSize = document.fileSize;
               }

            } else {
               data.content = "";
            }
         }
         let group = groupInfo.groups[data.groupId];
         let datanamevalue = null;
         if (datadef.Name === group.dataName) {
            group.dataNameId = data.Id;
            datanamevalue = await GetGroupDataNameValue(
               datadef,
               data.Value
            );

            group.dataNameValue = datanamevalue;
            data.groupDataNameIds.push(data.groupId);
         }
         const testname = group.name + "." + datadef.Name;
         let parentid = group.parentId;
         while (parentid !== 0) {
            let tgroup = groupInfo.groups[parentid];
            if (tgroup.dataName === testname) {
               if (datanamevalue === null) {
                  datanamevalue = await GetGroupDataNameValue(
                     datadef,
                     data.Value
                  );
               }

               data.groupDataNameIds.push(parentid);
               tgroup.dataNameId = data.Id;
               tgroup.dataNameValue = datanamevalue;
            }
            parentid = tgroup.parentId;
         }

         await db.formData.put(data, data.Id);

         if (!data.Hidden) incrementTotals(group, data.Value, datadef);

         const newdefx = { dataId: data.Id, groupId: data.groupId };
         if (!newForm.dataDefX.hasOwnProperty(data.DefId))
            newForm.dataDefX[data.DefId] = [];
         newForm.dataDefX[data.DefId].push(newdefx);
      } catch (err) {
         logger.Error(
            "formServer:saveDataFromServer",
            "Data Id=" + data.Id,
            err
         );
      }
   }
}

async function cleanupGroupsAndData(formId) {
   const groupkeys = await db.formGroups
      .where("formId")
      .equals(formId)
      .primaryKeys();
   for (let k = 0; k < groupkeys.length; k++) {
      const key = groupkeys[k];
      if (key < 0) await db.formGroups.delete(key);
   }

   const datakeys = await db.formData
      .where("formId")
      .equals(formId)
      .primaryKeys();
   for (let k = 0; k < datakeys.length; k++) {
      const key = datakeys[k];
      if (key < 0) await db.formData.delete(key);
   }
}
