import { instance as axios } from "./apiClient";
import db from "../db/db";
import * as logger from "../helpers/logger";
import * as enums from "../helpers/enums";
import { isNullOrEmpty, normalizeUrl } from "../helpers/utils";
import { GetUserDb, getUserSyncData, UpdateUserDb } from "../db/userHandler";
import { GetDefs } from "./defsApi";
import { SendForm, RetrieveForm } from "./formsApi";
import { GetCancelKeys, RemoveCancelKeys } from "../db/keyHandler";
import { VerifyAndSyncLocalForms, IntegrityCheck } from "../db/formServer";
import { SyncDigitalId } from "./apiUtils";
import { SetReleaseNotes } from "../db/uiHandler";
import { getLastsDataArray } from "../db/lastHandler";

let _config;
let _setSyncMessage;
let _accessToken;
let _failedSaveFormIds;

export async function Sync(accessToken, setSyncMessage) {
   logger.System("syncProcess:Sync", "Sync Attempt");
   _config = { headers: { Authorization: "Bearer " + accessToken } };
   _setSyncMessage = setSyncMessage;
   _accessToken = accessToken;
   _failedSaveFormIds = [];

   let response = {
      success: false,
      getUserResponse: { success: false },
      checkVersionResponse: { success: false },
      syncDefsResponse: { success: false },
      saveFormsResponse: { success: false },
      syncFormsResponse: { success: false, results: null },
      syncReleaseNotesResponse: { success: false },
      syncUserDataResponse: { success: false }
   };

   // Get User
   let success = await getUser();
   response = {
      ...response,
      getUserResponse: { success },
      success
   };

   if (!success) return response;

   // Check Version
   success = await checkVersion();
   response = {
      ...response,
      checkVersionResponse: { success },
      success
   };

   if (!success) return response;

   // Sync Form Definitions
   success = await syncDefs();
   response = {
      ...response,
      syncDefsResponse: { success },
      success
   };

   if (!success) return response;

   // Save Forms
   success = await saveForms();
   response = {
      ...response,
      saveFormsResponse: { success },
      success
   };

   if (!success) return response;

   // Sync Forms
   const syncFormsResponse = await syncForms();
   response = {
      ...response,
      syncFormsResponse,
      success: syncFormsResponse.success
   };

   if (!syncFormsResponse.success) return response;

   // Sync release notes
   success = await syncReleaseNotes();
   response = {
      ...response,
      syncReleaseNotesResponse: { success },
      success
   };

   if (!success) return response;

   // Sync lasts data
   success = await syncUserData();
   response = {
      ...response,
      syncUserDataResponse: { success },
      success
   };

   if (!success) return response;

   logger.System("syncProcess:Sync", "Sync Finished");
   return response;
}

function addMessage(newMessage) {
   _setSyncMessage((oldarray) => [...oldarray, newMessage]);
}

async function getUser() {
   addMessage({
      status: enums.syncStatus.Process,
      message: "Get User Info",
   });
   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/GetUser"
   );

   return axios.get(url, _config).then(
      async function (response) {
         addMessage({
            status: enums.syncStatus.Success,
            message: "User Info Retrieved",
         });
         UpdateUserDb(response.data);
         if (!response.data.DigitalIdOnFile) {
            await syncDigitalId();
         }
         return true;
      },
      function (error) {
         logger.Error("syncProcess:GetUser", "Error getting user", error);
         addMessage({
            status: enums.syncStatus.Failure,
            message: "Error occurred getting user",
         });
         return false;
      }
   );
}

async function syncDigitalId() {
   let user = await GetUserDb();
   if (!user.hasOwnProperty("digitalId") || isNullOrEmpty(user.digitalId))
      return;

   addMessage({
      status: enums.syncStatus.Process,
      message: "Syncing Digital Id",
   });

   SyncDigitalId(_accessToken, user.digitalId).then(function (response) {
      if (response) {
         addMessage({
            status: enums.syncStatus.Success,
            message: "Digital Id synced successfully",
         });
      } else {
         addMessage({
            status: enums.syncStatus.Failure,
            message: "Error occurred syncing Digital Id on the server",
         });
      }
   });
}

async function syncReleaseNotes() {

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/GetReleaseNotes"
   );

   addMessage({
      status: enums.syncStatus.Process,
      message: "Getting Release Notes",
   });

   try {
      const { data } = await axios.get(url, _config);

      if (data && data.Success) {

         addMessage({
            status: enums.syncStatus.Success,
            message: "Retrieved Release Notes",
         });

         SetReleaseNotes(data.Data);
      }
   }
   catch (error) {
      logger.Error(
         "syncProcess:syncReleaseNotes",
         "Release Notes sync failed with error",
         error
      );
   }

   return true;
}

async function syncUserData() {

   addMessage({
      status: enums.syncStatus.Process,
      message: "Syncing User Data",
   });

   const lastsDataArray = await getLastsDataArray();
   const userDataArray = await getUserSyncData();

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/SyncUserData"
   );

   try {

      const sendData = {
         lastData: lastsDataArray,
         userData: userDataArray
      };

      const { data } = await axios.post(url, sendData, _config);

      if (data && data.Success) {

         for (const index in data.Data.LastData) {
            const lastData = data.Data.LastData[index];
            let lastDb = await db.defsLast.get(lastData.FormDefinitionName);
            if (lastDb === undefined) continue;

            lastDb[lastData.WorkOrderId] = JSON.parse(lastData.Data);
            await db.defsLast.put(lastDb);
         }

         for (const index in data.Data.UserData) {
            const item = data.Data.UserData[index];

            let userDb = await GetUserDb();
            if (userDb === undefined) continue;

            switch (item.Key) {
               case "AdditionalEmailRecipients":
                  userDb.Recipients = {
                     recipients: JSON.parse(item.Data),
                     lastUpdated: item.LastUpdated
                  };
                  await UpdateUserDb(userDb);
                  break;
               case "FormListingPreferences":
                  const formListingPref = JSON.parse(item.Data);
                  userDb.FormListingPreferences = {
                     SortByValue: formListingPref.SortByValue,
                     LastUpdated: item.LastUpdated
                  };
                  await UpdateUserDb(userDb);
                  break;
               case "FormNewPreferences":
                  const formNewPref = JSON.parse(item.Data);
                  userDb.FormNewPreferences = {
                     LastZipCodeUsed: formNewPref.LastZipCodeUsed,
                     LastLocationType: formNewPref.LastLocationType,
                     LastUpdated: item.LastUpdated
                  };
                  await UpdateUserDb(userDb);
                  break;
               default:
                  break;
            }
         }
      }

      addMessage({
         status: enums.syncStatus.Success,
         message: "Retrieved User Data",
      });

   }
   catch (error) {
      logger.Error(
         "syncProcess:syncUserData",
         "User Data sync failed with error",
         error
      );
   }

   return true;
}

async function checkVersion() {
   addMessage({
      status: enums.syncStatus.Process,
      message: "Checking Version " + process.env.REACT_APP_ENFORM_VERSION,
   });
   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/VersionCheck?version=" +
      process.env.REACT_APP_ENFORM_VERSION
   );

   return axios.get(url, _config).then(
      function (response) {
         if (response.data) {
            addMessage({
               status: enums.syncStatus.Success,
               message: "Passed version check",
            });
         } else {
            addMessage({
               status: enums.syncStatus.Failure,
               message: "Invalid version - Please restart the app, log in, and sync.",
            });
         }
         return response.data;
      },
      function (error) {
         logger.Error(
            "syncProcess:checkVersion",
            "Version check failed with error",
            error
         );
         addMessage({
            status: enums.syncStatus.Failure,
            message: "Invalid version - Please restart the app, log in, and sync.",
         });
         return true;
      }
   );
}

async function syncDefs() {
   addMessage({
      status: enums.syncStatus.Process,
      message: "Syncing Form Definitions",
   });

   const success = await GetDefs(_config);
   if (success) {
      addMessage({
         status: enums.syncStatus.Success,
         message: "Form Definitions Retrieved",
      });
   } else {
      addMessage({
         status: enums.syncStatus.Failure,
         message: "Error occurred syncing form definitions",
      });
   }
   return success;
}

async function saveForms() {
   const formids = await db.forms.toCollection().primaryKeys();
   if (formids.length === 0) {
      addMessage({
         status: enums.syncStatus.Process,
         message: "No forms to save",
      });
      return true;
   }

   const messageforms =
      "Processing " +
      formids.length +
      (formids.length === 1 ? " form" : " forms");
   addMessage({
      status: enums.syncStatus.Process,
      message: messageforms,
   });

   let counts = {
      total: 0,
      success: 0,
      failure: 0,
      skipped: 0,
   };
   for (let f = 0; f < formids.length; f++) {
      const id = formids[f];
      const result = await SendForm(id, _config);

      counts.total++;
      if (result.status === "skip") {
         counts.skipped++;
         addMessage({
            status: enums.syncStatus.Success,
            message: "Form " + id + " skipped.",
         });
      } else if (result.status === "failure") {
         counts.failure++;
         addMessage({
            status: enums.syncStatus.Failure,
            message: "Error sending form " + id + ". " + result.message,
         });
         _failedSaveFormIds.push(id);
      } else {
         counts.success++;
         addMessage({
            status: enums.syncStatus.Success,
            message: "Successfully sent form " + result.newid,
         });
      }
   }

   if (counts.total > 0) {
      addMessage({
         status:
            counts.failure > 0
               ? enums.syncStatus.Failure
               : enums.syncStatus.Success,
         message:
            "Forms saved. success=" +
            counts.success +
            ", failure=" +
            counts.failure +
            ", skipped=" +
            counts.skipped,
      });
   }

   return true;
}

async function syncForms() {
   addMessage({
      status: enums.syncStatus.Process,
      message: "Syncing forms",
   });

   let cancelKeys;
   try {
      cancelKeys = await GetCancelKeys();
   } catch (err) {
      logger.Error("syncProcess:syncForms", "Error getting Cancel Keys", err);
      cancelKeys = { keys: [] };
   }

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/SyncForms"
   );

   addMessage({
      status: enums.syncStatus.Process,
      message:
         cancelKeys.keys.length > 0
            ? "Sending cancel keys and getting form ids to sync"
            : "Getting form ids to sync",
   });

   return axios.post(url, cancelKeys.keys, _config).then(
      async function (response) {
         const forminfos = response.data;
         if (cancelKeys.keys.length > 0) {
            await RemoveCancelKeys();
            addMessage({
               status: enums.syncStatus.Success,
               message: "Cancel keys removed",
            });
         }

         const getFormsResponse = await getForms(forminfos);

         if (getFormsResponse.success) {
            let syncLocalFormsSuccess;
            let integrityCheckSuccess;

            try {
               syncLocalFormsSuccess = await VerifyAndSyncLocalForms(response.data);
            } catch (err) {
               syncLocalFormsSuccess = false;
               logger.Error(
                  "syncProcess:syncForms",
                  "Verify and Sync Local Forms failed",
                  err
               );
            }
            try {
               integrityCheckSuccess = await IntegrityCheck();
            } catch (err) {
               integrityCheckSuccess = false;
               logger.Error(
                  "syncProcess:syncForms",
                  "Integrity Check failed",
                  err
               );
            }

            return {
               success: syncLocalFormsSuccess && integrityCheckSuccess,
               results: getFormsResponse.results
            }
         }

         return {
            success: false,
            results: getFormsResponse.results
         };
      },
      function (error) {
         logger.Error(
            "syncProcess:syncForms",
            "Error occurred syncing forms",
            error
         );
         addMessage({
            status: enums.syncStatus.Failure,
            message: "Error occurred syncing forms",
         });

         return {
            success: false,
            results: null
         };
      }
   );
}

async function getForms(forminfos) {
   addMessage({
      status: enums.syncStatus.Process,
      message: forminfos.length + " forms to retrieve",
   });
   if (forminfos.length === 0) {
      return {
         results: [],
         success: true
      };
   }

   let counts = {
      success: 0,
      failure: 0,
      skipped: 0,
      docSuccess: 0,
      docFailure: 0,
   };

   let processes = [];
   for (let f = 0; f < forminfos.length; f++) {
      const formid = forminfos[f].Id;
      if (_failedSaveFormIds.indexOf(formid) === -1) {
         processes.push(RetrieveForm(forminfos[f], _config));
      } else {
         counts.skipped++;
      }
   }

   return Promise.all(processes)
      .then((results) => {
         for (let i = 0; i < results.length; i++) {
            const formresult = results[i];
            if (formresult.status === "skip") {
               counts.skipped++;
               continue;
            }
            if (formresult.status === "failure") {
               addMessage({
                  status: enums.syncStatus.Failure,
                  message: formresult.message,
               });
               counts.failure++;
               continue;
            }
            counts.success++;
            if (formresult.result.status === "none") continue;
            if (formresult.result.status === "failure") {
               addMessage({
                  status: enums.syncStatus.Failure,
                  message: formresult.status.message,
               });
               counts.docFailure += formresult.result.count;
               continue;
            }

            for (let j = 0; j < formresult.result.results.length; j++) {
               const docresult = formresult.result.results[j];
               if (docresult === "success") {
                  counts.docSuccess++;
                  continue;
               }
               addMessage({
                  status: enums.syncStatus.Failure,
                  message: docresult.message,
               });
            }
         }

         addMessage({
            status:
               counts.failure > 0
                  ? enums.syncStatus.Failure
                  : enums.syncStatus.Success,
            message:
               "Forms saved. success=" +
               counts.success +
               ", failure=" +
               counts.failure +
               ", skipped=" +
               counts.skipped,
         });

         if (counts.docFailure + counts.docSuccess > 0) {
            addMessage({
               status:
                  counts.docFailure > 0
                     ? enums.syncStatus.Failure
                     : enums.syncStatus.Success,
               message:
                  "Documents saved. success=" +
                  counts.docSuccess +
                  ", failure=" +
                  counts.docFailure,
            });
         }
         addMessage({
            status: enums.syncStatus.Success,
            message: "Forms retrieved",
         });

         return {
            results,
            success: true
         };

      })
      .catch((errors) => {
         addMessage({
            status: enums.syncStatus.Failure,
            message: "Error retrieving forms",
         });
         logger.Error(
            "syncProcess:getForms",
            "Error occurred saving forms",
            errors
         );

         return {
            results: null,
            success: false
         };
      });
}
