import * as types from "../actionTypes";
import initialState from "../initialState";

export default function formReducer(state = initialState.forms, action) {
   switch (action.type) {
      case types.LOAD_FORMS_LIST:
         return { ...state, list: action.newlist, init: false };

      case types.SET_FORMS_INIT:
         return { ...state, init: action.value, list: [], formid: null };

      case types.SET_FORM_ID:
         return {
            ...state,
            formid: action.obj.formid,
            groupId: "root",
            form: action.obj.form,
            init: action.obj.init,
         };

      case types.SET_GROUP_ID:
         return {
            ...state,
            groupId: action.obj.groupId,
            form: { ...state.form, FormGroups: action.obj.formGroups },
         };

      case types.CREATE_NEW_FORM:
         return {
            ...state,
            formid: action.obj.formid,
            form: action.obj.form,
            list: action.obj.list,
         };

      case types.SAVE_EDIT_FORM:
         return { ...state, form: action.form };

      case types.SET_FORM_STATUS:
         return {
            ...state,
            form: { ...state.form, Status: action.obj.status },
            list: action.obj.list,
         };

      case types.DELETE_FORM:
         return {
            ...state,
            form: {},
            formid: null,
            init: true,
         };

      case types.GROUP_DELETE:
      case types.GROUP_ADD:
         return {
            ...state,
            form: action.obj.form,
            groupId: action.obj.groupId,
         };

      case types.GROUP_UPDATE_DATANAME:
         return {
            ...state,
            form: {
               ...state.form,
               FormGroups: {
                  ...state.form.FormGroups,
                  [action.obj.groupId]: {
                     ...state.form.FormGroups[action.obj.groupId],
                     dataNameValue: action.obj.value,
                  },
               },
            },
         };

      case types.GROUP_UPDATE_COUNTS:
         return {
            ...state,
            form: {
               ...state.form,
               FormGroups: {
                  ...state.form.FormGroups,
                  [action.obj.groupId]: {
                     ...state.form.FormGroups[action.obj.groupId],
                     total: action.obj.counts.total,
                     totalEntered: action.obj.counts.totalEntered,
                     required: action.obj.counts.required,
                     requiredEntered: action.obj.counts.requiredEntered,
                  },
               },
            },
         };

      default:
         return state;
   }
}
