import { instance as axios } from "./apiClient";
import * as settings from "../settings";
import { getDateTime, getUnixTimestamp } from "../helpers/dateUtils";
import * as logger from "../helpers/logger";

const baseUrl = "https://api.weatherapi.com/v1/forecast.json";
const baseParams = "?key=" + settings.weatherApiKey;

export async function GetWeatherThen(latLng, formLocation, dateTime) {
   let url = `${baseUrl}${baseParams}`;
   
   if (latLng) {
      url = `${url}&q=${latLng.lat},${latLng.lng}`;
   }
   else if (formLocation) {
      url = `${url}&q=${formLocation}`;
   }

   let weatherobj;
   try {
      weatherobj = getWeather(url, dateTime);
   } catch (error) {
      logger.Error(
         "weatherApi:GetWeatherThen",
         "An error occurred getting weather.",
         error
      );
      weatherobj = {
         success: false,
         message: "An error occurred getting weather. " + error.message,
      };
   }
   return weatherobj;
}

async function getWeather(url, dateTime) {
   logger.Info("weatherApi:getWeather", url);
   let dt = getUnixTimestamp(dateTime);
   url = url + "&unixdt=" + dt;

   return axios.get(url).then(
      function (response) {
         
         if (response.status !== 200) {
            return {
               success: false,
               message: response.message,
            };
         }

         let data = response.data;
         return getCurrentWeather(data, dateTime);
      },
      function (error) {
         logger.Error("weatherApi:getWeather", "Error from url " + url, error);
         return {
            success: false,
            message: "An error occurred. " + JSON.stringify(error),
         };
      }
   );
}

function getCurrentWeather(data, dateTime) {
   let obj = GetEmptyWeatherObject();
   if ((data.forecast.forecastday === null) || (data.forecast.forecastday.length === 0)) {
      return {
         success: false,
         data: obj,
      };
   }

   let weather = data.forecast.forecastday[0].day;
   obj.source = "api";
   if ((data.location !== null) && (data.location.name !== null)) {
      obj.location = `${data.location.name}, ${data.location.region}`
   }
   obj.dateTime = getDateTime(dateTime);
   for (var i = 0; i < data.forecast.forecastday[0].hour.length; i++) {
      let wxhr = data.forecast.forecastday[0].hour[i];
      let hr = Number(wxhr.time.substr(11, 2));
      if ((hr >= 7) && (hr < 18)) {
         if (hr === 12) {
            obj.windDirection = wxhr.wind_dir;
            obj.windSpeedmph = Math.round(wxhr.wind_mph * 10) / 10;
            obj.windSpeedkph = Math.round(wxhr.wind_kph * 10) / 10;
            obj.clouds = wxhr.cloud;
            obj.pressurein = wxhr.pressure_in;
            obj.pressuremb = wxhr.pressure_mb;
         };

         if (wxhr.gust_kph > obj.windGustkph) {
            obj.windGustkph = wxhr.gust_kph;
         };
         if (wxhr.gust_mph > obj.windGustmph) {
            obj.windGustmph = wxhr.gust_mph;
         };
      };
   };
   obj.windGustmph = Math.round(obj.windGustmph * 10) / 10;
   obj.windGustkph = Math.round(obj.windGustkph * 10) / 10;
   obj.condition = weather.condition.text;
   obj.tempHiF= weather.maxtemp_f;
   obj.tempHiC= weather.maxtemp_c;
   obj.tempLoF= weather.mintemp_f;
   obj.tempLoC= weather.mintemp_c;
   obj.tempAvgF= weather.avgtemp_f;
   obj.tempAvgC= weather.avgtemp_c;
   obj.precipin= Math.round(weather.totalprecip_in * 100) / 100;
   obj.precipmm= weather.totalprecip_mm;
   obj.visibilitymi= Math.round(weather.avgvis_miles * 10) / 10;
   obj.visibilitykm= Math.round(weather.avgvis_km * 10) / 10;
   obj.humidity= weather.avghumidity;
   return {
      success: true,
      data: obj,
   };
}

export function GetEmptyWeatherObject() {
   return {
      source: "",
      location: "",
      dateTime: "",
      condition: "",
      tempHiF: "",
      tempHiC: "",
      tempLoF: "",
      tempLoC: "",
      tempAvgF: "",
      tempAvgC: "",
      precipin: "",
      precipmm: "",
      visibilitymi: "",
      visibilitykm: "",
      pressurein: "",
      pressuremb: "",
      humidity: "",
      windSpeedkph: "",
      windSpeedmph: "",
      windDirection: "",
      windGustkph: "",
      windGustmph: "",
      clouds: "",
   };
}
