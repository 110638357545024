import { addSecondsToDate, isNullOrEmpty } from "./utils";

//const rollingCodeKey =
//   "PI9%YR=8>WO&7U-T....$6EQ@5LA4!KP+S......3?JD|2HF1G<Z........0XM1#NC6!BV0........";
const rollingCodeKey =
   "1234567890WXYZSL....1234567890WXYZ......1234567890WX........1234567890WX........";

export function getRollingCode() {
   let date = addSecondsToDate(3600, new Date());

   const min = make2Digit(date.getMinutes());
   let hh = make2Digit(date.getHours());
   const day = make2Digit(date.getDate());
   const mon = make2Digit(date.getMonth() + 1);
   const digits = (
      rev2Digit(min) +
      rev2Digit(day) +
      rev2Digit(hh) +
      rev2Digit(mon)
   ).split("");

   let code = "";
   for (let i = 0; i < 8; i++) {
      const digit = Number(digits[i]);
      const keyindex = i * 10 + digit;
      code += rollingCodeKey[keyindex];
   }
   let ampm = "am";
   if (Number(hh) > 11) ampm = "pm";
   if (Number(hh) > 12) hh = hh - 12;
   return [code, hh + ":" + min + " " + ampm];
}

export function validRollingCode(code) {
   if (isNullOrEmpty(code)) return [false, ""];
   if (code.length !== 8) return [false, ""];

   const codearray = code.split("");
   let digits = "";
   for (let i = 0; i < 8; i++) {
      const partial = rollingCodeKey.substr(i * 10, 10);
      const index = partial.indexOf(codearray[i]);
      if (index === -1) return [false, ""];
      digits += index;
   }
   const min = digits.charAt(1) + digits.charAt(0);
   const day = digits.charAt(3) + digits.charAt(2);
   let hh = digits.charAt(5) + digits.charAt(4);
   const mon = digits.charAt(7) + digits.charAt(6);

   const now = new Date();
   if (now.getDate() !== Number(day) || now.getMonth() + 1 !== Number(mon))
      return [false, ""];

   const nowtime = Number(
      make2Digit(now.getHours()) + make2Digit(now.getMinutes())
   );
   const checktime = Number(hh + min);

   if (nowtime > checktime) return [false, ""];

   let ampm = "am";
   if (Number(hh) > 11) ampm = "pm";
   if (Number(hh) > 12) hh = hh - 12;

   return [true, hh + ":" + min + " " + ampm];
}

function rev2Digit(value) {
   const nums = value.split("");
   return nums[1] + nums[0];
}

function make2Digit(value) {
   if (typeof value === "number") value = value.toString();
   if (value.length === 1) return "0" + value;
   return value;
}
