import db from "./db";
import * as enums from "../helpers/enums";
import { isNullOrEmpty, fixDateTimeLabelFormat } from "../helpers/utils";
import * as logger from "../helpers/logger";

export async function SaveValidations(vals) {
   logger.Info("valHandler:SaveValidations", "Save Validations start");
   for (let v = 0; v < vals.length; v++) {
      let val = vals[v];
      let valdb = {};

      valdb.id = val.Name;
      //valdb.properties = val.Properties;
      valdb.records = {};
      valdb.keys = [];

      for (let r = 0; r < val.Records.length; r++) {
         let rec = val.Records[r];
         let recdb = JSON.parse(rec.Value);
         recdb.id = rec.Key;
         recdb.label = rec.Label;
         valdb.records[rec.Key] = recdb;
         valdb.keys.push(rec.Key);
      }

      await db.vals.put(valdb, val.Name);
   }
}

export async function LoadAllValidations() {
   let obj = {};
   await db.vals.toCollection().each(function (valdb) {
      obj[valdb.id] = valdb;
   });

   return obj;
}

export async function GetValidation(valType, valList) {
   let returnlist = {};
   if (valType === enums.ValType.KeyValuePairs) {
      returnlist.keys = [];
      returnlist.records = {};
      const options = valList.split(",");
      for (let o = 0; o < options.length; o++) {
         const [optionkey, optionvalue] = options[o].split("|");
         returnlist.keys.push(optionkey);
         returnlist.records[optionkey] = { id: optionkey, label: optionvalue };
      }
   } else {
      returnlist = await db.vals.get(valList);
   }
   return returnlist;
}

export async function GetValidationRecords(valList) {
   const val = await db.vals.get(valList);
   const keys = Object.keys(val.records);
   let list = [];
   for (let k = 0; k < keys.length; k++) {
      list.push(val.records[keys[k]]);
   }
   return list;
}

export async function GetGroupDataNameValue(datadef, key) {
   let label = key;
   if((datadef.DataType === enums.DataType.DateTime) && (isNullOrEmpty(datadef.ValList))) {
      return await fixDateTimeLabelFormat(label, false, false);
   };
   if (isNullOrEmpty(datadef.ValList) || isNullOrEmpty(key)) return label;
   try {
      const list = await GetValidation(datadef.ValType, datadef.ValList);
      if (list.records.hasOwnProperty(key)) {
         label = list.records[key].label;
      } else {
         const id = await FindValidationIdFromValue(datadef.ValType, datadef.ValList, key);
         if (list.records.hasOwnProperty(id)) {
            label = list.records[id].label;
         } else {
            logger.Error(
               "valHandler:GetGroupDataNameValue",
               "Value List=" + datadef.ValList + ", Key=" + key,
               "Label not found"
            );
         }
      }
   } catch (err) {
      logger.Error(
         "valHandler:GetGroupDataNameValue",
         "Value List=" + datadef.ValList,
         err
      );
      return "";
   }
   return label;
}

export async function FindValidationIdFromValue(valType, valList, value) {
   if (isNullOrEmpty(valList) || isNullOrEmpty(value)) return value;
   try {
      const list = await GetValidation(valType, valList);
      if (isNullOrEmpty(list)) return value;

      const key = list.keys.find((item) => list.records[item].label === value);
      if (key === undefined) return value;
      const valObject = list.records[key];
      if (valObject === undefined) return value;
      return valObject.id;
   } catch (err) {
      logger.Error(
         "valHandler:FindValidationIdFromValue",
         "Value List=" +
            valList +
            ", Value=" +
            (isNullOrEmpty(value) ? "null/empty" : value),
         err
      );
   }
   return value;
}
