import React from "react";
import {
   AppBar,
   AppBarSection,
   AppBarSpacer,
   BottomNavigation,
} from "@progress/kendo-react-layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "../redux/actions/uiActions";
import "./NavigationContainer.css";

const items = [
   {
      text: "Home",
      id: 1,
      icon: "user",
      route: "home",
      selected: true,
   },
   {
      text: "Forms",
      id: 2,
      icon: "rows",
      route: "forms",
      selected: false,
   },
   {
      text: "Sync",
      id: 3,
      icon: "reset",
      route: "sync",
      selected: false,
   },
   {
      text: "Utility",
      id: 3,
      icon: "gears",
      route: "setup",
      selected: false,
   },
];

function NavigationContainer({ route, online, actions, children, sizeFactor }) {
   const handleRoute = (event) => {
      if (route !== event.itemTarget.route) {
         actions.setRoute(event.itemTarget.route);
      }
   };

   const setSelectedItem = (newRoute) => {
      let selecteditem = items.find((item) => item.route === newRoute);

      if (selecteditem) {
         return selecteditem.text;
      }
   };

   const connectorClass = online
      ? "k-icon k-i-connector navigation-connector-online"
      : "k-icon k-i-connector navigation-connector-offline";

   const selected = setSelectedItem(route);

   return (
      <div className="navigation-wrapper">
         <div className="navigation-page">
            <AppBar positionMode="fixed" className="navigation-header">
               <AppBarSection>
                  <h1 className="appbar-title">EnForms</h1>
               </AppBarSection>
               <AppBarSpacer />
               <AppBarSection>
                  <span className={connectorClass}></span>
               </AppBarSection>
            </AppBar>
            <div className="navigation-content">{children}</div>
            <BottomNavigation
               className="navigation-footer"
               style={{ zIndex: 10000, fontSize: 1 * sizeFactor + "rem" }}
               positionMode="fixed"
               items={items.map((item) => ({
                  ...item,
                  selected: item.text === selected,
               }))}
               onSelect={handleRoute}
            />
         </div>
      </div>
   );
}

function mapStateToProps(state) {
   return {
      route: state.ui.route,
      online: state.ui.online,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setRoute: bindActionCreators(uiActions.setRoute, dispatch),
      },
   };
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(NavigationContainer);
