const GroupType = {
   1: "Group",
   2: "Data",
   Group: 1,
   Data: 2,
};

const DataType = {
   1: "String",
   2: "Integer",
   3: "Double",
   4: "DateTime",
   5: "Boolean",
   6: "Image",
   String: 1,
   Integer: 2,
   Double: 3,
   DateTime: 4,
   Boolean: 5,
   Image: 6,
};

const ValType = {
   0: "None",
   1: "KeyValuePairs",
   2: "DefinedLists",
   None: 0,
   KeyValuePairs: 1,
   DefinedLists: 2,
};

const InputType = {
   1: "Text",
   2: "CheckBox",
   3: "Radio",
   4: "Select",
   5: "TextArea",
   6: "Date",
   7: "Number",
   8: "Time",
   9: "Calculated",
   10: "Station",
   14: "Chainage",
   11: "Picture",
   12: "GeoLocation",
   13: "Weather",
   15: "Attachment",
   Text: 1,
   CheckBox: 2,
   Radio: 3,
   Select: 4,
   TextArea: 5,
   Date: 6,
   Number: 7,
   Time: 8,
   Calculated: 9,
   Station: 10,
   Chainage: 14,
   Picture: 11,
   GeoLocation: 12,
   Weather: 13,
   Attachment: 15
};

const Status = {
   0: "New",
   1: "Submitted",
   2: "Approved",
   3: "ChangeRequest",
   4: "PendingSubmit",
   5: "Canceled",
   99: "PendingCancel",
   New: 0,
   Submitted: 1,
   Approved: 2,
   ChangeRequest: 3,
   PendingSubmit: 4,
   Canceled: 5,
   PendingCancel: 99,
};

const WorkflowStatus = {
   0: "PendingSubmit",
   1: "PendingApproval",
   2: "Approved",
   3: "ChangeRequest",
   4: "Skipped",
   5: "Canceled",
   PendingSubmit: 0,
   PendingApproval: 1,
   Approved: 2,
   ChangeRequest: 3,
   Skipped: 4,
   Canceled: 5,
};

const State = {
   0: "None",
   1: "New",
   2: "Update",
   3: "Delete",
   None: 0,
   New: 1,
   Update: 2,
   Delete: 3,
};

const Role = {
   0: "User",
   1: "Approver",
   User: 0,
   Approver: 1,
};

const DefaultType = {
   Literal: 1,
   Action: 2,
   GroupData: 3,
   Reference: 4,
   Operator: 5,
};

const ReferenceType = {
   User: 1,
   Form: 2,
   DefinedList: 3,
};

const ActionType = {
   Date: 1,
   Time: 2,
   Last: 3,
   Unknown: 99,
};

const Operators = {
   arithmetic: ["+", "-", "*", "/", "%", "&"],
   comparison: ["==", "!=", ">", "<", ">=", "<="],
   logical: ["&&", "||"],
};

const jType = {
   Member: "MemberExpression",
   Call: "CallExpression",
   Identifier: "Identifier",
   Binary: "BinaryExpression",
   Literal: "Literal",
};

const syncStatus = {
   Process: 0,
   Success: 1,
   Warning: 2,
   Failure: 3,
   0: "Process",
   1: "Success",
   2: "Warning",
   3: "Failure",
};

export {
   GroupType,
   DataType,
   ValType,
   InputType,
   Status,
   WorkflowStatus,
   State,
   Role,
   DefaultType,
   ReferenceType,
   ActionType,
   Operators,
   jType,
   syncStatus,
};
