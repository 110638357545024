export async function GetGeoLocation() {
   const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
   };

   return new Promise(function (eResolve, eReject) {
      navigator.geolocation.getCurrentPosition(
         function (position) {
            eResolve(getPosition(position));
         },
         function (error) {
            eReject(error.message);
         },
         options
      );
   });
}

const getPosition = (position) => {
   const coord = position.coords;
   let tvalue = "lng:" + coord.longitude + ",lat:" + coord.latitude;
   tvalue += ",alt:" + (coord.altitude === null ? "" : coord.altitude);
   return tvalue;
};
