import { useEffect, useState } from "react";
import {
   ListView,
   ListViewHeader,
   ListViewFooter,
} from "@progress/kendo-react-listview";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { BiSync } from "react-icons/bi";
import "./FormsListing.css";
import { formatDate, getDateObject } from "../helpers/dateUtils";
import * as enums from "../helpers/enums";
import FormInfo from "./FormInfo";
import FormEdit from "./FormEdit";

function FormsListing({
   forms,
   setFormId,
   setFormStatus,
   online,
   setFormPage,
   sizeFactor,
   setSubmitType,
   includeSubmitted,
   setIncludeSubmitted,
   saveEditForm,
   username,
   setFormListSortBy,
   formListingPreferences,
}) {
   const [editId, setEditId] = useState(0);
   const [sortBy, setSortBy] = useState("Date");

   useEffect(() => {
      setSortBy(formListingPreferences.SortByValue);
   }, [formListingPreferences]);

   const formsListingHeader = () => {
      return (
         <ListViewHeader>
            <div
               style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  borderTop: "solid 2px lightgrey",
                  borderBottom: "solid 2px lightgrey",
                  marginTop: 5,
                  marginBottom: 5,
                  paddingBottom: 3,
                  backgroundColor: "#f5f5f5",
               }}
            >
               <div
                  style={{
                     color: "black",
                     fontWeight: "bold",
                     fontSize: 1.15 * sizeFactor + "rem",
                     padding: "5px",
                  }}
               >
                  Forms
               </div>
               <div
                  style={{
                     display: "grid",
                     gridAutoFlow: "column",
                     alignContent: "center",
                     alignItems: "center",
                     justifyContent: "center",
                     gap: 20,
                     paddingRight: 10,
                  }}
               >
                  <span style={{ fontSize: 0.95 * sizeFactor + "rem", paddingLeft: 5 }}>
                     Sort By:{" "}
                  </span>
                  <div>
                     <RadioButton
                        id="sortbyDate"
                        onChange={() => setFormListSortBy("Date")}
                        name="sort-order"
                        checked={sortBy === "Date"}
                     >
                        <label
                           htmlFor="sortbyDate"
                           className={"k-radio-label"}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              paddingLeft: 5,
                           }}
                        >
                           Most Recent
                        </label>
                     </RadioButton>
                  </div>
                  <div>
                     <RadioButton
                        id="sortbyName"
                        onChange={() => setFormListSortBy("Name")}
                        name="sort-order"
                        checked={sortBy === "Name"}
                     >
                        <label
                           htmlFor="sortbyName"
                           className={"k-radio-label"}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              paddingLeft: 5,
                           }}
                        >
                           Name
                        </label>
                     </RadioButton>
                  </div>
               </div>
            </div>
         </ListViewHeader>
      );
   };

   const formsListingFooter = () => {
      return (
         <ListViewFooter
            style={{
               color: "lightgrey",
               fontSize: 0.95 * sizeFactor + "rem",
               paddingLeft: "5px",
            }}
         >
            {forms.list.length} {forms.list.length === 1 ? "Form" : "Forms"}
         </ListViewFooter>
      );
   };

   const formsListingItem = (props) => {
      let itemForm = props.dataItem;

      if (
         !itemForm.assigned &&
         !includeSubmitted &&
         itemForm.Status === enums.Status[enums.Status.Submitted]
      )
         return null;

      const isChangeRequest = itemForm.Status === enums.Status[enums.Status.ChangeRequest];

      const handleSetFormId = (itemFormId) => {
         if (forms.formid === null) {
            setFormId(itemFormId);
         } else {
            if (itemFormId === forms.formid) {
               setFormId(null);
            } else {
               setFormId(itemFormId);
            }
         }
      };

      const handleSetEditId = (id) => {
         setEditId(id);
      };

      return (
         <>
            <div
               className="item-container"
               onClick={() => handleSetFormId(itemForm.Id)}
               style={{
                  backgroundColor: itemForm.assigned
                     ? "#f0ffff"
                     : isChangeRequest
                        ? "#ffffb2"
                        : "",
                  fontSize: 1 + "rem",
               }}
            >
               <div>
                  <div
                     className="item-name"
                     style={{ fontSize: 1 * sizeFactor + "rem" }}
                  >
                     {itemForm.DefName + " " + itemForm.DefLabel}
                     {itemForm.State !== enums.State.None ? (
                        <BiSync color="red" />
                     ) : null}
                  </div>
                  {!!itemForm.Title && 
                  <div 
                     className="item-title"
                     style={{ fontSize: 1 * sizeFactor + "rem" }}>
                        {itemForm.Title}
                  </div>}
                  <div style={{ fontSize: 1 * sizeFactor + "rem" }}>
                     {formatDate(itemForm.ReportDate)}&nbsp;&nbsp;
                     {itemForm.WorkOrderName}
                  </div>
               </div>
               <div
                  className="item-status"
                  style={{ fontSize: 1 * sizeFactor + "rem" }}
               >
                  {itemForm.Status}
               </div>
            </div>
            {itemForm.Id === forms.formid && editId !== itemForm.Id ? (
               <FormInfo
                  form={forms.form}
                  online={online}
                  setFormStatus={setFormStatus}
                  setFormId={handleSetFormId}
                  setFormPage={setFormPage}
                  workOrderName={itemForm.WorkOrderName}
                  sizeFactor={sizeFactor}
                  setSubmitType={setSubmitType}
                  setEditId={handleSetEditId}
                  username={username}
               />
            ) : itemForm.Id === forms.formid && editId === itemForm.Id ? (
               <FormEdit
                  form={forms.form}
                  sizeFactor={sizeFactor}
                  setEditId={setEditId}
                  saveEditForm={saveEditForm}
               />
            ) : null}
         </>
      );
   };

   let sortedForms = [...forms.list];

   if (sortBy === "Name") {
      sortedForms = sortedForms.sort((a, b) =>
         a.DefName.localeCompare(b.DefName)
      );
   } else if (sortBy === "Date") {
      sortedForms = sortedForms.sort((a, b) => {
         const aDate = getDateObject(a.CreateDateTime);
         const bDate = getDateObject(b.CreateDateTime);

         return -1 * (aDate - bDate);
      });
   }

   return (
      <div>
         <div
            style={{
               padding: "3px",
               display: "flex",
               flexWrap: "wrap",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <Checkbox
               id="includeSubmitted"
               style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               value={includeSubmitted}
               onChange={() => setIncludeSubmitted()}
            >
               <label
                  htmlFor="includeSubmitted"
                  className={"k-radio-label"}
                  style={{ fontSize: 0.95 * sizeFactor + "rem", paddingLeft: 5 }}
               >
                  Show Submitted
               </label>
            </Checkbox>
            <div>
               <Button
                  style={{
                     fontWeight: "bold",
                     fontSize: 1 * sizeFactor + "rem",
                     backgroundColor: "gold",
                  }}
                  onClick={() => {
                     setFormId(null);
                     setFormPage("form");
                  }}
               >
                  Create New Form
               </Button>
            </div>
         </div>
         <ListView
            data={sortedForms}
            style={{ width: "100%" }}
            item={formsListingItem}
            header={formsListingHeader}
            footer={formsListingFooter}
         />
      </div>
   );
}

export default FormsListing;
