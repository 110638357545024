import { Button } from '@progress/kendo-react-buttons';
import { useState } from 'react';
import { RecipientCard } from './RecipientCard';
import { RecipientEditor } from './RecipientEditor';
import { v4 as uuidv4 } from 'uuid';
import styles from './RecipientSetup.module.css';
import { IRecipient } from '../../types/IRecipient';

const defaultRecipient: IRecipient =
{
  id: '',
  name: '',
  title: '',
  email: ''
};

interface IProps {
  recipients: IRecipient[];
  onAddUpdate: (recipient: IRecipient) => void;
  onRemove: (recipient: IRecipient) => void;
}

const RecipientSetup = ({ recipients, onAddUpdate, onRemove }: IProps) => {

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IRecipient>({ ...defaultRecipient });

  const onChangeRecipientField = (field: string, value: string) => {
    let newRecipient: IRecipient = { ...editItem, [field]: value };
    setEditItem(newRecipient);
  };

  const onAddClick = () => {
    setEditItem({ ...defaultRecipient, id: uuidv4() });
    setIsEditing(true);
  };

  const onCancelHandler = () => {
    setIsEditing(false);
  };

  const onEditHandler = (recipient: IRecipient) => {
    setEditItem({ ...recipient });
    setIsEditing(true);
  };

  const onRemoveHandler = (recipient: IRecipient) => {
    onRemove(recipient);
  };

  const onSaveHandler = () => {
    onAddUpdate(editItem);
    setIsEditing(false);
  };

  const sorted = recipients.sort((a, b) => {

    const aName = `${a.name}${a.title}`.toLowerCase();
    const bName = `${b.name}${b.title}`.toLowerCase();

    return aName.localeCompare(bName);

  });

  const getComponent = (recipient: IRecipient) => {

    if (isEditing && editItem.id === recipient.id) {
      return (
        <RecipientEditor key={recipient.id}
          recipient={editItem!}
          onChange={(e) => onChangeRecipientField(e.target.name || '', e.target.value!.toString())}
          onSave={onSaveHandler}
          onCancel={onCancelHandler}
        />);
    }
    else {
      return <RecipientCard key={recipient.id} recipient={recipient} onEdit={onEditHandler} onRemove={onRemoveHandler} />;
    }
  }

  const existingEditedItem = !!sorted.find(x => x.id === editItem.id);

  return (
    <div className={styles.recipientList}>
      {sorted.map(getComponent)}

      {isEditing && !existingEditedItem && <RecipientEditor
        recipient={editItem!}
        onChange={(e) => onChangeRecipientField(e.target.name || '', e.target.value!.toString())}
        onSave={onSaveHandler}
        onCancel={onCancelHandler}
      />}

      {isEditing === false &&
        <div className={styles.textRight}>
          <Button fillMode='outline' icon="plus" onClick={onAddClick}>Add Recipient</Button>
        </div>
      }
    </div>
  );
}

export { RecipientSetup };