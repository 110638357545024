import { useEffect, useState } from "react";
import DataLabel from "./DataLabel";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TiWeatherCloudy, TiWeatherSunny } from "react-icons/ti";
import { Loader } from "@progress/kendo-react-indicators";
import { GetEmptyWeatherObject, GetWeatherThen } from "../../api/weatherApi";
import { isNullOrEmpty, getDateTime, getLocationObject, getDateObject, isCanadianPostalCode, isUSZipCode } from "../../helpers/utils";
import DataWeatherManual from "./DataWeatherManual";

function DataWeather({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   addRefElement,
   sizeFactor,
   formIsLocked,
   online,
   location,
   reportdate,
}) {
   const [isLoading, setIsLoading] = useState(false);
   const [showDetail, setShowDetail] = useState(false);
   const [weatherDisplayValue, setWeatherDisplayValue] = useState(null);
   const [visibleDialog, setVisibleDialog] = useState(false);
   const [weatherData, setWeatherData] = useState(null);

   useEffect(() => {
      if (isNullOrEmpty(dataValue)) {
         setWeatherDisplayValue(null);
         setWeatherData(null);
      } else {
         const obj = JSON.parse(dataValue);
         setWeatherData(obj);
         if (obj.source === "api" || obj.source === "manual") {
            setWeatherDisplayValue(obj.condition);
         } else if (obj.source === "later") {
            setWeatherDisplayValue("Weather Data will be retrieved on Sync");
         }
      }
   }, [dataValue]);

   let coordinates = getLocationObject(location);
   let canGetWeather = coordinates !== null || isCanadianPostalCode(location) || isUSZipCode(location);

   const onConfirmGetWeather = async () => {
      setVisibleDialog(false);
      if (online) {
         setIsLoading(true);
         const weatherDate = getDateObject(getDateTime());
         const response = await GetWeatherThen(coordinates, location, weatherDate);
         handleOnChange(JSON.stringify(response.data));
         setIsLoading(false);
      } else {
         let data = GetEmptyWeatherObject();
         data.source = "later";
         data.dateTime = getDateTime();
         handleOnChange(JSON.stringify(data));
      }
   };

   const onClickGetWeather = () => {
      if (weatherData !== null) {
         if (!isNullOrEmpty(weatherData.dateTime)) {
            setVisibleDialog(true);
            return;
         }
      }
      onConfirmGetWeather();
   };

   const GetConfirmationDialog = () => {
      return (
         <Dialog title={"Please confirm"} onClose={() => setVisibleDialog(false)}>
            <p
            style={{
               margin: "25px",
               textAlign: "center",
            }}
            >
               Are you sure you want to override the current weather data?
            </p>
            <DialogActionsBar>
               <Button
                  fillMode='solid'
                  themeColor='secondary'
                  onClick={() => setVisibleDialog(false)}
               >
                  No
               </Button>
               <Button
                  fillMode='solid'
                  themeColor='secondary'
                  onClick={onConfirmGetWeather}
               >
                  Yes
               </Button>
            </DialogActionsBar>
         </Dialog>
      );
   };

   const GetDataWeatherManual = () => {
      return (
         <DataWeatherManual
            dataValue={
               isNullOrEmpty(dataValue)
                  ? GetEmptyWeatherObject()
                  : JSON.parse(dataValue)
            }
            handleOnChange={handleOnChange}
            sizeFactor={sizeFactor}
            formIsLocked={formIsLocked}
            setShowDetail={setShowDetail}
         />
      );
   };

   const GetLoader = () => {
      return (
         <div
            style={{
               textAlign: "center",
               marginTop: "-50px",
               height: "50px", }}
         >
            <Loader type="infinite-spinner" />
         </div>
      );
   };

   return (
      <div>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <input
            type="text"
            defaultValue={weatherDisplayValue}
            style={{
               width: "100%",
               fontSize: 1 * sizeFactor + "rem",
               borderRadius: "2px",
               padding: "5px",
               height: "calc(1.4285714286em + 10px)",
               borderColor: "rgba(0, 0, 0, 0.08)",
            }}
            disabled={true}
         />
         <div
            style={{
               display: "flex",
               justifyContent: "space-between",
               marginTop: "3px",
            }}
         >
            <div>
               {canGetWeather ? (
                  <Button
                     fillMode="outline"
                     disabled={formIsLocked}
                     onClick={onClickGetWeather}
                  >
                     {online ? (
                        <TiWeatherSunny style={{ color: "orange" }} />
                     ) : (
                        <TiWeatherCloudy style={{ color: "darkgray" }} />
                     )}
                     Get Weather
                  </Button>
               ) : (
                  <span>&nbsp;</span>
               )}
            </div>
            <div>
               <Button fillMode="outline" onClick={() => setShowDetail(true)}>
                  Detail
               </Button>
            </div>
         </div>
         
         {isLoading && <GetLoader />}
         
         {showDetail && <GetDataWeatherManual />}

         {visibleDialog && <GetConfirmationDialog />}
      </div>
   );
}

export default DataWeather;
