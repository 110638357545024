import db from "../db/db";

export async function GetForms() {
   let obj = { forms: [], formGroups: [], formData: [] };
   await db.forms.toCollection().each(function (form, cursor) {
      obj.forms.push(form);
   });
   await db.formGroups.toCollection().each(function (group, cursor) {
      obj.formGroups.push(group);
   });
   await db.formData.toCollection().each(function (data, cursor) {
      obj.formData.push(data);
   });

   return obj;
}

export async function DeleteALLForms() {
   await db.formData.toCollection().delete();
   await db.formGroups.toCollection().delete();
   const numforms = await db.forms.toCollection().delete();
   return numforms;
}

export async function DeleteAllDefinitions() {
   await db.defsLast.toCollection().delete();
   await db.vals.toCollection().delete();
   const numdefs = await db.defs.toCollection().delete();
   return numdefs;
}

export async function GetVals() {
   let obj = {};
   await db.vals.toCollection().each(function (val, cursor) {
      obj[val.id] = val;
   });
   return obj;
}

export async function GetDefsLastAsObject() {
   let obj = {};
   await db.defsLast.toCollection().each(function (val, cursor) {
      obj[val.id] = val;
   });
   return obj;
}
