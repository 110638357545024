import React, { useRef, useEffect } from "react";
import DataLabel from "./DataLabel";
import { isNullOrEmpty, formatValue } from "../../helpers/utils";

function DataReadOnly({
   dataId,
   editorId,
   dataDef,
   dataValue,
   addRefElement,
   sizeFactor,
}) {
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);

   return (
      <div>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <input
            ref={ref}
            id={editorId}
            value={formatValue(dataDef, dataValue)}
            style={{
               width: "100%",
               fontSize: 1 * sizeFactor + "rem",
               borderRadius: "2px",
               padding: "5px",
               height: "calc(1.4285714286em + 10px)",
               borderColor: "rgba(0, 0, 0, 0.08)",
            }}
            disabled={true}
         />
      </div>
   );
}

export default DataReadOnly;
