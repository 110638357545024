import React from "react";
import {
   ListView,
   ListViewHeader,
   ListViewFooter,
} from "@progress/kendo-react-listview";
import * as enums from "../helpers/enums";

function SyncMessages({ progressMessages, sizeFactor }) {
   const listHeader = () => {
      return (
         <ListViewHeader
            style={{
               color: "black",
               fontWeight: "bold",
               fontSize: 1.15 * sizeFactor + "rem",
               paddingLeft: "5px",
               borderBottom: "1px solid lightgrey",
               marginTop: "5px",
            }}
         >
            Sync Messages
         </ListViewHeader>
      );
   };

   const listFooter = () => {
      return (
         <ListViewFooter
            style={{
               color: "lightgrey",
               fontSize: 0.95 * sizeFactor + "rem",
               paddingLeft: "5px",
            }}
         >
            {progressMessages.length}{" "}
            {progressMessages.length === 1 ? "Message" : "Messages"}
         </ListViewFooter>
      );
   };

   const listItem = (props) => {
      let item = props.dataItem;
      return (
         <div
            style={{
               fontSize: 1 * sizeFactor + "rem",
               color:
                  item.status === enums.syncStatus.Success
                     ? "green"
                     : item.status === enums.syncStatus.Failure
                     ? "red"
                     : item.status === enums.syncStatus.Warning
                     ? "yellow"
                     : "black",
               paddingBottom: "3px",
               paddingLeft:
                  item.status === enums.syncStatus.Success
                     ? "10px"
                     : item.status === enums.syncStatus.Failure
                     ? "10px"
                     : item.status === enums.syncStatus.Warning
                     ? "3px"
                     : "0px",
            }}
         >
            {item.message}
         </div>
      );
   };

   return (
      <ListView
         data={progressMessages}
         style={{ width: "100%" }}
         item={listItem}
         header={listHeader}
         footer={listFooter}
      />
   );
}

export default SyncMessages;
