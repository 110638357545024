import { differenceInCalendarDays, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { AppOkDialog } from './AppOkDialog';

const SYNC_REMINDER_DAYS = parseInt(process.env.REACT_APP_SYNC_REMINDER_DAYS || "1");

interface IProps {
  lastSync: string;
  online: boolean;
}

const SyncReminder = ({ lastSync, online }: IProps) => {

  const [days, setDays] = useState<number>(0)
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {

    if (online === false) return;

    setTimeout(() => {

      const daysSinceLastSync = differenceInCalendarDays(new Date(), lastSync ? parseISO(lastSync) : new Date());

      const showReminder = daysSinceLastSync >= SYNC_REMINDER_DAYS;

      setDays(daysSinceLastSync);
      setShow(showReminder);

    }, 2000);

  }, [lastSync, setShow, online])

  const onClose = () => {
    setShow(false);
  }

  const onOk = () => {
    setShow(false);
  }

  return (
    <>
      {show &&
        <AppOkDialog title="Sync Reminder"
          body={
            <>
              <span style={{ fontWeight: 'bold' }}>{`It has been ${days} days since you last synced.`}</span>
              <br />
              <br />
              <span>Please sync as soon as possible</span>
            </>
          }
          onOk={onOk}
          onClose={onClose} />}
    </>)
}

export { SyncReminder };