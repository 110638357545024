import { combineReducers } from "redux";
import ui from "./uiReducer";
import user from "./userReducer";
import defs from "./defReducer";
import forms from "./formReducer";
import vals from "./valReducer";

const rootReducer = combineReducers({
   ui,
   user,
   defs,
   forms,
   vals,
});

export default rootReducer;
