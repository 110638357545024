import db from "./db";
import { isNullOrEmpty } from "../helpers/utils";
import { incrementTotals } from "../helpers/groupUtils";
import * as enums from "../helpers/enums";
import { GetForm } from "./formHandler";
import { SetDefaults, GetGroupAutoList } from "../helpers/resolver";
import { CreateData, GetDataFromIds } from "./dataHandler";
import { parse } from "../helpers/parser";
import { EvaluateGroupHidden } from "../helpers/resolver";
import { GetNextGroupId, GetNextDataId, SaveKeys } from "./keyHandler";
import { GetGroupDataNameValue } from "./valHandler";
import { GetGroupAutoListLast, saveGroupAutoListData } from "./lastHandler";

export async function CreateGroups(
   groupDefIds,
   form,
   def,
   parentGroupId,
   ggDataNames,
   ignoreLAST
) {
   let groupids = [];
   let order = 0;
   if (isNullOrEmpty(ggDataNames)) ggDataNames = [];
   for (let g = 0; g < groupDefIds.length; g++) {
      const gdef = def.GroupDefs[groupDefIds[g]];
      let autolist = [];
      if (isNullOrEmpty(gdef.AutoList) || ignoreLAST) {
         autolist.push({
            label: gdef.Label,
            blabel: gdef.BLabel,
            dataNameValue: null,
         });
      } else {
         autolist = await resolveAutoList(gdef, form, def.Name);
      }
      let gsequence = 0;
      const gsequencecount = gdef.Repeats ? autolist.length : 0;
      for (let a = 0; a < autolist.length; a++) {
         if (gdef.Repeats) {
            gsequence++;
         } else {
            gsequence = 1;
         }
         order++;
         let ngroup = await getNewGroupObject(gdef.Id, form.Id, parentGroupId);
         ngroup.Label = autolist[a].label;
         ngroup.BLabel = autolist[a].blabel;
         ngroup.Order = order;
         ngroup.Sequence = gsequence;
         ngroup.dataNameValue =
            autolist[a].dataNameValue === null ? "" : autolist[a].dataNameValue;
         ngroup.sequenceCount = gsequencecount;
         groupids.push(ngroup.Id);
         if (gdef.Type === enums.GroupType.Group) {
            const ggdatanameidx = ggDataNames.length;
            if (!isNullOrEmpty(gdef.DataName)) {
               ggDataNames.push({
                  id: ngroup.Id,
                  dataname: gdef.DataName,
                  dataid: 0,
                  value: "",
               });
            }
            ngroup.Groups = await CreateGroups(
               gdef.Groups,
               form,
               def,
               ngroup.Id,
               ggDataNames,
               ignoreLAST
            );
            if (!isNullOrEmpty(gdef.DataName)) {
               let ggdataname = ggDataNames[ggdatanameidx];
               ngroup.dataNameId = ggdataname.dataid;
               ngroup.dataNameValue = ggdataname.value;
            }
         } else if (gdef.Type === enums.GroupType.Data) {
            [ngroup.Data, ngroup.dataNameId, ngroup.dataNameValue] =
               await CreateData(
                  gdef.Data,
                  gdef.DataName,
                  form,
                  def,
                  ngroup.Id,
                  gdef.Name,
                  ggDataNames,
                  ngroup.dataNameValue,
                  autolist[a].dataPoints
               );
         }
         db.formGroups.put(ngroup);
      }
   }
   await SaveKeys();
   return groupids;
}

async function getNewGroupObject(defId, formId, parentGroupId) {
   const ngroupid = await GetNextGroupId();
   let ngroup = {
      Id: ngroupid,
      DefId: defId,
      State: enums.State.New,
      Highlight: false,
      Hidden: false,
      Comment: "",
      Label: "",
      BLabel: "",
      Order: 0,
      Sequence: 0,
      Groups: [],
      Data: [],
      formId: formId,
      groupId: parentGroupId,
      dataNameId: 0,
      dataNameValue: "",
      total: 0,
      required: 0,
      totalEntered: 0,
      requiredEntered: 0,
      sequenceCount: 0,
   };
   return ngroup;
}

export async function GroupAdd(groupId, formId, isCopy) {
   let form = await db.forms.get(formId);
   let def = await db.defs.get(form.DefId);
   let groupdb = await db.formGroups.get(groupId);

   if (def.GroupDefs[groupdb.DefId].Type === enums.GroupType.Group && isCopy) {
      const newgroupid = await copyGroupOfGroups(form, def, groupdb, true);

      form = await GetForm(groupdb.formId);
      form.groupX = await MapGroups(form);
      return {
         form: form,
         groupId: newgroupid,
      };
   }

   return await addGroup(form, def, groupdb, isCopy);
}

async function copyGroupOfGroups(form, def, groupdb, isRoot) {
   let datanamelist = {};
   let newgroupdb = await getNewGroupObject(
      groupdb.DefId,
      form.Id,
      groupdb.groupId
   );
   newgroupdb.Label = groupdb.Label;
   newgroupdb.BLabel = groupdb.BLabel;
   newgroupdb.dataNameValue = groupdb.dataNameValue;
   if (groupdb.dataNameId !== 0)
      datanamelist[groupdb.dataNameId] = {
         newDataNameId: 0,
         groupids: [newgroupdb.Id],
      };
   newgroupdb.Order = groupdb.Order;
   newgroupdb.Sequence = groupdb.Sequence + 1;
   newgroupdb.sequenceCount = groupdb.sequenceCount + 1;
   await db.formGroups.put(newgroupdb);

   if (isRoot) {
      await reorderGroups(
         groupdb.formId,
         groupdb.groupId,
         groupdb.DefId,
         false,
         groupdb.Id,
         newgroupdb.Id,
         newgroupdb.sequenceCount
      );

      // form was changed via 'reorderGroups', so reload
      form = await GetForm(form.Id);
   }

   for (let i = 0; i < groupdb.Groups.length; i++) {
      const igroupdb = await db.formGroups.get(groupdb.Groups[i]);
      const igroupdef = def.GroupDefs[igroupdb.DefId];
      if (igroupdef.Type === enums.GroupType.Group) {
         const inewgroupid = await copyGroupOfGroups(
            form,
            def,
            igroupdb,
            false
         );
         newgroupdb.Groups.push(inewgroupid);
      } else {
         let inewgroupdb = await getNewGroupObject(
            igroupdb.DefId,
            form.Id,
            newgroupdb.Id
         );
         inewgroupdb.Label = igroupdb.Label;
         inewgroupdb.BLabel = igroupdb.BLabel;
         inewgroupdb.dataNameValue = igroupdb.dataNameValue;
         if (igroupdb.dataNameId !== 0) {
            if (!datanamelist.hasOwnProperty(igroupdb.dataNameId))
               datanamelist[igroupdb.dataNameId] = {
                  newDataNameId: 0,
                  groupids: [],
               };
            datanamelist[igroupdb.dataNameId].groupids.push(inewgroupdb.Id);
         }
         inewgroupdb.Order = igroupdb.Order;
         inewgroupdb.Sequence = igroupdb.Sequence;
         inewgroupdb.sequenceCount = igroupdb.sequenceCount;
         inewgroupdb.required = igroupdb.required;
         inewgroupdb.requiredEntered = igroupdb.requiredEntered;
         inewgroupdb.total = igroupdb.total;
         inewgroupdb.totalEntered = igroupdb.totalEntered;
         await db.formGroups.put(inewgroupdb);
         newgroupdb.Groups.push(inewgroupdb.Id);
         for (let d = 0; d < igroupdb.Data.length; d++) {
            const ddatadb = await db.formData.get(igroupdb.Data[d]);
            const ndataid = await GetNextDataId();
            let dnewdatadb = {
               Id: ndataid,
               DefId: ddatadb.DefId,
               Value: ddatadb.Value,
               Hidden: ddatadb.Hidden,
               State: enums.State.New,
               formId: form.Id,
               groupId: inewgroupdb.Id,
               groupDataNameIds: [],
            };
            if (ddatadb.hasOwnProperty("content"))
               dnewdatadb.content = ddatadb.content;

            if (!form.dataDefX.hasOwnProperty(ddatadb.DefId)) form.dataDefX[ddatadb.DefId] = [];
            form.dataDefX[ddatadb.DefId].push({ dataId: ndataid, groupId: inewgroupdb.Id });

            if (datanamelist.hasOwnProperty(ddatadb.Id)) {
               datanamelist[ddatadb.Id].newDataNameId = dnewdatadb.Id;
               dnewdatadb.groupDataNameIds = datanamelist[ddatadb.Id].groupids;
            }
            await db.formData.put(dnewdatadb);
            inewgroupdb.Data.push(dnewdatadb.Id);
         }
         await db.formGroups.put(inewgroupdb);
      }
      await db.formGroups.put(newgroupdb);

      await db.forms.put(form);
   }

   const keys = Object.keys(datanamelist);
   for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      const object = datanamelist[key];
      const updatedb = { dataNameId: object.newDataNameId };
      for (let g = 0; g < object.groupids.length; g++) {
         await db.formGroups.update(object.groupids[g], updatedb);
      }
   }

   return newgroupdb.Id;
}

export async function addGroup(form, def, groupdb, isCopy) {
   const origGroupId = groupdb.Id;

   const newgroupids = await CreateGroups(
      [groupdb.DefId],
      form,
      def,
      groupdb.groupId,
      null,
      true
   );

   await db.forms.put(form);

   const newgroupid = newgroupids[0];
   let newgroupdb = await db.formGroups.get(newgroupid);
   newgroupdb.Order = groupdb.Order;
   newgroupdb.Sequence = groupdb.Sequence + 1;
   newgroupdb.sequenceCount = groupdb.sequenceCount + 1;

   if (
      def.GroupDefs[groupdb.DefId].Type === enums.GroupType.Data &&
      isCopy &&
      groupdb.Data.length === newgroupdb.Data.length
   ) {
      for (let d = 0; d < newgroupdb.Data.length; d++) {
         const datadb = await db.formData.get(groupdb.Data[d]);
         const hasvalue =
            !isNullOrEmpty(datadb.Value) ||
            (datadb.hasOwnProperty("content") &&
               !isNullOrEmpty(datadb.content));
         if (hasvalue) {
            const newdatadb = await db.formData.get(newgroupdb.Data[d]);
            newdatadb.Value = datadb.Value;
            if (datadb.hasOwnProperty("content"))
               newdatadb.content = datadb.content;
            await db.formData.put(newdatadb);

            if (newgroupdb.dataNameId === newdatadb.Id) {
               newgroupdb.dataNameValue = newdatadb.Value;
            }
            incrementTotals(newgroupdb, 1, def.DataDefs[newdatadb.DefId]);
         }
      }
   }

   await db.formGroups.put(newgroupdb);

   await reorderGroups(
      groupdb.formId,
      groupdb.groupId,
      groupdb.DefId,
      false,
      origGroupId,
      newgroupdb.Id,
      newgroupdb.sequenceCount
   );

   form = await GetForm(groupdb.formId);
   form.groupX = await MapGroups(form);

   let formdataids = [];
   if (def.GroupDefs[newgroupdb.DefId].Type === enums.GroupType.Group) {
      formdataids = getDataIdsForGroupOfGroups(form, newgroupdb.Id);
   }
   else {
      formdataids = newgroupdb.Data;
   }

   let formdata = await GetDataFromIds(formdataids);
   await SetDefaults(form, formdata);
   await SetGroupTotals(formdata, form);

   await UpdateGroupAutoListLastData(form, groupdb.DefId, groupdb.groupId);

   return {
      form: form,
      groupId: newgroupdb.Id,
   };
}

export async function GroupDelete(groupId) {
   const groupdb = await db.formGroups.get(groupId);
   const formid = groupdb.formId;
   const groupDefinitionId = groupdb.DefId;
   const sequenceCount = groupdb.sequenceCount - 1;
   const parentGroupId = groupdb.groupId === 0 ? "root" : groupdb.groupId;
   const ids = await getGroupIdsAndDataIds(groupId);

   await db.formGroups.bulkDelete(ids.groupids);

   let form = await db.forms.get(formid);

   for (let d = 0; d < ids.dataids.length; d++) {
      const data = await db.formData.get(ids.dataids[d]);
      let newdatadefx = [];
      for (let i = 0; i < form.dataDefX[data.DefId].length; i++) {
         const datadefxitem = form.dataDefX[data.DefId][i];
         if (datadefxitem.dataId !== data.Id) newdatadefx.push(datadefxitem);
      }
      form.dataDefX[data.DefId] = newdatadefx;
      await db.formData.delete(data.Id);
   }

   if (form.State !== enums.State.New) form.State = enums.State.Update;
   await db.forms.put(form);

   await reorderGroups(
      formid,
      groupdb.groupId,
      groupDefinitionId,
      true,
      groupId,
      null,
      sequenceCount
   );

   form = await GetForm(groupdb.formId);
   form.groupX = await MapGroups(form);

   await UpdateGroupAutoListLastData(form, groupDefinitionId, groupdb.groupId);

   return {
      form: form,
      groupId: parentGroupId,
   };
}

async function UpdateGroupAutoListLastData(form, groupDefinitionId, parentGroupId) {

   const groupDefinition = form.def.GroupDefs[groupDefinitionId];
   const isAutoListRepeatingGroup = groupDefinition.Repeats && groupDefinition.AutoList === "$LAST";

   if (isAutoListRepeatingGroup === false) {
      return;
   }

   await saveGroupAutoListData(
      form.Status,
      form.def.Name,
      form.WorkOrderId,
      form.FormGroups,
      groupDefinitionId,
      parentGroupId);
}

export async function GetGroupsForForm(formId) {
   let formgroups = {};
   await db.formGroups
      .where("formId")
      .equals(formId)
      .each(function (group) {
         formgroups[group.Id] = group;
      });

   return formgroups;
}

export async function PopulateFormGroups(groups, form) {
   for (let g = 0; g < groups.length; g++) {
      const gid = groups[g];
      const group = await db.formGroups.get(gid);
      form.FormGroups[gid] = group;
      if (group.Groups.length > 0) {
         await PopulateFormGroups(group.Groups, form);
      }
   }
}

export function GetFormGroups(form) {
   let formgroups = {};
   formgroups[0] = {
      Id: 0,
      DefId: null,
      Groups: [...form.Groups],
      dataNameValue: "",
      groupId: null,
   };
   const keys = Object.keys(form.FormGroups);
   for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      const group = form.FormGroups[key];
      formgroups[key] = {
         Id: group.Id,
         DefId: group.DefId,
         Groups: [...group.Groups],
         dataNameValue: group.dataNameValue,
         groupId: group.groupId,
      };
   }
   return formgroups;
}

export async function SetGroupTotals(formdata, form) {
   let gcount = {};

   let keys = Object.keys(formdata);
   for (let k = 0; k < keys.length; k++) {
      let data = formdata[keys[k]];
      let datadef = form.def.DataDefs[data.DefId];
      const groupid = data.groupId;
      if (!gcount.hasOwnProperty(groupid))
         gcount[groupid] = {
            total: 0,
            required: 0,
            totalEntered: 0,
            requiredEntered: 0,
         };
      const hasvalue =
         !isNullOrEmpty(data.Value) ||
         (data.hasOwnProperty("content") && !isNullOrEmpty(data.content));
      if (!data.Hidden && !data.InfoOnly)
         incrementTotals(gcount[groupid], hasvalue ? 1 : null, datadef);
   }

   keys = Object.keys(gcount);
   for (let k = 0; k < keys.length; k++) {
      let groupid = keys[k];
      let groupdb = form.FormGroups[groupid];
      groupdb.total = gcount[groupid].total;
      groupdb.required = gcount[groupid].required;
      groupdb.totalEntered = gcount[groupid].totalEntered;
      groupdb.requiredEntered = gcount[groupid].requiredEntered;
      await db.formGroups.put(groupdb);
   }
}

export async function UpdateGroupDataNames(form, dataDb, value) {
   if (dataDb.groupDataNameIds.length === 0) return;

   let dataDefinition = form.def.DataDefs[dataDb.DefId];
   let dataNameValue = await GetGroupDataNameValue(dataDefinition, value);

   for (let gg = 0; gg < dataDb.groupDataNameIds.length; gg++) {
      let groupId = dataDb.groupDataNameIds[gg];
      const group = form.FormGroups[groupId];
      if (group.dataNameValue !== dataNameValue) {
         let ggroupdb = { dataNameValue: dataNameValue };
         await db.formGroups.update(groupId, ggroupdb);
      }
   }
}

export async function UpdateGroupDataReferencedName(form, data) {
   //currently does not work until sync'd if labeling a group of groups with a concat field
   const group = form.FormGroups[data.groupId];
   if (group.dataNameId === data.Id) {
      let ggroupdb = { dataNameValue: data.Value };
      await db.formGroups.update(data.groupId, ggroupdb);
   };
}

export async function UpdateGroupTotals(groupData, form, groupId) {
   let updategroup = {
      total: 0,
      totalEntered: 0,
      required: 0,
      requiredEntered: 0,
   };
   const keys = Object.keys(groupData);
   for (let k = 0; k < keys.length; k++) {
      const data = groupData[keys[k]];
      const datadef = form.def.DataDefs[data.DefId];
      const hasvalue =
         !isNullOrEmpty(data.Value) ||
         (data.hasOwnProperty("content") && !isNullOrEmpty(data.content));
      if (!data.Hidden && !data.InfoOnly)
         incrementTotals(updategroup, hasvalue ? 1 : null, datadef);
   }

   await db.formGroups.update(groupId, updategroup);
}

export async function UpdateGroupViewedStatus(groupId) {
   if (groupId === 'root') return;

   await db.formGroups.update(groupId, { WasViewed: true });
}

export async function UpdateHiddenGroups(form, formdatadb) {
   let datadef = form.def.DataDefs[formdatadb.DefId];
   if (datadef.maps.groupReferences.length > 0) {
      const groupkeys = GetGroupsFromHidden(form.groupX, formdatadb.groupId);
      for (let gg = 0; gg < datadef.maps.groupReferences.length; gg++) {
         let groupdef = form.def.GroupDefs[datadef.maps.groupReferences[gg]];
         const hidden = await EvaluateGroupHidden(
            form,
            formdatadb,
            groupdef.hiddenComponents
         );
         for (let gk = 0; gk < groupkeys.length; gk++) {
            const gid = groupkeys[gk];
            let group = form.FormGroups[gid];
            if (groupdef.Id === group.DefId) {
               if (group.Hidden !== hidden) {
                  let ggroupdb = { Hidden: hidden };
                  await db.formGroups.update(Number(gid), ggroupdb);
               }
            }
         }
      }
   }
}

export function GetGroupsFromHidden(groupX, groupId) {
   let list = [groupId].concat(
      groupX[groupId].parents,
      groupX[groupId].siblings
   );
   for (let p = 0; p < groupX[groupId].parents.length; p++) {
      const parentgroupid = groupX[groupId].parents[p];
      list = list.concat(groupX[parentgroupid].siblings);
   }
   for (let s = 0; s < groupX[groupId].siblings.length; s++) {
      const siblinggroupid = groupX[groupId].siblings[s];
      list = list.concat(groupX[siblinggroupid].children);
   }
   return list;
}

export async function MapGroups(form) {
   let groupX = {};

   let parents = [];
   traverseGroups(form, form.Groups, groupX, parents);

   let formdb = { groupX: groupX };
   await db.forms.update(form.Id, formdb);

   return groupX;
}

function traverseGroups(form, groupkeys, groupX, parents) {
   let siblings = { real: [], step: [] };
   let children = [];
   for (let g = 0; g < groupkeys.length; g++) {
      const group = form.FormGroups[groupkeys[g]];
      children.push(group.Id);
      const groupdef = form.def.GroupDefs[group.DefId];
      groupX[group.Id] = { parents: [...parents] };
      parents.push(group.Id);
      if (groupdef.Type === enums.GroupType.Group) {
         groupX[group.Id].children = traverseGroups(
            form,
            group.Groups,
            groupX,
            parents
         );
         children = children.concat(groupX[group.Id].children);
      } else {
         groupX[group.Id].children = [];
      }
      parents.pop();
      if (groupdef.Repeats) {
         siblings.step.push(group.Id);
      } else {
         siblings.real.push(group.Id);
      }
   }
   for (let g = 0; g < groupkeys.length; g++) {
      let real = [...siblings.real];
      let step = [...siblings.step];
      let groupid = groupkeys[g];
      let idx = real.indexOf(groupid);
      if (idx !== -1) real.splice(idx, 1);
      idx = step.indexOf(groupid);
      if (idx !== -1) step.splice(idx, 1);
      groupX[groupid].siblings = real;
      groupX[groupid].stepSiblings = step;
   }
   return children;
}

async function resolveAutoList(groupDef, form, defName) {
   let list = [];
   if (groupDef.AutoList.startsWith("#")) {
      const components = parse(groupDef.AutoList);
      if (components === null) return list;
      list = await GetGroupAutoList(form, components);
   } else if (groupDef.AutoList === "$LAST") {
      list = await GetGroupAutoListLast(defName, form.WorkOrderId, groupDef);
   } else {
      const parts = groupDef.AutoList.split(",");
      for (let p = 0; p < parts.length; p++) {
         const [label, blabel] = parts[p].split("|");
         list.push({ label, blabel, dataNameValue: null });
      }
   }
   return list;
}

async function getGroupIdsAndDataIds(groupId) {
   let ids = { groupids: [groupId], dataids: [] };
   const groupdb = await db.formGroups.get(groupId);
   if (groupdb.Groups.length > 0) {
      for (let g = 0; g < groupdb.Groups.length; g++) {
         let tids = await getGroupIdsAndDataIds(groupdb.Groups[g]);
         ids.groupids = ids.groupids.concat(tids.groupids);
         ids.dataids = ids.dataids.concat(tids.dataids);
      }
   } else {
      ids.dataids = groupdb.Data;
   }
   return ids;
}

async function reorderGroups(
   formId,
   parentGroupId,
   groupDefId,
   isDelete,
   groupId,
   newGroupId,
   sequenceCount
) {
   let formdb;
   let groupdb;
   let groupids = [];
   if (parentGroupId === 0) {
      formdb = await db.forms.get(formId);
      groupids = formdb.Groups;
   } else {
      groupdb = await db.formGroups.get(parentGroupId);
      groupids = groupdb.Groups;
   }

   let newgroups = [];
   let after = false;
   let sequence = 0;
   for (let i = 0; i < groupids.length; i++) {
      const igroupid = groupids[i];
      let tgroupdb;
      let tdefid;
      if (!isDelete || igroupid !== groupId) {
         tgroupdb = await db.formGroups.get(igroupid);
         tdefid = tgroupdb.DefId;
      }
      let isupdate = false;
      let ispush = false;
      let updatekey = null;
      let pushkey = null;
      if (isDelete) {
         if (igroupid === groupId) {
            // delete this one, i.e. skip
            after = true;
         } else {
            if (after && tdefid === groupDefId) {
               isupdate = true;
               updatekey = igroupid;
            } else {
               // insert into new group
               ispush = true;
               pushkey = igroupid;
            }
         }
      } else {
         if (igroupid === groupId) {
            // found where to insert
            after = true;

            ispush = true;
            pushkey = igroupid;
            isupdate = true;
            updatekey = newGroupId;
         } else {
            if (after) {
               // update sequence for group
               isupdate = true;
               updatekey = igroupid;
            } else {
               // insert into new group
               ispush = true;
               pushkey = igroupid;
            }
         }
      }
      if (ispush) {
         if (igroupid === groupId || tdefid === groupDefId) sequence++;
         newgroups.push(pushkey);
         if (tdefid === groupDefId) {
            tgroupdb = await db.formGroups.get(pushkey);
            tgroupdb.sequenceCount = sequenceCount;
            await db.formGroups.put(tgroupdb);
         }
      }
      if (isupdate) {
         if (igroupid === groupId || tdefid === groupDefId) sequence++;
         newgroups.push(updatekey);
         if (tdefid === groupDefId) {
            tgroupdb = await db.formGroups.get(updatekey);
            tgroupdb.Sequence = sequence;
            tgroupdb.sequenceCount = sequenceCount;
            await db.formGroups.put(tgroupdb);
         }
      }
   }

   if (parentGroupId === 0) {
      formdb.Groups = newgroups;
      await db.forms.put(formdb);
   } else {
      groupdb.Groups = newgroups;
      await db.formGroups.put(groupdb);
   }
}

function getDataIdsForGroupOfGroups(form, groupId) {
   const groups = form.FormGroups[groupId].Groups;
   let dataids = [];
   for (let g = 0; g < groups.length; g++) {
      let group = form.FormGroups[groups[g]];
      if (!isNullOrEmpty(group.Groups)) {
         const tempdataids = getDataIdsForGroupOfGroups(form, group.Id);
         if (tempdataids.length > 0) dataids = dataids.concat(tempdataids);
      } else {
         dataids = dataids.concat(group.Data);
      }
   }
   return dataids;
}
