import { useMsal } from '@azure/msal-react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Loader } from '@progress/kendo-react-indicators';
import { RadioButton, TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CSSProperties, useEffect, useState } from 'react';
import { SendForm } from '../api/formsApi';
import { getWorkOrderUsers, transferFormOwnership } from '../api/queries';
import { loginRequest } from '../helpers/authConfig';
import { IApiResponse } from '../types/IApiResponse';
import { IWorkOrderUser } from '../types/IWorkOrderUser';

interface IProps {
  formId: number,
  workOrderId: number;
  onTransferComplete: (formId: number) => void;
  onClose: () => void;
}

const TransferDialog = ({ workOrderId, formId, onTransferComplete, onClose }: IProps) => {

  const { instance, accounts } = useMsal();

  const [accessToken, setAccessToken] = useState<string>();
  const [users, setUsers] = useState<IWorkOrderUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IWorkOrderUser>();
  const [comments, setComments] = useState<string>("");
  const [confirm, setConfirm] = useState<boolean>(false);

  useEffect(() => {

    const go = async () => {
      const tokenResponse = await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

      setAccessToken(tokenResponse.accessToken);
    }

    if (users.length === 0 && accessToken === undefined) go();

  }, [workOrderId, setAccessToken, accessToken, users, accounts, instance])

  const sortUsers = (userA: IWorkOrderUser, userB: IWorkOrderUser) => {

    const aName = `${userA.LastName} ${userA.FirstName}`;
    const bName = `${userB.LastName} ${userB.FirstName}`;

    return aName.localeCompare(bName);
  };

  const getUsersQuery = useQuery(['getWorkOrderUsers', { workOrderId, accessToken }], getWorkOrderUsers,
    {
      enabled: (accessToken !== undefined && users.length === 0),
      onSuccess: async (response: IApiResponse<IWorkOrderUser[]>) => {
        setUsers(response.Data.sort(sortUsers));
      },
      onError: (response: any) => {
        console.log("Error getting users for work order");
      }
    }
  );

  const transferOwnerMutation = useMutation(transferFormOwnership,
    {
      onSuccess: async (response: IApiResponse<number>) => {
        if (response.Success && response.Data) {
          onTransferComplete(response.Data);
        }
      },
      onError: (response: any) => {
        console.log("Error transferring form ownership");
        onTransferComplete(response.Data);
      }
    }
  );

  const onCloseDialog = () => {
    onClose();
  }

  const onTransfer = () => {
    setConfirm(true);
  }

  const onTransferConfirmed = async () => {

    const config = {
      headers: { Authorization: "Bearer " + accessToken }
    };

    const sendFormResult: any = await SendForm(formId, config);

    await transferOwnerMutation.mutateAsync(
      {
        formId: sendFormResult.newid,
        newOwner: selectedUser?.Username!,
        comments,
        accessToken
      }
    )
  }

  const onSelectUser = (user: IWorkOrderUser) => {
    setSelectedUser(user);
  }

  const userRow = (user: IWorkOrderUser, idx: number) => {

    const style: CSSProperties = {
      display: 'grid',
      gridTemplateColumns: 'max-content 150px max-content',
      gap: '20px',
      alignItems: 'center',
    }

    return <div key={`user${idx}`} style={style}>
      <RadioButton name='user-transfer' onChange={() => onSelectUser(user)} ></RadioButton>
      <div>{`${user.LastName}, ${user.FirstName} `}</div>
    </div>
  }

  const onTransferCommentsChange = (event: TextAreaChangeEvent) => {
    setComments(event.value);
  }

  const gridStyle: CSSProperties = {
    display: 'grid',
    overflowY: 'scroll',
    height: '50vh',
    gap: '20px',
    paddingBottom: '20px'
  }

  return (
    <Dialog title="Transfer Form" onClose={onCloseDialog} style={{ top: '10px' }}>

      {confirm === false &&
        <>
          <div style={{ display: 'grid', gap: '20px' }}>

            <h6>Select a user below.  Click 'Transfer' to continue.</h6>

            <div style={gridStyle}>
              {getUsersQuery.isLoading &&
                <div style={{ display: 'grid', justifyContent: 'center', justifyItems: 'center' }}>
                  <Loader type="infinite-spinner" />
                </div>
              }
              {users.map((x, idx) => userRow(x, idx))}
            </div>
          </div>
          <DialogActionsBar>
            <div style={{ display: 'grid', justifyContent: 'center' }}>
              <Button fillMode='solid'
                disabled={selectedUser === undefined}
                themeColor='secondary'
                onClick={onTransfer}
                style={{ width: '300px' }}
              >
                Transfer
              </Button>
            </div>
          </DialogActionsBar>
        </>
      }

      {
        confirm === true &&
        <>
          <div style={{ maxWidth: '80vw', display: 'grid', gap: '20px' }}>

            <h6 style={{ textAlign: 'center' }}>Are you sure you want to transfer this form to '{`${selectedUser?.FirstName} ${selectedUser?.LastName}`}'?</h6>
            <div style={{ display: 'grid', gap: '10px' }}>
              <label>Comments:</label>
              <TextArea value={comments} size='large' onChange={onTransferCommentsChange} style={{ height: '100px' }} />
            </div>

          </div>
          <DialogActionsBar>
            <div style={{ display: 'grid', justifyContent: 'stretch', gridAutoFlow: 'column', gap: '10px' }}>
              <Button fillMode='solid'
                themeColor='secondary'
                onClick={() => setConfirm(false)}
              >
                No
              </Button>
              <Button fillMode='solid'
                themeColor='secondary'
                onClick={onTransferConfirmed}
              >
                Yes
              </Button>
            </div>
          </DialogActionsBar>
        </>
      }

    </Dialog >
  );
}

export { TransferDialog };