import React from "react";

function DataInfoOnlyText({ dataDef, sizeFactor }) {
   return (
      <div
         style={{ fontSize: 0.9 * sizeFactor + "rem" }}
         dangerouslySetInnerHTML={{ __html: dataDef.InputTypeOptions }}
      ></div>
   );
}

export default DataInfoOnlyText;
