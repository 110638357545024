import * as types from "../actionTypes";
import { GetFormDefsForSelect } from "../../db/defHandler";

export function loadDefsList() {
   return (dispatch) => {
      GetFormDefsForSelect().then((defList) => {
         dispatch({ type: types.LOAD_DEFS_LIST, defList });
      });
   };
}

export function setDefInit(value) {
   return { type: types.SET_DEF_INIT, value };
}
