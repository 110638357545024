import { ChangeEvent, Ref, useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { isNullOrEmpty } from "../../helpers/utils";
import "./DataPhoto.css";
import DataLabel from './DataLabel';
import { useQuery } from '@tanstack/react-query';
import { getDocumentById } from '../../api/queries';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../helpers/authConfig';
import { IApiResponse } from '../../types/IApiResponse';
import { downloadBlobAsFileName } from '../../helpers/utils';
import { Loader } from '@progress/kendo-react-indicators';
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { calculateFormAttachmentCurrentTotalSize, getFormDefinitionDtoFromDataPoint } from '../../db/dataHelpers';

interface IAttachmentDataValue {
  content: string;
  fileName: string;
  documentId: string;
  fileSize?: number;
}

interface IProps {
  dataId: number,
  dataDef: any,
  dataValue: IAttachmentDataValue,
  handleOnChange: (data: string, fileName: string, fileSize: number) => void,
  addRefElement: (dataId: number, ref: Ref<HTMLInputElement>) => void,
  sizeFactor: any,
  formIsLocked: boolean,
  isOnline: boolean
}

const maxSingleAttachmentSizeMB = parseInt(process.env.REACT_APP_MAX_SINGLE_ATTACHMENT_SIZE_MB || "2");
const maxSingleAttachmentSizeBytes = maxSingleAttachmentSizeMB * 1000000;

const maxTotalAttachmentSizeMB = parseInt(process.env.REACT_APP_MAX_TOTAL_ATTACHMENT_SIZE_MB || "3");
const maxTotalAttachmentSizeBytes = maxTotalAttachmentSizeMB * 1000000;

const notifyPosition = {
  top: 150,
  left: "50%",
  transform: "translateX(-50%)",
};

const DataAttachment = ({ dataId, dataDef, dataValue, handleOnChange, addRefElement, sizeFactor, formIsLocked, isOnline }: IProps) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const attachmentInputRef = useRef<HTMLInputElement>(null);
  const elRef = useRef(null);

  const allowedFileTypes = ".pdf";

  const [accessToken, setAccessToken] = useState<string>();
  const [showPreview, setShowPreview] = useState(false);
  const [error, setError] = useState<string>();

  const canPreviewAttachment = (): boolean => {

    // if attachment was just selected and still in indexedDb, allow immediate preview
    if (!!dataValue.content) return true;

    if (formIsLocked === true) return false;
    if (isOnline === false) return false;
    if (isAuthenticated === false) return false;

    // if currently downloading preview, can't preview -- (i.e. don't allow doubleclicks)
    if (showPreview === true) return false;

    return true;
  }

  const downloadPdfStringAsBlob = async (pdfString: string) => {
    const r = await fetch(pdfString);
    const blob = await r.blob();
    downloadBlobAsFileName(blob, dataValue.fileName);
  }

  useQuery(['getAttachment', { documentId: dataValue.documentId, accessToken }], getDocumentById,
    {
      enabled: (isOnline && isAuthenticated && showPreview && accessToken !== undefined),
      onSuccess: async (response: IApiResponse<string>) => {
        setAccessToken(undefined);
        setShowPreview(false);

        if (response.Success) {
          const pdfString = atob(response.Data);
          await downloadPdfStringAsBlob(pdfString);

        }
      },
      onError: (response: any) => {
        setAccessToken(undefined);
        setShowPreview(false);
        setError("There was an error previewing the attachment");
      }
    }
  );

  useEffect(() => {
    if (!isNullOrEmpty(elRef.current)) {
      addRefElement(dataId, elRef.current);
    }
  }, [addRefElement, dataId, elRef]);

  const roundBytesToMb = (bytes: number) => {
    return Math.round((bytes / 1000000) * 100) / 100;
  }

  const onHandleGetPhoto = () => {
    attachmentInputRef?.current?.click();
  };

  const handleAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.currentTarget?.files || event.currentTarget.files.length === 0) {
      return;
    }

    const file = event.currentTarget.files[0];

    const nameSplit = file.name.split('.');
    const fileExtension = `.${nameSplit[nameSplit.length - 1]}`;

    // file not allowed
    if (allowedFileTypes.split(',').indexOf(fileExtension) < 0) {
      setError(`Please select a PDF file`);
      return;
    }

    // individual file too large
    if (file.size > maxSingleAttachmentSizeBytes) {
      setError(`The selected file (${roundBytesToMb(file.size)}mb) exceeds the ${maxSingleAttachmentSizeMB}mb limit.`);
      return;
    }

    // total of all attachments too large
    const formDefinitionDto = await getFormDefinitionDtoFromDataPoint(dataId);
    const formTotalAttachmentSizeBytes = await calculateFormAttachmentCurrentTotalSize(formDefinitionDto);
    if ((file.size + formTotalAttachmentSizeBytes) > maxTotalAttachmentSizeBytes) {
      setError(`The selected file (${roundBytesToMb(file.size)}mb), along with all other attachments (${roundBytesToMb(formTotalAttachmentSizeBytes)}mb) exceeds the ${maxTotalAttachmentSizeMB}mb limit.`)
      return;
    }

    try {
      var fileData = await getFileContents(file);
      handleOnChange(fileData, file.name, file.size);
    }
    catch {
      alert("Unable to read file");
    }
  };

  const getFileContents = (file: File): Promise<string> => {

    return new Promise<string>((resolve, reject) => {

      const reader = new FileReader();

      reader.onload = function (event) {
        const fileData = event?.target?.result?.toString();

        if (!fileData) {
          reject();
          return;
        }

        resolve(fileData);
      }

      reader.readAsDataURL(file);
    });
  };

  const onPreview = async () => {

    if (showPreview) return;

    if (!!dataValue.content) {
      downloadPdfStringAsBlob(dataValue.content);
      return;
    }

    const tokenResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    });

    setAccessToken(tokenResponse.accessToken);
    setShowPreview(true);
  }

  const onClearPhoto = () => {
    handleOnChange("", "", 0);
  };

  const disablePreview = canPreviewAttachment() === false;

  return (
    <div ref={elRef}>
      <input type='hidden' value={dataId} />
      <DataLabel
        dataDef={dataDef}
        dataValue={dataValue}
        sizeFactor={sizeFactor}
      />

      <div
        id={"preview-" + dataId}
        style={{
          display: 'grid',
          justifyContent: 'start',
          justifyItems: 'start',
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div style={{
          border: `${!!dataValue.fileName ? 'solid' : 'dashed'} 2px #666`,
          minHeight: 150,
          display: 'grid',
          alignItems: 'center',
          borderRadius: 4,
          width: 300
        }}>
          {!dataValue.fileName &&
            <div style={{
              display: 'grid',
              justifyItems: 'center',
              gap: 10,
              padding: 10,

            }}>

              <input
                ref={attachmentInputRef}
                type="file"
                accept={allowedFileTypes}
                style={{ display: "none" }}
                onChange={handleAttachment}
                value=''
              />

              <div style={{ fontSize: 64 * sizeFactor }} className="k-icon k-i-file-add"></div>
              <Button
                style={{ fontSize: 1 * sizeFactor + "rem" }}
                onClick={onHandleGetPhoto}
                disabled={formIsLocked}
              >
                Select PDF Attachment
              </Button>

            </div>
          }

          {dataValue.fileName &&
            <div style={{
              display: 'grid',
              justifyItems: 'center',
              gap: 10,
              width: '100%'
            }}>
              <div style={{
                padding: 10,
                width: '100%',
                display: 'grid',
                gridAutoFlow: 'column',
                justifyContent: 'space-between',
              }}>
                <Button rounded='small'
                  style={{ fontSize: 1 * sizeFactor + "rem" }}
                  onClick={onPreview}
                  disabled={disablePreview}
                >
                  <div style={{ display: 'grid', gridAutoFlow: 'column', alignItems: 'center', gap: 5 }}>
                    {showPreview && <Loader size="small" type='infinite-spinner' />}
                    {!showPreview && <span style={{ fontSize: 24 }} className="k-icon k-i-preview"></span>}
                    <span>Preview</span>
                  </div>
                </Button>
                <Button
                  style={{ fontSize: 1 * sizeFactor + "rem" }}
                  onClick={onClearPhoto}
                  disabled={formIsLocked}
                >
                  <div style={{ display: 'grid', gridAutoFlow: 'column', alignItems: 'center', gap: 5 }}>
                    <span style={{ fontSize: 24 }} className="k-icon k-i-trash"></span>
                    <span>Clear</span>
                  </div>
                </Button>
              </div>
              <div style={{ fontSize: 64 * sizeFactor }} className="k-icon k-i-file-pdf"></div>
              <div style={{
                fontSize: 1 * sizeFactor + "rem",
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                backgroundColor: '#eee',
                borderTop: 'solid 1px #999',
                padding: '10px',
              }}>{dataValue.fileName}</div>
            </div>
          }
        </div>
      </div>

      {!!error && (
        <NotificationGroup style={notifyPosition}>
          <Notification
            type={{
              style: 'error',
              icon: true,
            }}
            style={{
              fontSize: `${1 * sizeFactor}rem`
            }}
            closable={true}
            onClose={() => setError(undefined)}
          >
            {error}
          </Notification>
        </NotificationGroup>)}

    </div >
  );
}


export default DataAttachment;
