import React, { useRef } from "react";
import {
   ListView,
   ListViewHeader,
   ListViewFooter,
} from "@progress/kendo-react-listview";
import FormGroupGroupItem from "./FormGroupGroupItem";
import DataNote from "./FormDataTypes/DataNote";
import { isNullOrEmpty } from "../helpers/utils";
import * as enums from "../helpers/enums";
import { FormBackButton } from "./FormBackButton";
import { ChangeRequestNotation } from './ChangeRequestNotation';

function FormGroupGroups({
   form,
   groupId,
   setGroupId,
   groupAdd,
   groupDelete,
   sizeFactor,
   formIsLocked,
   isOnline
}) {
   const anchor = useRef(null);
   let tgroupids = [];
   if (groupId === "root") {
      tgroupids = form.Groups;
   } else {
      tgroupids = form.FormGroups[groupId].Groups;
   }
   let groupids = [];
   for (let g = 0; g < tgroupids.length; g++) {
      if (!form.FormGroups[tgroupids[g]].Hidden) groupids.push(tgroupids[g]);
   }
   const prevGroupId =
      groupId === "root" || form.FormGroups[groupId].groupId === 0
         ? "root"
         : form.FormGroups[groupId].groupId;

   const groupListHeader = () => {
      const label =
         groupId === "root"
            ? "Home"
            : form.def.GroupDefs[form.FormGroups[groupId].DefId].Label;
      const note =
         groupId === "root"
            ? ""
            : form.def.GroupDefs[form.FormGroups[groupId].DefId].Note;
      return (
         <ListViewHeader
            style={{
               color: "black",
               fontWeight: "bold",
               fontSize: 1 * sizeFactor + "rem",
               padding: 5,
               borderTop: '2px solid lightgrey',
               borderBottom: '2px solid lightgrey',
               marginBottom: '5px',
               backgroundColor: 'rgb(245, 245, 245)',
            }}
         >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
               <div>
                  {label}
                  {isNullOrEmpty(note) ? null : (
                     <DataNote
                        note={note}
                        anchor={anchor}
                        sizeFactor={sizeFactor}
                     />
                  )}
               </div>
               {groupId !== "root" &&
                  <FormBackButton formId={form.Id} prevGroupId={prevGroupId} setGroupId={setGroupId} />
               }
            </div>
         </ListViewHeader>
      );
   };

   const groupListFooter = () => {
      return (
         <ListViewFooter
            style={{
               paddingLeft: 5,
               paddingTop: 5,
               paddingBottom: 5,
               paddingRight: 5
            }}
         >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
               <div
                  style={{
                     color: "lightgrey",
                     fontSize: 0.9 * sizeFactor + "rem",
                  }}
               >
                  {groupids.length} Groups
               </div>
               {groupId !== "root" &&
                  <FormBackButton formId={form.Id} prevGroupId={prevGroupId} setGroupId={setGroupId} />
               }
            </div>
         </ListViewFooter>
      );
   };

   const groupListItems = (props) => {
      const tgroup = form.FormGroups[props.dataItem];
      const groupdef = form.def.GroupDefs[tgroup.DefId];
      let counts = {
         total: 0,
         required: 0,
         totalEntered: 0,
         requiredEntered: 0,
      };
      if (groupdef.Type === enums.GroupType.Group) {
         counts.total = tgroup.Groups.length;
      } else {
         counts.total = tgroup.total;
         counts.required = tgroup.required;
         counts.totalEntered = tgroup.totalEntered;
         counts.requiredEntered = tgroup.requiredEntered;
      }
      return (
         <FormGroupGroupItem
            form={form}
            group={tgroup}
            groupDef={groupdef}
            sequence={tgroup.Sequence}
            dataNameValue={tgroup.dataNameValue}
            setGroupId={setGroupId}
            groupAdd={groupAdd}
            groupDelete={groupDelete}
            counts={counts}
            sizeFactor={sizeFactor}
            formIsLocked={formIsLocked}
         />
      );
   };

   const comment =
      groupId === "root"
         ? form.ChangeRequestComment
         : form.FormGroups[groupId].Comment;

   return (
      <>
         {(!!comment || !!form.ChangeRequestDocumentId) &&
            <ChangeRequestNotation
               sizeFactor={sizeFactor}
               comment={comment}
               documentId={form.ChangeRequestDocumentId}
               isOnline={isOnline}
            />
         }

         <ListView
            data={groupids}
            item={groupListItems}
            header={groupListHeader}
            footer={groupListFooter}
         />
      </>
   );
}

export default FormGroupGroups;
