import db from "../db/db";
import { addDays, getLocalISODateTime } from "./dateUtils";
import { store } from "../redux/store";
import { isNullOrEmpty } from "./utils";

export const logLevel = {
   System: 0,
   Error: 1,
   Warning: 2,
   Info: 3,
   Trace: 99,
   0: "System",
   1: "Error",
   2: "Warning",
   3: "Info",
   99: "Trace",
};

let useLevel = null;
let usetime = new Date().getTime();

export async function System(from, message) {
   await Message(logLevel.System, from, message);
}

export async function Error(from, message, error) {
   if (typeof error === "undefined" || error === null) {
      await Message(logLevel.Error, from, message);
   }
   if (typeof error === "string") {
      await Message(logLevel.Error, from, message, error);
      return;
   }
   if (Array.isArray(error)) {
      await Message(logLevel.Error, from, message, error.toString());
   }
   if (typeof error === "object") {
      await Message(logLevel.Error, from, message, error.message);
      return;
   }
}

export async function Warning(from, message) {
   if (getLogLevel() < logLevel.Warning) return;

   await Message(logLevel.Warning, from, message);
}

export async function Info(from, message) {
   if (getLogLevel() < logLevel.Info) return;

   await Message(logLevel.Info, from, message);
}

export async function Trace(from, message) {
   if (getLogLevel() < logLevel.Trace) return;

   await Message(logLevel.Trace, from, message);
}

export async function Message(level, from, message, error) {
   await db.transaction("rw!", db.logs, function () {
      db.logs.add({
         level: level,
         datetime: new Date().toISOString(),
         from: from,
         message: message,
         error: typeof error === "undefined" ? "" : error,
      });
   });
}

export async function GetLogs() {
   let list = await db.logs.toArray();
   list.sort((b, a) => {
      return new Date(a.datetime) - new Date(b.datetime);
   });
   return list;
}

export async function PurgeAll() {
   await db.logs.toCollection().delete();

   System("logger:PurgeAll", "All logs purged");
}

export async function PurgeKeepDays(daysToKeep) {
   let deletelist = [];
   let xdaysago = addDays(daysToKeep * -1, null);
   await db.logs.toCollection().each(function (log, cursor) {
      if (new Date(log.datetime) < xdaysago) deletelist.push(log.id);
   });

   db.logs.bulkDelete(deletelist);

   System(
      "logger:PurgeKeepDays",
      "Keep " +
         daysToKeep +
         " day(s).  Purged " +
         deletelist.length +
         " log(s) purged"
   );
}

export async function SetLogLevel(level) {
   let ui = { logLevel: level };
   await db.ui.update(1, ui);
   return level;
}

export async function PurgeLogs(daysToKeep) {
   if (isNullOrEmpty(daysToKeep) || daysToKeep === 0) {
      await PurgeAll();
   } else {
      const lastpurge = store.getState().ui.lastLogPurge;
      const today = getLocalISODateTime(null);
      if (
         isNullOrEmpty(lastpurge) ||
         // eslint-disable-next-line eqeqeq
         lastpurge.split("T")[0] != today.split("T")[0]
      ) {
         await PurgeKeepDays(daysToKeep);
      } else {
         return lastpurge;
      }
   }
   const purgedate = getLocalISODateTime(null);
   let ui = { lastLogPurge: purgedate };
   await db.ui.update(1, ui);
   return purgedate;
}

function getLogLevel() {
   if (useLevel !== null && (new Date().getTime() - usetime) / 60000 < 1)
      return useLevel;

   useLevel = store.getState().ui.logLevel;
   usetime = new Date().getTime();

   return useLevel;
}
