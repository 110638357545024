import React, { useEffect, useRef, useCallback } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import DataLabel from "./DataLabel";
import { GetValidation } from "../../db/valHandler";
import { isNullOrEmpty } from "../../helpers/utils";

function DataSelect({
   dataId,
   editorId,
   handleOnChange,
   handleOnBlur,
   dataDef,
   dataValue,
   filterOnValue,
   addRefElement,
   sizeFactor,
   formIsLocked,
   workOrderId,
}) {
   const ref = useRef(null);
   const [allData, setAllData] = React.useState([]);
   const [data, setData] = React.useState([]);
   const [value, setValue] = React.useState(null);

   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) {
         addRefElement(dataId, ref.current);
      }
   }, [addRefElement, dataId]);

   useEffect(() => {
      let isMounted = true;
      const preFilter = (data) => {
         if(data.length > 0) {
            if(data[0]["WorkOrderId"]!==undefined) {
               data = data.filter((x) => x["WorkOrderId"] === workOrderId);
            }
         };
         if (isNullOrEmpty(dataDef.FilterOnProperty) || isNullOrEmpty(filterOnValue) || (dataDef.FilterOnProperty.indexOf("WorkOrder") >= 0)) {
            return data;
         };
         return data.filter((x) => x[dataDef.FilterOnProperty] === filterOnValue);
      };
      GetValidation(dataDef.ValType, dataDef.ValList).then((response) => {
         if (isMounted) {
            var records = Object.values(response.records);
            var filtered = preFilter(records);
            setAllData(filtered);
            setData(filtered);
         }
      });
      return () => {
         isMounted = false;
      };
   }, [dataDef.ValList, dataDef.ValType, dataDef.FilterOnProperty, filterOnValue, workOrderId]);

   // Used Callback method to prevent useEffect from firing if allData changes
   const onDataValueChange = useCallback(() => {
      const value = allData.find((x) => x.id === dataValue) ?? null;
      setValue(value);
    }, [dataValue, allData]);
   useEffect(onDataValueChange, [dataValue, onDataValueChange]);

   const filterChange = (event) => {
      setData(filterBy(allData, event.filter));
   };

   return (
      <>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <ComboBox
            ref={ref}
            id={editorId}
            data={data}
            onBlur={handleOnBlur}
            textField="label"
            dataItemKey="id"
            value={value}
            onChange={handleOnChange}
            filterable={true}
            onFilterChange={filterChange}
            placeholder="Please select ..."
            disabled={dataDef.ReadOnly || formIsLocked}
            required={dataDef.Required}
            style={{ width: "100%", fontSize: 0.9 * sizeFactor + "rem" }}
         />
      </>
   );
}

export default DataSelect;
