import React from "react";
import * as enums from "../helpers/enums";
import DataInfoOnlyText from "./FormDataTypes/DataInfoOnlyText";
import DataInfoOnlyPic from "./FormDataTypes/DataInfoOnlyPic";

function FormDataInfo({ dataDef, sizeFactor, hidden }) {
   return (
      <div
         className="form-data-item"
         key={"parent-data-item-def-" + dataDef.Id}
         style={{display: hidden ? "none" : ""}}
      >
         {dataDef.InputType === enums.InputType.Picture ? (
            <DataInfoOnlyPic dataDef={dataDef} sizeFactor={sizeFactor} />
         ) : (
            <DataInfoOnlyText dataDef={dataDef} sizeFactor={sizeFactor} />
         )}
      </div>
   );
}

export default FormDataInfo;
