import * as enums from "./enums";
import { formatStation } from "./utils";
import { formatChainage } from "./utils";

export function UpdateSiblings({
   oldGroupData,
   newGroupData,
   dataDefs,
   refElements,
   dataId,
}) {
   let refresh = false;
   const keys = Object.keys(newGroupData);
   for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      if (
         dataId !== Number(key) &&
         (newGroupData[key].Value !== oldGroupData[key].Value ||
            newGroupData[key].Hidden !== oldGroupData[key].Hidden) &&
         refElements.current.hasOwnProperty(key)
      ) {
         if (newGroupData[key].Value !== oldGroupData[key].Value) {
            const element = refElements.current[key].hasOwnProperty("element")
               ? refElements.current[key].element
               : refElements.current[key];
            const datadef = dataDefs[newGroupData[key].DefId];
            const needsrefresh = updateValue(
               datadef,
               element,
               newGroupData[key].Value
            );
            if (!refresh && needsrefresh) refresh = true;
         }
         if (newGroupData[key].Hidden !== oldGroupData[key].Hidden) {
            refresh = true;
         }
      }
   }
   return refresh;
}

function updateValue(dataDef, element, value) {
   let newvalue;
   switch (dataDef.InputType) {
      case enums.InputType.Number:
         newvalue = value.toLocaleString("en-US");
         break;

      case enums.InputType.Station:
         newvalue = formatStation(value);
         break;

      case enums.InputType.Chainage:
         newvalue = formatChainage(value);
         break;

      case enums.InputType.TextArea:
         if (element.current.value === value) return false;
         element.current.value = value;
         return false;

      default:
         newvalue = value;
         break;
   }
   if (element.value === newvalue) return false;
   if (!element.hasOwnProperty("value")) return true;
   element.value = newvalue;
   return false;
}
