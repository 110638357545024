import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Main from "../pages/Main";
import Forms from "../pages/Forms";
import Sync from "../pages/Sync";
import Setup from "../pages/Setup";
import Logs from "../pages/Logs";
import Troubles from "../pages/Troubles";
import SubmitForm from "./Submit/SubmitForm";
import Support from "../pages/Support";
import ReferenceLibrary from "../pages/ReferenceLibrary";

function AppRouter({ route }) {
   switch (route) {
      case "/":
      case "home":
         return <Main />;
      case "forms":
         return <Forms />;
      case "sync":
         return <Sync />;
      case "setup":
         return <Setup />;
      case "logs":
         return <Logs />;
      case "troubles":
         return <Troubles />;
      case "submit":
         return <SubmitForm />;
      case "support":
         return <Support />;
      case "referencelibrary":
         return <ReferenceLibrary />;
      default:
         return <Main />;
   }
}

AppRouter.propTypes = {
   route: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
   return {
      route: state.ui.route,
   };
}

export default connect(mapStateToProps)(AppRouter);
