import React, { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
   Notification,
   NotificationGroup,
} from "@progress/kendo-react-notification";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "../redux/actions/uiActions";
import "./Logs.css";
import { getDateTime } from "../helpers/dateUtils";
import * as logger from "../helpers/logger";

const notifyPosition = {
   top: 300,
   left: "50%",
   transform: "translateX(-50%)",
};

const loglevellist = [
   { value: 1, label: "Error" },
   { value: 2, label: "Warning" },
   { value: 3, label: "Info" },
   { value: 99, label: "Trace" },
];

function Logs({ actions, logLevel, sizeFactor }) {
   const [toastState, setToastState] = useState({ style: "none", message: "" });
   const [gridData, setGridData] = useState([]);

   useEffect(() => {
      let isMounted = true;
      logger.GetLogs().then((logs) => {
         if (isMounted) {
            let flogs = [];
            for (let l = 0; l < logs.length; l++) {
               const log = logs[l];
               const level0 = logger.logLevel[log.level].charAt(0);
               flogs.push({
                  id: log.id,
                  level: log.level,
                  l: level0,
                  datetime: getDateTime(log.datetime),
                  from: log.from,
                  message: log.message,
                  error: log.error,
                  text:
                     log.level === logger.logLevel.Error
                        ? log.error
                        : log.message,
               });
            }
            setGridData(flogs);
         }
      });
      return () => {
         isMounted = false;
      };
   }, []);

   const LogDetail = (props) => {
      const log = props.dataItem;
      return (
         <section style={{ fontSize: 0.95 * sizeFactor + "rem" }}>
            <br />
            <strong>Level:</strong> {logger.logLevel[log.level]}
            <br />
            <strong>Date/Time:</strong> {log.datetime}
            <br />
            <strong>From:</strong> {log.from}
            <br />
            <strong>Message:</strong> {log.message}
            <br />
            <strong>Error:</strong> {log.error}
         </section>
      );
   };

   const expandChange = (event) => {
      let newData = gridData.map((item) => {
         if (item.id === event.dataItem.id) {
            item.expanded = !event.dataItem.expanded;
         }

         return item;
      });
      setGridData(newData);
   };

   const rowRender = (trElement, props) => {
      let rowstyle = { fontSize: 0.95 * sizeFactor + "rem" };
      switch (props.dataItem.level) {
         case logger.logLevel.Error:
            rowstyle.backgroundColor = "#ffb2b2";
            break;
         case logger.logLevel.Warning:
            rowstyle.backgroundColor = "#ffffbf";
            break;
         case logger.logLevel.Trace:
            rowstyle.backgroundColor = "#defade";
            break;
         default:
            break;
      }
      return React.cloneElement(
         trElement,
         { style: rowstyle },
         trElement.props.children
      );
   };

   const purgeLogsButton = async () => {
      actions.purgeLogs(0);
      showToast("success", "Purge Logs complete", 3000);
      setGridData([]);
   };

   const showToast = (style, message, timeout) => {
      setToastState({ style: style, message: message });
      setTimeout(() => {
         setToastState({ style: "none", message: "" });
      }, timeout);
   };

   const logleveldefault = logLevel;

   const handleSetLogLevel = (event) => {
      actions.setLogLevel(event.value);
   };

   return (
      <div className="logs-container">
         <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
               <Label
                  style={{
                     display: "block",
                     fontWeight: "600",
                     fontSize: 1 * sizeFactor + "rem",
                     paddingTop: "15px",
                  }}
               >
                  Log Level
               </Label>
               <Button
                  fillMode="outline"
                  onClick={purgeLogsButton}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Purge
               </Button>
               <Button
                  themeColor="primary"
                  fillMode="flat"
                  icon="wrench"
                  style={{ fontSize: 1 * sizeFactor + "rem" }}
                  onClick={() => {
                     actions.setRoute("troubles");
                  }}
               >
                  Return to Troubleshooting
               </Button>
            </div>
            <RadioGroup
               layout="horizontal"
               data={loglevellist}
               defaultValue={logleveldefault}
               style={{ marginBottom: "5px", fontSize: 1 * sizeFactor + "rem" }}
               onChange={handleSetLogLevel}
            />
         </div>
         <div className="logs-grid">
            <Grid
               data={gridData}
               detail={LogDetail}
               expandField="expanded"
               onExpandChange={expandChange}
               rowRender={rowRender}
            >
               <Column field="l" title=" " width="22px" />
               <Column field="datetime" title="Date/Time" width="125px" />
               <Column field="text" title="Message/Error" width="" />
            </Grid>
         </div>
         <NotificationGroup style={notifyPosition}>
            {toastState.style === "success" ? (
               <Notification
                  type={{
                     style: toastState.style,
                     icon: true,
                  }}
                  closable={true}
                  onClose={() => setToastState({ style: "none", message: "" })}
               >
                  {toastState.message}
               </Notification>
            ) : null}
         </NotificationGroup>
      </div>
   );
}

function mapStateToProps(state) {
   return {
      logLevel: state.ui.logLevel,
      sizeFactor: state.ui.sizeFactor,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setLogLevel: bindActionCreators(uiActions.setLogLevel, dispatch),
         purgeLogs: bindActionCreators(uiActions.purgeLogs, dispatch),
         setRoute: bindActionCreators(uiActions.setRoute, dispatch),
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
