import React, { useState } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { isNullOrEmpty } from "../../helpers/utils";
import "./DataNote.css";

function DataNote({ anchor, note, sizeFactor }) {
   const [showNote, setShowNote] = useState(false);

   let noteclass = "note-content";
   if (!isNullOrEmpty(sizeFactor)) {
      if (sizeFactor < 1) {
         noteclass = noteclass + " note-content-10";
      } else {
         noteclass = noteclass + " note-content-" + sizeFactor * 10;
      }
   }

   return (
      <>
         &nbsp;
         <span
            className="k-icon k-i-info"
            style={{ fontSize: 1 * sizeFactor + "rem", color: "blue" }}
            ref={anchor}
            onClick={() => setShowNote(!showNote)}
         ></span>
         <Popup
            anchor={anchor.current}
            show={showNote}
            popupClass={noteclass}
            style={{ fontSize: 1 * sizeFactor + "rem" }}
         >
            {note}
         </Popup>
      </>
   );
}

export default DataNote;
