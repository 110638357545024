import { IAppState } from '../types/IAppState';

const initialState: IAppState = {
  ui: {
    username: "",
    init: false,
    route: "home",
    submitType: "",
    formid: 0,
    online: navigator.onLine,
    logLevel: 0,
    lastLogPurge: null,
    sizeFactor: 1,
    troubleCode: "",
    checkVersion: true,
    includeSubmitted: false,
    releaseNotes: '',
  },
  keys: {},
  user: {
    FullName: "",
    Roles: "",
    Username: "",
    ProjectList: [],
    HasDigitalId: false,
    Email: "",
    Recipients: [],
    LastSyncDate: "",
    copyApprover: false,
    FormNewPreferences: {
      LastZipCodeUsed: "",
      LastLocationType: "geo",
      LastUpdated: new Date().toISOString()
    },
    FormListingPreferences: {
      SortByValue: "Date",
      LastUpdated: new Date().toISOString(),
    },
  },
  defs: {
    init: true,
    list: [],
  },
  forms: {
    formid: null,
    groupId: "root",
    form: {},
    list: [],
    init: true,
  },
  vals: {},
};

export default initialState;
