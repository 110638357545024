function HealthCheck()
{
    var dateTimeCurrent = new Date();
    var dateTimeText = dateTimeCurrent.toISOString();

    return (
        <div>
            <h1>Enforms</h1>
            <h2>Health Check</h2>
            <dl>
                <dt>Status: </dt>
                <dd>Healthy</dd>
                <dt>Date Time (ISO 8601): </dt>
                <dd>{dateTimeText}</dd>
            </dl>
        </div>
    );
}

export default HealthCheck;
