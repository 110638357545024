import { instance as axios } from "./apiClient";
import {
   normalizeUrl,
   isNullOrEmpty,
   getLocationObject,
   isCanadianPostalCode,
   isUSZipCode,
} from "../helpers/utils";
import { compareDates } from "../helpers/utils";
import { GetFormForServer, SaveFormToLocalDb } from "../db/formServer";
import * as enums from "../helpers/enums";
import { getUser } from "../helpers/userInfo";
import { CheckFormId, DeleteForm } from "../db/formHandler";
import * as logger from "../helpers/logger";
import { GetWeatherThen } from "./weatherApi";
import db from "../db/db";

import { getFormLastsDataObj } from "../db/lastHandler";
import { GetDefsLastAsObject } from "../helpers/setupUtil";

const maxSingleAttachmentSizeMB = parseInt(process.env.REACT_APP_MAX_SINGLE_ATTACHMENT_SIZE_MB || "2");
const maxSingleAttachmentSizeBytes = maxSingleAttachmentSizeMB * 1000000;

export async function SubmitForm(
   id,
   digitalIdKey,
   submitType, // "submit", "approve", "changeRequest"
   comment,
   accessToken,
   additionalRecipientEmails,
   copyApprover,
   sendExcelFile
) {
   logger.Info(
      "formsApi:SubmitForm",
      "Submit Form start " + id + " " + submitType
   );

   const user = await getUser(true);
   const config = {
      headers: { Authorization: "Bearer " + accessToken },
   };

   // Check Version
   const success = await checkVersion(config);
   if (!success) {
      throw new Error("Invalid version - Please restart the app, log in, and sync.");
   }

   if (!user.hasOwnProperty("DigitalIdOnFile")) {
      throw new Error("One-time Sync needed before Submit");
   }

   const submitid = await CheckFormId(id);

   const username = user.Username;
   const submitdto = {
      Username: username,
      Id: submitid,
      Form: null,
      StepStatus:
         submitType === "submit"
            ? enums.WorkflowStatus.PendingApproval
            : submitType === "approve"
               ? enums.WorkflowStatus.Approved
               : enums.WorkflowStatus.ChangeRequest,
      digitalIdKey: digitalIdKey,
      ContactInfo: user.FullName,
      LocationInfo: user.locationInfo,
      Reason: user.reason,
      Comment: comment,
      JobTitle: user.jobTitle,
      AdditionalRecipients: additionalRecipientEmails,
      copyApprover: copyApprover,
      SendExcelFile: sendExcelFile
   };

   if (submitType === "submit") {
      const sendFormLastsData = true;
      const result = await SendForm(submitid, config, sendFormLastsData);
      submitdto.Id = result.newid;

      if (result.status === "failure") {
         logger.Error("formsApi:SubmitForm", "Error sending form from submit.");
         return false;
      }
   }

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/SubmitForm"
   );

   return axios.post(url, submitdto, config).then(
      async (serverResponse) => {
         let response = serverResponse.data;
         if (response.Success) {
            if (submitType === "submit") {
               await db.forms.update(submitdto.Id, {
                  Status: enums.Status.Submitted,
               });
            } else {
               await DeleteForm(submitid);
            }
         } else {
            logger.Error(
               "formsApi:SubmitForm",
               "Error from server. " + response.Errors.toString()
            );
            throw new Error(response.Errors.toString());
         }
         return response.Success;
      },
      function (error) {
         logger.Error("formsApi:SubmitForm", "Error submitting form", error);
         return false;
      }
   );
}

export async function SendForm(id, config, sendFormLastData = false) {
   let formDto;
   let weatherlist;
   let anychanges;
   try {
      [formDto, weatherlist, anychanges] = await GetFormForServer(id);
   } catch (err) {
      logger.Error("formsApi:SendForm", "Error getting form for server.", err);
      throw err;
   }
   if (!anychanges) return { id: id, newid: id, status: "skip" };

   // Get weather
   for (let l = 0; l < weatherlist[formDto.Id].length; l++) {
      const dataid = weatherlist[formDto.Id][l];
      const dataidx = formDto.FormData.findIndex((item) => {
         return item.Id === dataid;
      });
      let data = formDto.FormData[dataidx];
      await getWeatherForData(formDto, data);
   }

   let documents = formDto.documents;
   delete formDto.documents;

   let result;
   try {
      result = await sendFormToServer(formDto, config, documents);
      if (result.status === "failure") return result;

      const formDef = await db.defs.get(formDto.DefId);

      let faileddocids = [];
      for (let d = 0; d < documents.length; d++) {

         let documentData = documents[d];
         const dataDefinition = formDef.DataDefs[documentData.DefId];

         // ignore attachments that didn't change
         if (documentData.State === enums.State.None && dataDefinition.IsAttachmentInputType) {
            continue;
         }

         const documentdto = {
            Id: documentData.Value,
            FileContent: documentData.content,
            FileName: documentData.fileName
         };

         const docResult = await sendDocumentContentToServer(id, documentdto, config);

         if (docResult.status === "failure") {
            faileddocids.push(documentdto.Id);
         }
         else if (dataDefinition.IsAttachmentInputType) {
            let formDataDb = await db.formData.get(documentData.Id);
            if (!!formDataDb) {
               formDataDb.content = '';
               await db.formData.put(formDataDb);
            }
         }
      }

      if (faileddocids.length > 0) {
         result.status = "failure";
         result.message =
            "The following documents failed: " + faileddocids.toString();
      }

      if (sendFormLastData === true) {
         const formDef = await db.defs.get(formDto.DefId);
         const lastsDef = await GetDefsLastAsObject();
         const formLastsData = getFormLastsDataObj(lastsDef, formDef.Name)

         const url = normalizeUrl(
            process.env.REACT_APP_API_SERVER_URL,
            "api/EnFormMApi/SyncUserData"
         );

         try {
            const sendData = {
               lastData: formLastsData,
               userData: []
            };

            await axios.post(url, sendData, config);
         }
         catch (err) {
            logger.Error(
               "formsApi:SendFormLasts",
               "Error occurred sending form lasts " + id,
               err
            );
         }
      }

   } catch (err) {
      logger.Error(
         "formsApi:SendForm",
         "Error occurred sending form " + id,
         err
      );
      result.status = "failure";
      result.message = "A hard error occurred sending form.  See logs.";
   }

   if (result.status === "success") {
      await setFormState(id, enums.State.None);
   }

   return result;
}

async function sendFormToServer(dto, config, documents) {
   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/SaveForm"
   );

   return axios.post(url, dto, config).then(
      async function (response) {
         const newform = response.data;
         await SaveFormToLocalDb(newform, documents, enums.State.Update);

         return {
            id: dto.Id,
            newid: newform.Id,
            status: "success",
         };
      },
      function (error) {
         logger.Error("formsApi:SendForm", "Error sending form", error);
         return {
            id: dto.Id,
            status: "failure",
            message: "An error occurred saving form " + dto.Id + ".  See logs.",
         };
      }
   );
}

async function sendDocumentContentToServer(formId, dto, config) {
   const payloadsize = dto.FileContent.length;

   if (payloadsize === 0) {
      logger.System(
         "formApi:sendDocument",
         "Form Id: " +
         formId +
         ", Document Id: " +
         dto.Id +
         `. Document size is 0 `
      );
   }

   if (payloadsize > maxSingleAttachmentSizeBytes) {
      logger.Error(
         "formApi:sendDocument",
         "Form Id: " +
         formId +
         ", Document Id: " +
         dto.Id +
         `. Document size is over ${maxSingleAttachmentSizeMB}mb. ` +
         payloadsize
      );
      return {
         id: dto.Id,
         status: "failure",
         message:
            "Form Id: " +
            formId +
            ", Document Id: " +
            dto.Id +
            ". Document size is over 4mb",
      };
   }

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/SaveDocument"
   );

   logger.System(
      "formsApi:sendDocumentContentToServer",
      `sending document content | Id: ${dto.Id} | FormId: ${formId} | Size: ${dto.FileContent.length}`
   );

   return axios.post(url, dto, config).then(
      async function (response) {
         if (response.data) {
            return { id: dto.Id, status: "success" };
         } else {
            return {
               id: dto.Id,
               status: "failure",
               message:
                  "Form Id: " +
                  formId +
                  "Document Id: " +
                  dto.Id +
                  ". Document failed to save",
            };
         }
      },
      function (error) {
         logger.Error(
            "formsApi:sendDocument",
            "Form Id: " +
            formId +
            "Document Id: " +
            dto.Id +
            "Error sending document",
            error
         );
         return {
            id: dto.Id,
            status: "failure",
            message:
               "Form Id: " +
               formId +
               "Document Id: " +
               dto.Id +
               "An error occurred.  See logs.",
         };
      }
   );
}

export async function RetrieveForm(forminfo, config) {
   const formdb = await db.forms.get(forminfo.Id);

   if (formdb != null) {
      const datecompare = compareDates(
         forminfo.UpdateDateTime,
         formdb.UpdateDateTime
      );

      if (formdb != null && datecompare != null && datecompare <= 0) {
         logger.Info(
            "formsApi:RetrieveForm",
            "Local form " + forminfo.Id + " is up to date.  Skipping retrieval."
         );
         if (formdb.State !== enums.State.None) {
            formdb.State = enums.State.None;
            await db.forms.put(formdb);
         }
         return { id: forminfo.Id, status: "skip" };
      }
   }

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/GetForm/" + forminfo.Id
   );

   return axios.get(url, config).then(
      async function (response) {
         const documentinfo = await SaveFormToLocalDb(
            response.data,
            [],
            enums.State.None
         );
         let getDocumentResults = { status: "none" };
         if (documentinfo.length > 0) {
            getDocumentResults = await retrieveDocuments(
               forminfo.Id,
               documentinfo,
               config
            );
         }
         return {
            status: "success",
            id: forminfo.Id,
            definitionId: response.data.DefId,
            workOrderId: response.data.WorkOrderId,
            result: getDocumentResults,
            isNewTransfer: response.data.IsTransferred && formdb == null,
            updateDateTime: response.data.UpdateDateTime,
            updatedBy: response.data.UpdatedBy
         };
      },
      function (error) {
         logger.Error(
            "formsApi:RetrieveForm",
            "Error retrieving form " + forminfo.Id,
            error
         );
         return {
            id: forminfo.Id,
            status: "failure",
            message:
               "An error occurred retrieving form " +
               forminfo.Id +
               ". See logs.",
         };
      }
   );
}

async function retrieveDocuments(formId, documentInfo, config) {
   let processes = [];
   for (let d = 0; d < documentInfo.length; d++) {
      const dataid = documentInfo[d].dataId;
      const documentid = documentInfo[d].documentId;

      processes.push(retrieveDocument(formId, dataid, documentid, config));
   }
   return Promise.all(processes).then(
      function (results) {
         return { id: formId, status: "success", results: results };
      },
      function (error) {
         logger.Error(
            "formsApi:retrieveDocuments",
            "Error retrieving documents for form " + formId,
            error
         );
         return {
            id: formId,
            status: "failure",
            message:
               "An error occurred retrieving documents for form " + formId,
         };
      }
   );
}

async function retrieveDocument(formId, dataId, documentId, config) {

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/GetDocument/" + documentId
   );

   return axios.get(url, config).then(
      async function (response) {
         let datadb = await db.formData.get(dataId);
         datadb.content = response.data.FileContentString;
         datadb.fileName = response.data.Name;
         await db.formData.put(datadb);
         return { id: documentId, status: "success" };
      },
      function (error) {
         logger.Error(
            "formsApi:retrieveDocument",
            "Error retrieving document " + documentId + " for " + dataId,
            error
         );
         return {
            id: documentId,
            status: "failure",
            message:
               "An error occurred retrieving document for form id " +
               formId +
               ", data id " +
               dataId +
               ", document id " +
               documentId,
         };
      }
   );
}

async function setFormState(id, state) {
   const updateobj = { State: state };
   await db.forms.update(id, updateobj);
}

async function checkVersion(config) {
   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/VersionCheck?version=" +
      process.env.REACT_APP_ENFORM_VERSION
   );

   return axios.get(url, config).then(
      function (response) {
         return response.data;
      },
      function (error) {
         logger.Error(
            "syncProcess:checkVersion",
            "Version check failed with error",
            error
         );
         return true;
      }
   );
}

async function getWeatherForData(form, data) {
   if (!form.hasOwnProperty("Location") || isNullOrEmpty(form.Location)) return;
   let latlng = getLocationObject(form.Location);
   if (latlng === null && !isCanadianPostalCode(form.Location) && !isUSZipCode(form.Location)) return;

   let weather = JSON.parse(data.Value);
   if (weather.source !== "later") return;

   let newweather = await GetWeatherThen(
      latlng,
      form.Location,
      weather.dateTime
   );

   if (newweather.success) {
      data.Value = JSON.stringify(newweather.data);
   }
}
