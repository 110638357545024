import React, { useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
   Notification,
   NotificationGroup,
} from "@progress/kendo-react-notification";
import { SendEmail } from "../api/apiUtils";
import { isNullOrEmpty } from "../helpers/utils";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../helpers/authConfig";
import Login from "../components/Login";

const notifyPosition = {
   top: 300,
   left: "50%",
   transform: "translateX(-50%)",
};

function Support({ sizeFactor }) {
   const [supportEmailText, setSupportEmailText] = useState("");
   const [toastState, setToastState] = useState({ style: "none", message: "" });
   const [emailButtonDisabled, setEmailButtonDisabled] = useState(true);
   const isAuthenticated = useIsAuthenticated();
   const { instance, accounts } = useMsal();

   if (!isAuthenticated) {
      return <Login />;
   }

   const handleSetSupportEmailText = (event) => {
      setSupportEmailText(event.value);
      if (isNullOrEmpty(event.value)) {
         setEmailButtonDisabled(true);
      } else {
         setEmailButtonDisabled(false);
      }
   };

   const onSendEmail = () => {
      instance
         .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
         })
         .then(function (response) {
            SendEmail(
               "Support",
               "EnForms User Support",
               supportEmailText,
               "support",
               "",
               response.accessToken
            ).then(
               function (response) {
                  setToastState({
                     style: "success",
                     message: "Success sending email",
                  });
               },
               function (error) {
                  setToastState({
                     style: "error",
                     message: "Error sending email",
                  });
               }
            );
         });
   };

   return (
      <div style={{ margin: "5px" }}>
         <Label
            style={{
               display: "block",
               fontWeight: "600",
               fontSize: 1 * sizeFactor + "rem",
            }}
         >
            Send Message to the Support Team
         </Label>
         <TextArea
            rows={4}
            value={supportEmailText}
            style={{ width: "100%", fontSize: 1 * sizeFactor + "rem" }}
            onChange={handleSetSupportEmailText}
         />
         <Button
            style={{
               fontSize: 0.95 * sizeFactor + "rem",
               marginTop: "3px",
            }}
            disabled={emailButtonDisabled}
            onClick={onSendEmail}
         >
            Send Email
         </Button>
         <NotificationGroup style={notifyPosition}>
            {toastState.style !== "none" ? (
               <Notification
                  type={{
                     style: toastState.style,
                     icon: true,
                  }}
                  closable={true}
                  onClose={() => setToastState({ style: "none", message: "" })}
               >
                  {toastState.message}
               </Notification>
            ) : (
               ""
            )}
         </NotificationGroup>
      </div>
   );
}

export default Support;
