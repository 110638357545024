import React, { useState } from "react";
import { Slider, SliderLabel } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "../redux/actions/uiActions";
import * as userActions from "../redux/actions/userActions";
import styles from "./Setup.module.css";
import LoadDigitalId from "../components/LoadDigitalId";
import { RecipientSetup } from "../components/Recipients/RecipientSetup";

function Setup({ actions, online, sizeFactor, hasDigitalId, recipients }) {
   const [loadDigitalId, setLoadDigitalId] = useState(false);

   const handleSizeFactorChange = (event) => {
      const newsizefactor = Math.round(event.value * 10) / 10;
      actions.setSizeFactor(newsizefactor);
   };

   const reloadPage = () => {
      window.location.reload();
   };

   return (
      <>
         <div className={styles.setupContainer}>
            <div className={styles.setupElementContainer}>
               <div className={styles.setupTitleBar}
                  style={{ fontSize: 1.1 * sizeFactor + "rem" }}
               >
                  Options
               </div>
               <div className={styles.setupElement}>
                  <Label
                     style={{
                        display: "block",
                        fontWeight: "600",
                        fontSize: 1 * sizeFactor + "rem",
                     }}
                  >
                     Size
                  </Label>
                  <Slider
                     min={0}
                     max={2}
                     step={0.1}
                     value={sizeFactor}
                     onChange={handleSizeFactorChange}
                     style={{
                        marginBottom: 20 * sizeFactor + "px",
                        fontSize: 1 * sizeFactor + "rem",
                        width: "100%",
                        paddingLeft: "5px",
                        paddingBottom: "5px",
                        paddingRight: "15px",
                     }}
                  >
                     {[0, 1, 2].map((value, idx) => (
                        <SliderLabel key={idx} position={value}>
                           {(value * 100).toString() + "%"}
                        </SliderLabel>
                     ))}
                  </Slider>
               </div>
            </div>

            <div className={styles.setupElementContainer}>
               <div
                  className={styles.setupTitleBar}
                  style={{ fontSize: 1.1 * sizeFactor + "rem" }}
               >
                  Info
               </div>
               <div className={styles.setupElement}
                  style={{
                     fontSize: 1 * sizeFactor + "rem",
                  }}
               >
                  <strong>Version:</strong> {process.env.REACT_APP_ENFORM_VERSION}
                  <br />
                  <strong>API Server:</strong>{" "}
                  {process.env.REACT_APP_API_SERVER_URL}
                  <br />
                  <strong>Environment:</strong> {process.env.NODE_ENV}
                  <br />
                  <strong>Connection:</strong> {online ? "Online" : "Offline"}
                  <br />
               </div>
               <div
                  style={{
                     display: "flex",
                     flexWrap: "wrap",
                     justifyContent: "space-between",
                  }}
               >
                  <div>
                     <Button
                        themeColor="primary"
                        fillMode="flat"
                        icon="wrench"
                        style={{ fontSize: 1 * sizeFactor + "rem" }}
                        onClick={() => {
                           actions.setRoute("troubles");
                        }}
                     >
                        Go to Troubleshooting
                     </Button>
                  </div>
                  <div>
                     <Button
                        fillMode="flat"
                        icon="reload"
                        disabled={!online}
                        onClick={reloadPage}
                        style={{ fontSize: 1 * sizeFactor + "rem" }}
                     >
                        Reload Page
                     </Button>
                  </div>
               </div>
            </div>
            <div className={styles.setupElementContainer}>
               <div
                  className={styles.setupTitleBar}
                  style={{ fontSize: 1.1 * sizeFactor + "rem" }}
               >
                  Digital Id
               </div>
               <div className={styles.setupElement}>
                  {hasDigitalId ? (
                     <div
                        style={{ fontSize: 0.95 * sizeFactor + "rem", color: "blue" }}
                     >
                        A Digital Id has been loaded.
                     </div>
                  ) : (
                     <div
                        style={{ fontSize: 0.95 * sizeFactor + "rem", color: "red" }}
                     >
                        A Digital Id has NOT been loaded.
                     </div>
                  )}
                  <div>
                     <Button
                        icon="ppt"
                        disabled={!online}
                        onClick={() => setLoadDigitalId(true)}
                        style={{ fontSize: 0.9 * sizeFactor + "rem" }}
                     >
                        Load Digital Id
                     </Button>
                  </div>
               </div>
            </div>

            <div className={styles.setupElementContainer}>
               <div className={styles.setupTitleBar} style={{ fontSize: 1.1 + "rem" }}>
                  Additional Email Recipients
               </div>

               <div className={styles.setupElement}>
                  <RecipientSetup
                     recipients={recipients}
                     onAddUpdate={actions.addUpdateRecipient}
                     onRemove={actions.removeRecipient}
                  />
               </div>
            </div>

         </div>
         {
            loadDigitalId &&
            <LoadDigitalId
               sizeFactor={sizeFactor}
               setLoadDigitalId={setLoadDigitalId}
               setDigitalId={actions.setDigitalId}
            />
         }
      </>
   );
}

function mapStateToProps(state) {
   return {
      logLevel: state.ui.logLevel,
      online: state.ui.online,
      sizeFactor: state.ui.sizeFactor,
      hasDigitalId: state.user.HasDigitalId,
      recipients: [...state.user.Recipients]
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setSizeFactor: bindActionCreators(uiActions.setSizeFactor, dispatch),
         setRoute: bindActionCreators(uiActions.setRoute, dispatch),
         setDigitalId: bindActionCreators(userActions.setDigitalId, dispatch),
         addUpdateRecipient: bindActionCreators(userActions.addUpdateRecipient, dispatch),
         removeRecipient: bindActionCreators(userActions.removeRecipient, dispatch),
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
