import * as types from "../actionTypes";
import {
   GetFormsList,
   GetForm,
   CreateNewForm,
   SetFormStatus,
   SetDeleteForm,
   SaveEditForm,
} from "../../db/formHandler";
import { GroupAdd, GroupDelete, UpdateGroupViewedStatus } from "../../db/groupHandler";
import { GetGroupsForForm } from "../../db/groupHandler";

export function loadFormsList() {
   return (dispatch) => {
      GetFormsList().then((newlist) => {
         dispatch({ type: types.LOAD_FORMS_LIST, newlist });
      });
   };
}

export function setFormId(formId) {
   return (dispatch) => {
      GetForm(formId).then((newform) => {
         const obj = {
            formid: formId,
            groupid: "root",
            form: newform,
            init: formId === null,
         };
         dispatch({ type: types.SET_FORM_ID, obj });
      });
   };
}

export function setGroupId(formId, groupId) {
   return (dispatch) => {
      GetGroupsForForm(formId).then((formGroups) => {
         UpdateGroupViewedStatus(groupId);
         const obj = {
            groupId: groupId,
            formGroups: formGroups,
         };
         dispatch({ type: types.SET_GROUP_ID, obj });
      });
   };
}

export function saveEditForm(formId, updateObject) {
   return (dispatch) => {
      SaveEditForm(formId, updateObject).then((form) => {
         dispatch({ type: types.SAVE_EDIT_FORM, form });
      });
   };
}

export function createNewForm(defId, date, woId, location, title) {
   return (dispatch) => {
      CreateNewForm(defId, date, woId, location, title).then((newobj) => {
         const obj = {
            formid: newobj.form.Id,
            form: newobj.form,
            list: newobj.list,
            title: title,
         };
         dispatch({ type: types.CREATE_NEW_FORM, obj });
      });
   };
}

export function setFormStatus(formId, status) {
   return (dispatch) => {
      SetFormStatus(formId, status).then((list) => {
         const obj = { status, list };
         dispatch({ type: types.SET_FORM_STATUS, obj });
      });
   };
}

export function deleteForm(formId) {
   return (dispatch) => {
      SetDeleteForm(formId).then(() => {
         dispatch({ type: types.DELETE_FORM, formId });
      });
   };
}

export function groupAdd(groupId, formId, isCopy) {
   return (dispatch) => {
      GroupAdd(groupId, formId, isCopy).then((obj) => {
         UpdateGroupViewedStatus(obj.groupId);
         dispatch({ type: types.GROUP_ADD, obj });
      });
   };
}

export function groupDelete(formId, groupId) {
   return (dispatch) => {
      GroupDelete(formId, groupId).then((obj) => {
         dispatch({ type: types.GROUP_DELETE, obj });
      });
   };
}

export function groupUpdateDataName({ groupId, value, counts }) {
   const obj = { groupId, value, counts };
   return { type: types.GROUP_UPDATE_DATANAME, obj };
}

export function groupUpdateCounts({ groupId, counts }) {
   const obj = { groupId, counts };
   return { type: types.GROUP_UPDATE_COUNTS, obj };
}

export function setFormsInit(value) {
   return { type: types.SET_FORMS_INIT, value };
}
