import React, { useRef, useEffect, useState } from "react";
import DataLabel from "./DataLabel";
import {
   isNullOrEmpty,
   formatStation,
   unformatStation,
} from "../../helpers/utils";

function DataStation({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const [localValue, setLocalValue] = useState("");
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
      setLocalValue(formatStation(dataValue));
   }, [addRefElement, dataId, dataValue]);

   const thisOnBlur = (event) => {
      if (dataValue === localValue) {
         setLocalValue(formatStation(localValue));
      } else {
         handleOnChange(event);
      }
   };
   const thisOnChange = (event) => {
      if (!event.target.value.match(/\D/g)) {
         setLocalValue(event.target.value);
      }
   };
   const thisOnFocus = (event) => {
      setLocalValue(unformatStation(localValue));
   };
   return (
      <div>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <input
            ref={ref}
            id={editorId}
            type="text"
            value={localValue}
            onChange={thisOnChange}
            onBlur={thisOnBlur}
            onFocus={thisOnFocus}
            disabled={dataDef.ReadOnly || formIsLocked}
            required={dataDef.Required}
            style={{
               borderRadius: "2px",
               borderWidth: "1px",
               borderStyle: "solid",
               borderColor: "rgba(0, 0, 0, 0.08)",
               height: "calc(1.4285714286em + 10px)",
               boxShadow: "none",
               boxSizing: "border-box",
               padding: "4px 8px",
               fontSize: 1 * sizeFactor + "rem",
            }}
         />
      </div>
   );
}

export default DataStation;
