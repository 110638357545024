import React, { useEffect, useRef } from "react";
import { RadioGroup } from "@progress/kendo-react-inputs";
import DataLabel from "./DataLabel";
import { isNullOrEmpty } from "../../helpers/utils";

function DataRadio({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);
  
   let defaultValue = dataValue;
   let firstValue = null;
   let optionslist = [];
   const options = dataDef.ValList.split(",");
   optionslist = options.map((option, index) => {
      const [optionkey, optionvalue] = option.split("|");
      if (firstValue === null) firstValue = optionkey;
      return {
         value: optionkey,
         label: optionvalue,
      };
   });
   if (isNullOrEmpty(defaultValue)) defaultValue = null;
   return (
      <>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <RadioGroup
            id={editorId}
            layout="horizontal"
            data={optionslist}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={dataDef.ReadOnly || formIsLocked}
            required={dataDef.Required}
            style={{ fontSize: 1 * sizeFactor + "rem" }}
         />
      </>
   );
}

export default DataRadio;
