import db from "./db";
import { isNullOrEmpty } from "../helpers/utils";
import * as settings from "../settings";
import { ParseDataDefs } from "./defHandler";
import { FixBrokenAutoListDatas, ConvertPictures } from "./fixHandler";
import { DeleteALLForms } from "../helpers/setupUtil";
import * as logger from "../helpers/logger";

export async function GetInitialUIState() {
   const ui = await db.ui.get(1);
   const loglevel = isNullOrEmpty(ui.logLevel) ? 0 : ui.logLevel;
   const lastlogpurge = isNullOrEmpty(ui.lastLogPurge) ? null : ui.lastLogPurge;
   const sizeFactor = isNullOrEmpty(ui.sizeFactor) ? 1 : ui.sizeFactor;
   const route = isNullOrEmpty(ui.lastRoute) ? "home" : ui.lastRoute;
   const releaseNotes = isNullOrEmpty(ui.releaseNotes) ? "" : ui.releaseNotes;
   return {
      syncinfo: ui.Sync,
      username: ui.username,
      init: true,
      route: route,
      submitType: "",
      online: navigator.onLine,
      logLevel: loglevel,
      lastLogPurge: lastlogpurge,
      sizeFactor: sizeFactor,
      checkVersion: true,
      releaseNotes: releaseNotes
   };
}

export async function GetSyncInfo() {
   return db.ui
      .get(1)
      .then((obj) => {
         return obj;
      })
      .catch((error) => {
         const obj = {
            Sync: {
               LastSync: "",
               Status: "Unknown",
               Error: error,
            },
         };
         return obj;
      });
}

export async function SetSizeFactor(factor) {
   let ui = { sizeFactor: factor };
   await db.ui.update(1, ui);
}

export async function SetReleaseNotes(notes) {
   let ui = { releaseNotes: notes };
   await db.ui.update(1, ui);
}

export async function SetFormListSortByDb(sortBy) {
   let ui = { formListSortBy: sortBy };
   await db.ui.update(1, ui);
}

export async function CheckVersion() {
   let uidb = await db.ui.get(1);
   if (!uidb.hasOwnProperty("versionInfo")) {
      uidb.versionInfo = {};
   }
   let versioninfo = settings.versionInfo;
   const versionkeys = Object.keys(versioninfo);
   let update = false;
   for (let k = 0; k < versionkeys.length; k++) {
      const version = versionkeys[k];
      if (!uidb.versionInfo.hasOwnProperty(version)) {
         uidb.versionInfo[version] = { actions: [] };
      }
      let actions = versioninfo[versionkeys[k]].actions;
      for (let a = 0; a < actions.length; a++) {
         let action = actions[a];
         if (uidb.versionInfo[version].actions.indexOf(action) === -1) {
            if (action === "ParseDataDefs") {
               await ParseDataDefs();
               logger.System(
                  "uiHandler:CheckVersion",
                  "Executed " + action + "; version " + version
               );
            } else if (action === "FixBrokenAutoListDatas") {
               await FixBrokenAutoListDatas();
               logger.System(
                  "fixHandler:CheckVersion",
                  "Executed " + action + "; version " + version
               );
            } else if (action === "DeleteALLForms") {
               const numdeleted = await DeleteALLForms();
               logger.System(
                  "fixHandler:CheckVersion",
                  "Executed " +
                  action +
                  "; version " +
                  version +
                  "; num deleted: " +
                  numdeleted
               );
            } else if (action === "ConvertPictures") {
               await ConvertPictures();
               logger.System(
                  "fixHandler:CheckVersion",
                  "Executed " + action + "; version " + version
               );
            }
            uidb.versionInfo[version].actions.push(action);
            update = true;
         }
      }
   }
   if (update) {
      await db.ui.put(uidb);
   }
}

export async function UpdateLastRoute(route) {
   const uidb = {
      lastRoute: route,
   };
   await db.ui.update(1, uidb);
}
