import React, { useRef, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import DataLabel from "./DataLabel";
import { getTime, isNullOrEmpty } from "../../helpers/utils";

function DataTime({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);

   const thisValue = isNullOrEmpty(dataValue) ? "" : getTime(dataValue, true);

   const getTimeNow = async () => {
      let rightnow = new Date();
      handleOnChange(rightnow);
   };

   return (
      <>
         <DataLabel
            dataDef={dataDef}
            dataValue={thisValue}
            sizeFactor={sizeFactor}
         />
         <input
            type="time"
            id={editorId}
            value={thisValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={dataDef.ReadOnly || formIsLocked}
            required={dataDef.Required}
            style={{
               fontSize: 0.85 * sizeFactor + "rem",
               borderRadius: "2px",
               padding: "5px",
               height: "calc(1.4285714286em + 10px)",
               color: "black",
               backgroundColor: "white",
               borderColor: "rgba(0, 0, 0, 0.08)",
            }}
         />
         <Button 
            style={{
               fontSize: 0.85 * sizeFactor + "rem",
               marginLeft: "20px",
               marginTop:"-4px",
            }}
            disabled={formIsLocked}
            onClick={getTimeNow}
         >
            Now
         </Button>
      </>
   );
}

export default DataTime;
