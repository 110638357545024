import { IApiResponse } from '../types/IApiResponse';
import { IWorkOrderUser } from '../types/IWorkOrderUser';
import { instance as apiClient } from "./apiClient";
import { IUserLast } from '../types/IUserLast';

interface IGetDocumentParams {
  queryKey: [string, { documentId: string, accessToken?: string }];
}

export const getDocumentById = async (params: IGetDocumentParams): Promise<IApiResponse<string>> => {

  const [, { documentId, accessToken }] = params.queryKey;

  const config = {
    headers: { Authorization: "Bearer " + accessToken }
  };

  const { data } = await apiClient.get<IApiResponse<string>>(`api/EnFormMApi/GetAttachmentData/${documentId}`, config);

  return data;
};

interface IGetGeneratedFormPdfParams {
  queryKey: [string, { formId: string, accessToken?: string }];
}

export const getGeneratedFormPdf = async (params: IGetGeneratedFormPdfParams): Promise<IApiResponse<string>> => {
  const [, { formId, accessToken }] = params.queryKey;

  const config = {
    headers: { Authorization: "Bearer " + accessToken }
  };

  const { data } = await apiClient.get<IApiResponse<string>>(`api/EnFormMApi/GetGeneratedPdfForForm/${formId}`, config);

  return data;
}

export const getOnlineStatus = async (): Promise<IApiResponse<boolean>> => {
  const { data } = await apiClient.get<IApiResponse<boolean>>(`api/EnFormApi/IsOnline`);

  return data;
}

interface IGetWorkOrderUsers {
  queryKey: [string, { workOrderId: number, accessToken?: string }];
}

export const getWorkOrderUsers = async (params: IGetWorkOrderUsers): Promise<IApiResponse<IWorkOrderUser[]>> => {
  const [, { workOrderId, accessToken }] = params.queryKey;

  const config = {
    headers: { Authorization: "Bearer " + accessToken }
  };

  const { data } = await apiClient.get<IApiResponse<IWorkOrderUser[]>>(`api/EnFormMApi/GetUsersForTransfer/${workOrderId}`, config);

  return data;
}

interface ITransferFormOwnerParams {
  formId: number,
  newOwner: string,
  comments: string,
  accessToken?: string
};

export const transferFormOwnership = async (params: ITransferFormOwnerParams): Promise<IApiResponse<number>> => {
  const { formId, newOwner, comments, accessToken } = params;

  const config = {
    headers: { Authorization: "Bearer " + accessToken }
  };

  const { data } = await apiClient.post<IApiResponse<number>>(`api/EnFormMApi/TransferOwner`, { formId, newOwner, comments }, config);

  return data;
};

interface IAcknowledgeTransferProps {
  formIds: number[],
  accessToken?: string
};

export const acknowledgeOwnership = async (params: IAcknowledgeTransferProps): Promise<IApiResponse<boolean>> => {
  const { formIds, accessToken } = params;

  const config = {
    headers: { Authorization: "Bearer " + accessToken }
  };

  const { data } = await apiClient.post<IApiResponse<boolean>>(`api/EnFormMApi/AcknowledgeTransfer`, formIds, config);

  return data;
};

interface IGetUserLastsProps {
  queryKey: [string, { formDefId: number, workOrderId: number, accessToken?: string }];
};

export const getUserLasts = async (params: IGetUserLastsProps): Promise<IUserLast[]> => {
  const [, { formDefId, workOrderId, accessToken }] = params.queryKey;

  const config = {
    headers: { Authorization: "Bearer " + accessToken }
  };

  const url = `api/UsersApi/GetUserLasts?formDefId=${formDefId}&workOrderId=${workOrderId}`;
  
  const { data } = await apiClient.get<IUserLast[]>(url, config);

  return data;
};
