import React from "react";
import styles from "./SubmitDigitalId.module.css";

interface IProps {
   digitalIdKey: any;
   setDigitalIdKey: any;
   digitalIdInfo: any;
   setDigitalIdInfo: any;
   sizeFactor: any;
   submittingForm: any;
}

function SubmitDigitalId({
   digitalIdKey,
   setDigitalIdKey,
   digitalIdInfo,
   setDigitalIdInfo,
   sizeFactor,
   submittingForm,
}: IProps) {
   const handleSetPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDigitalIdKey(event.target.value);
   };

   const onDigitalIdInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDigitalIdInfo({
         ...digitalIdInfo,
         [event.target.name]: event.target.value,
      });
   };

   return (
      <div>
         <div style={{ display: "flex", alignItems: 'center' }}>
            <div
               className={styles.digitalidLabel}
               style={{
                  fontSize: 0.9 * sizeFactor + "rem",
               }}
            >
               Contact Info
            </div>
            <div className={styles.digitalidItem}>
               <input
                  type="text"
                  name="FullName"
                  disabled={submittingForm}
                  placeholder="Contact Info"
                  value={digitalIdInfo.FullName}
                  onChange={onDigitalIdInfoChange}
               />
            </div>
         </div>
         <div style={{ display: "flex", alignItems: 'center' }}>
            <div
               className={styles.digitalidLabel}
               style={{
                  fontSize: 0.9 * sizeFactor + "rem",
               }}
            >
               Job Title
            </div>
            <div className={styles.digitalidItem}>
               <input
                  type="text"
                  name="jobTitle"
                  disabled={submittingForm}
                  placeholder="Job Title"
                  value={digitalIdInfo.jobTitle}
                  onChange={onDigitalIdInfoChange}
               />
            </div>
         </div>
         <div style={{ display: "flex", alignItems: 'center' }}>
            <div
               className={styles.digitalidLabel}
               style={{
                  fontSize: 0.9 * sizeFactor + "rem",
               }}
            >
               Location Info
            </div>
            <div className={styles.digitalidItem}>
               <input
                  type="text"
                  name="locationInfo"
                  disabled={submittingForm}
                  placeholder="Location Info"
                  value={digitalIdInfo.locationInfo}
                  onChange={onDigitalIdInfoChange}
               />
            </div>
         </div>
         <div style={{ display: "flex", alignItems: 'center' }}>
            <div
               className={styles.digitalidLabel}
               style={{
                  fontSize: 0.9 * sizeFactor + "rem",
               }}
            >
               Reason
            </div>
            <div className={styles.digitalidItem}>
               <input
                  type="text"
                  name="reason"
                  disabled={submittingForm}
                  placeholder="Reason"
                  value={digitalIdInfo.reason}
                  onChange={onDigitalIdInfoChange}
               />
            </div>
         </div>

         <div
            style={{
               fontSize: 0.9 * sizeFactor + "rem",
               fontWeight: "600",
               paddingBottom: "20px",
               textAlign: "justify",
               marginTop: '20px'
            }}
         >
            By entering your Digital Id Key, you agree to digitally signing the
            document form. A copy of the signed document will be sent to you via
            email.
         </div>
         <div style={{ display: "flex", alignItems: 'center' }}>
            <div
               className={styles.digitalidLabel}
               style={{
                  fontSize: .9 * sizeFactor + "rem",
               }}
            >
               Digital Id Key
            </div>
            <div className={styles.digitalidItem}>
               <input
                  type="password"
                  name="password"
                  value={digitalIdKey}
                  onChange={handleSetPassword}
                  disabled={submittingForm}
                  autoComplete="off"
               />
            </div>
         </div>
      </div>
   );
}

export default SubmitDigitalId;
