import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { IRecipient } from '../../types/IRecipient'

import styles from './RecipientSelector.module.css';

interface IProps {
  list: IRecipient[];
  selected: IRecipient[];
  onSelect: (recipient: IRecipient) => void;
  setRoute: (route: string) => void;
};

const RecipientSelector = ({ list, selected, onSelect, setRoute }: IProps) => {

  const row = (recipient: IRecipient) => {

    const isSelected = selected.find(x => x.id === recipient.id) !== undefined;

    return (
      <div key={recipient.id} className={styles.row} onClick={() => onSelect(recipient)}>
        {isSelected && <span className={`k-icon k-i-check-circle ${styles.icon}`}></span>}
        {!isSelected && <span className={`k-icon k-i-shape-circle ${styles.icon}`}></span>}
        {`${recipient.name}, ${recipient.title}`}
      </div>
    );
  };


  return (
    <PanelBar>
      <PanelBarItem expanded={false} title="Additional Email Recipients">
        {list.map(x => row(x))}
        <div className={`${styles.warning}`}>Additional Email recipients can be setup in the <span className={styles.link} onClick={() => setRoute('setup')}>utility tab</span>.</div>
      </PanelBarItem>
    </PanelBar>
  )
}

export { RecipientSelector };