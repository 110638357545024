import db from "./db";
import { isNullOrEmpty } from "../helpers/utils";

let keys = null;

export async function GetNextFormId() {
   let k = await getKeys();
   const newid = k.nextFormId--;
   await SaveKeys();
   return newid;
}

export async function GetNextGroupId() {
   let k = await getKeys();
   return k.nextGroupId--;
}

export async function GetNextDataId() {
   let k = await getKeys();
   return k.nextDataId--;
}

async function getKeys() {
   if (keys != null) return keys;

   keys = await db.keys.get(1);
   return keys;
}

export async function SaveKeys() {
   await db.keys.put(keys);
}

export async function GetCancelKeys() {
   let obj = await db.keys.get("CancelKeys");
   if (isNullOrEmpty(obj)) {
      obj = { id: "CancelKeys", keys: [] };
   }
   return obj;
}

export async function AddCancelKey(id) {
   let obj = await GetCancelKeys();
   if (obj.keys.indexOf(id) === -1) {
      obj.keys.push(id);
      await db.keys.put(obj);
   }
}

export async function RemoveCancelKeys() {
   await db.keys.put({ id: "CancelKeys", keys: [] });
}

export async function RemoveCancelKey(id) {
   let obj = await GetCancelKeys();
   const idx = obj.keys.indexOf(id);
   if (idx === -1) return;
   obj.keys.splice(idx, 1);
   await db.keys.put(obj);
}
