// ui
export const LOAD_SYNC_INFO = "LOAD_SYNC_INFO";
export const SET_INITIAL_UI_STATE = "SET_INITIAL_UI_STATE";
export const SET_ROUTE = "SET_ROUTE";
export const IS_ONLINE = "IS_ONLINE";
export const SET_LOG_LEVEL = "SET_LOG_LEVEL";
export const PURGE_LOGS = "PURGE_LOGS";
export const SET_SIZE_FACTOR = "SET_SIZE_FACTOR";
export const SET_TROUBLE_CODE = "SET_TROUBLE_CODE";
export const CHECK_VERSION = "CHECK_VERSION";
export const SET_INCLUDE_SUBMITTED = "SET_INCLUDED_SUBMITTED";
export const SET_SUBMIT_TYPE = "SET_SUBMIT_TYPE";
export const SET_RELEASE_NOTES = "SET_RELEASE_NOTES";
export const SET_FORM_LIST_SORT = "SET_FORM_LIST_SORT";

// user
export const SET_INITIAL_USER_STATE = "SET_INITIAL_USER_STATE";
export const SET_DIGITAL_ID = "SET_DIGITAL_ID";
export const SET_LAST_SYNC = "SET_LAST_SYNC";
export const SET_EMAIL_RECIPIENTS = "SET_EMAIL_RECIPIENTS";
export const SET_COPY_APPROVER = "SET_COPY_APPROVER";
export const SET_FORM_NEW_LOCATION = "SET_LAST_LOCATION";
export const SET_FORM_LISTING_SORT_BY = "SET_LAST_FORM_LIST_SORT_BY";

// defs
export const LOAD_DEFS_LIST = "LOAD_DEFS_LIST";
export const SET_DEF_INIT = "SET_DEF_INIT";

// forms
export const LOAD_FORMS_LIST = "LOAD_FORMS_LIST";
export const SET_FORM_ID = "SET_FORM_ID";
export const CREATE_NEW_FORM = "CREATE_NEW_FORM";
export const SET_FORM_STATUS = "SET_STATUS";
export const DELETE_FORM = "DELETE_FORM";
export const GROUP_ADD = "GROUP_ADD";
export const GROUP_DELETE = "GROUP_DELETE";
export const GROUP_UPDATE_DATANAME = "GROUP_UPDATE_DATANAME";
export const GROUP_UPDATE_COUNTS = "GROUP_UPDATE_COUNTS";
export const SET_FORMS_INIT = "SET_FORMS_INIT";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const SAVE_EDIT_FORM = "SAVE_EDIT_FORM";

// vals
export const LOAD_ALL_VALIDATIONS = "LOAD_ALL_VALIDATIONS";
