import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getOnlineStatus } from '../api/queries';
import { IApiResponse } from '../types/IApiResponse';

const useOnlineDetector = () => {

  const [onlineState, setOnlineState] = useState<boolean>(false);

  useQuery(['isOnline'], getOnlineStatus, {
    enabled: true,
    cacheTime: 0,
    refetchInterval: 30000,
    onSuccess: (response: IApiResponse<boolean>) => {
      setOnlineState(response.Data);
    },
    onError: () => {
      setOnlineState(false);
    }
  });

  return { onlineState };
}

export { useOnlineDetector };
